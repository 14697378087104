<template>
  <div id="Showanalysis">
    <div class="totalSubject">
      <div class="toptitle">
        <h4>全部错题({{ total }})</h4>
        <van-button v-if="total !== 0" @click="emptyall">一键清空</van-button>
      </div>
      <div class="notYet" v-if="total == 0">暂无错题</div>
      <div class="typelist" v-for="(item, index) in typelist" :key="index">
        <van-icon
          @click="delet(item)"
          name="cross"
          color="#df3833"
          size="0.5rem"
        />
        <div @click="clickSend('错题集', index)">
          <p>
           
            <span v-if="!item.read"> {{ item.question.types }}</span>
            <span v-else> {{item.read.questions[0].types}}</span>
            <!-- <span>/</span>
            <span>{{ item.question.grade }}分</span> -->
          </p>
          <p v-if="!item.read" class="questionStem" v-html="item.question.stem"></p>
          <p v-else class="questionStem" v-html="item.read.questions[0].stem"></p>
        </div>
      </div>
    </div>
    <!-- 返回考试结果 -->
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      total: 0, //题目
      loadingBool: true,
      titlename: "个人中心 - 西知题库",
      typelist: [],
    };
  },
  created() {
    //查看解析

    this.refirsh();
      localStorage.routerPush="/PersonalCenter/Index"

  },
  methods: {
    refirsh() {
      let _this = this;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.$api.errorlist(obj).then((res) => {
        _this.total = res.data.total;
        _this.typelist = res.data.data;
        console.log(_this.typelist);
        _this.loadingBool = false;
      });
    },
    returnExam() {
      let _this = this;
      _this.$router.push("./Result");
    },
    returndown() {
      let _this = this;
      _this.$router.push("/PersonalCenter");
    },
    // 删除单个
    delet(item) {
      let _this = this;
      _this.$dialog
        .confirm({
          title: "你确定删除此错题吗！",
        })
        .then(() => {
          // on confirm
          let obj = {
            question_id: item.question_id,
          };
          console.log(obj);
          _this.$api.emptyerrorstate(obj).then((res) => {
            _this.refirsh();
            _this.$toast.success("删除成功");

            console.log(res);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除全部
    emptyall() {
      let _this = this;
      _this.$dialog
        .confirm({
          title: "你确定删除所有错题吗！",
        })
        .then(() => {
          // on confirm

          _this.$api.emptyerrorstate().then((res) => {
            _this.$toast.success("删除成功");
            _this.refirsh();

            console.log(res);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    clickSend(val, index) {
      let _this = this;
      console.log(val, index);
      _this.$router.push({
        path: "/Practice",
        query: {
          val: val,
          index: index,
          
        },
      });
    },
    routerPush() {
      let _this = this;
      _this.$router.push("/PersonalCenter/Index");

    },
  },
  mounted() {
    bus.$on("routerPush", this.routerPush);
  },
};
</script>

<style lang="less" scoped>
#Showanalysis {
  margin-top: 100px;
  width: 100%;
  background: #fcfcfc;
  min-height: 100%;
  //   position: relative;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  .van-button {
    border-radius: 8px;
    padding: 0px 20px;
    height: 60px;
    color: #4280f4;
    font-size: 20px;
  }
  .notYet {
    font-size: 28px;
    padding-left: 15px;
  }
  .totalSubject {
    padding: 40px 30px;
    .toptitle {
      display: flex;
      justify-content: space-between;

      h4 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 50px;
      }
      > div {
        font-size: 28px;
        color: #333;
      }
    }
  }
  .typelist {
    padding: 40px 0px 30px 30px;
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px #dfdfdf;
    border-radius: 8px;

    h6 {
      // color: red;
      line-height: 90px;
      margin-right: 40px;
      span {
        font-weight: bold;
      }
    }
    div {
      margin-left: 30px;

      p {
        &:first-child {
          font-size: 24px;
          color: rgb(71, 71, 71);
          margin-bottom: 20px;
          span {
            &:nth-child(2) {
              margin: 0 10px;
            }
          }
        }
      }
      .questionStem {
        font-weight: bold;
        width: 500px;
        height: 33px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 28px;
        font-weight: 600;
        /deep/p {
          width: 500px;
          // height: 30px;
           font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 28px;
        }
      }
    }
  }
  .returnExam {
    width: 100%;
    line-height: 100px;
    text-align: center;
    font-size: 30px;
    background: #fff;
    box-shadow: 0px 0px 20px #dfdfdf;
    position: fixed;
    bottom: 0;
  }
}
</style>