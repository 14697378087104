<!--  微信授权-->
<template>
  <div id="wxLogin">
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getCookie, setCookie } from "@/utils/commit.js";
export default {
  //import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
    //这里存放数据
    return {
      loadingBool: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    GetRequest() {
      var url = window.location.search;
      // url = url.substring(9, url.length);
      var object = {};
      if (url.indexOf("?") != -1) {
        //url中存在问号，也就说有参数。
        var str = url.substr(1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return object;
    },
    is_weixin() {
      let _this = this;
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
      } else {
        _this.$router.push("/");
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
   
    _this.loadingBool = true;
    let obj = _this.GetRequest();
    if (obj != {}) {
      let msg = {
        token: "680b4f91afb709iDAeiRSfb4mFsq5J2hFvuoUMKYf",
        code: obj.code,
        state: obj.state,
        auth: "click",
      };
      _this.$api.wxLoginphone(msg).then((res) => {
        localStorage.userInfo = JSON.stringify(res.data);
        // _this.$router.push( this.$store.state.locationHref)
      // window.location.href =  localStorage.locationHref;

         window.location.href = this.$store.state.locationHref;
      });
    }
    this.is_weixin();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.van-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>