<template>
  <div id="login">
    <div class="logo">
      <img src="@/assets/images/logo.jpg" alt="" />
    </div>
    <div class="wrap_bd">
      <div class="wrap_title">
        <div
          :class="activeShow ? 'active' : ''"
          @click="clickActive('微信登录')"
        >
          微信登录
        </div>
        <span>|</span>
        <div
          :class="!activeShow ? 'active' : ''"
          @click="clickActive('手机号登录')"
        >
          手机号登录
        </div>
      </div>
      <div v-if="activeShow" class="code">
        <wxlogin
          :appid="obj.appid"
          :scope="obj.scope"
          :redirect_uri="obj.redirect_uri"
          :state="obj.state"
          :href="obj.href"
          :secret="obj.secret"
        ></wxlogin>
        <div>可截图或长按保存二维码，在微信中扫描</div>
      </div>
      <div v-if="!activeShow" class="phoneFrom">
        <input
          type="text"
          placeholder="请输入手机号/账号"
          v-model="from.username"
        />
        <input
          type="password"
          placeholder="请输入密码"
          v-model="from.password"
        />
        <van-button
          type="primary"
          :disabled="from.username != '' && from.password != '' ? false : true"
          @click="clickLogin"
          >确定</van-button
        >
        <div class="findPwd">
          <div class="color" @click="clickForget">找回密码</div>
          <div>
            还没有账号？<span class="color" @click="clickRegist">立即注册</span>
          </div>
        </div>
        <div class="ext_tips">未设置密码的用户可点击"找回密码"前往设置</div>
      </div>
    </div>
  </div>
</template>
<script>
import wxlogin from "vue-wxlogin";
import { getCookie, setCookie } from "@/utils/commit.js";
export default {
  data() {
    return {
      activeShow: false, //微信和手机切换
      from: {
        username: "", //账号
        password: "", //密码
      },
      obj: {
        appid: "",
        scope: "snsapi_login",
        redirect_uri: "http%3a%2f%2fm.tk.spanishknow.com%2f%23%2fwxLogin",
        state: "", //随机数
        style: "black",
        href: "",
      },
    };
  },
  components: {
    wxlogin,
  },
  methods: {
    clickActive(val) {
      //tab'切换
      let _this = this;
      if (val == "微信登录") {
        _this.activeShow = true;
      } else {
        _this.activeShow = false;
      }
    },
    clickLogin() {
      //手机号登陆
      let _this = this;
     
      if (_this.from.username == "") {
        Toast.fail("用户名为空");
      } else if (_this.from.password == "") {
        Toast.fail("密码为空");
      } else {
        _this.$api.login(_this.from).then((res) => {
          localStorage.userInfo = JSON.stringify(res.data);
          // 提示信息
          this.$toast.success("欢迎回来");

          // 2秒跳转
          setTimeout(() => {
            // _this.$router.push( this.$store.state.locationHref)
            window.location.href = this.$store.state.locationHref;
            console.log(this.$store.state.locationHref);
          }, 1000);
        });
      }
    },
    // 注册
    clickRegist() {
      let _this = this;
      _this.$router.push("/Regist");
    },
    // 找回密码
    clickForget() {
      let _this = this;
      _this.$router.push("/Forget");
    },
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    is_weixin() {
      let _this = this;
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        _this.$router.push("/wxMMLogin");
      } else {
      }
    },
  },
  beforeCreate() {},
  created() {
    let _this = this;
    // _this.$api.me().then((res) => {
    if (localStorage.userInfo) {
       window.location.href = this.$store.state.locationHref;
    } else {
      _this.is_weixin();
    }
    // });
    let obj = {
      token: "680b4f91afb709iDAeiRSfb4mFsq5J2hFvuoUMKYf",
    };
    _this.$api.getwxinfo(obj).then((res) => {
      _this.obj = res.data;
      _this.obj.style = "black";
      _this.obj.redirect_uri =
        "http%3a%2f%2fm.tk.spanishknow.com%2f%23%2fwxLogin";
      _this.obj.href =
        "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O21hcmlnbi10b3A6LThweH0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ==";
    });
  },
};
</script>
<style lang="less" scoped>
@colorBule: #4395ff;
#login {
  padding: 0 40px;
  width: 100%;
  background: url("../assets/images/bg.png") no-repeat 50%;
  .logo {
    padding: 60px 0;
    width: 100%;
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .wrap_bd {
    background: #fff;
    box-shadow: 0 0 100px 0 rgba(166, 167, 169, 0.2);
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
    padding: 40px;
    // height: 600px;
    font-size: 40px;
    .wrap_title {
      display: flex;
      div {
        width: 50%;
        text-align: center;
        &.active {
          color: @colorBule;
        }
      }
      span {
        color: #ebebeb;
        position: relative;
        top: 2px;
      }
    }
    .code {
      text-align: center;
      padding: 30px 0;
      img {
        width: 380px;
        height: 380px;
        margin: 50px 0;
      }
      div {
        font-size: 28px;
        text-align: center;
      }
    }
    .phoneFrom {
      padding-top: 30px;
      input {
        &:nth-child(1) {
          margin-top: 30px;
        }
        height: 90px;
        padding: 0 30px;
        border: 1px solid #ebebeb;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 50px;
        font-size: 30px;
        &:focus {
          border: 1px solid @colorBule;
        }
      }
      button {
        height: 90px;
        width: 100%;
        background: @colorBule;
        border: none;
        border-radius: 5px;
        color: #fff;
        margin-bottom: 50px;
      }
      .findPwd {
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        color: #999;
        .color {
          color: @colorBule;
        }
      }
      .ext_tips {
        font-size: 26px;
        color: #999;
        text-align: center;
        border-top: 1px solid #ebebeb;
        padding-top: 30px;
      }
    }
  }
}
</style>