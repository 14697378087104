<template>
  <!-- 配音秀 -->
  <div id="DubbingShow">
    <!-- <div class="search">
      <van-search v-model="value" placeholder="请输入搜索关键词" />
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>1</van-swipe-item>
      <van-swipe-item>2</van-swipe-item>
      <van-swipe-item>3</van-swipe-item>
      <van-swipe-item>4</van-swipe-item>
    </van-swipe> -->
    <div class="hot-Dubbingshow">
      <div class="hot-title">
        <div>热门配音</div>
        <div>查看更多></div>
      </div>
      <div class="video-list">
        <div v-for="(item, index) in 3" :key="index" @click="getInto(index)">
          <div class="bgimg">
            <i class="iconfont icon-bofang4"></i>
          </div>
          <div class="name">名字</div>
          <p class="peplo">183人参与</p>
        </div>
      </div>
    </div>
    <!-- <div class="recommend-show">
      <div class="hot-title">
        <div>推荐配音课</div>
        <div>查看更多></div>
      </div>
      <div class="show-class">
        <div v-for="(item, index) in 6" :key="index" @click="imgInfo(index)">
          <img src="../../../assets/images/flower.png" alt="" />
          <div>花木兰</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    imgInfo(index) {
      this.$router.push("/ImgInfo");
    },
    getInto(index) {
      this.$router.push('/DubbingShowDetails')
    },
  },
  created() {
    let obj={
      "filter[id]":398,
      page:1,
      limit:10,
    }
    this.$api.ShowArctype(obj).then((res)=>{
      console.log(res);
    })

    
  },
};
</script>

<style lang="less" scoped>
#DubbingShow {
  padding: 0 30px;

  .van-search {
    padding: 20px 5px;
  }
  .van-swipe {
    border-radius: 5px;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 300px;
    text-align: center;
    background-color: #39a9ed;
    border-radius: 5px;
  }
  .hot-title {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      &:first-child{
        font-size: 56px;
      }
      &:last-child {
        font-size: 26px;
        color: #999;
      }
    }
  }
  .hot-Dubbingshow {
    .video-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 30px;
      > div {
        padding-bottom: 30px;
      }
      .bgimg {
        width: 320px;
        height: 400px;
        background: orange;
        position: relative;
        border-radius: 5px;
        i {
          color: #fff;
          font-size: 48px;
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
      }
      .name {
        font-size: 34px;
        margin-top: 20px;
      }
      .peplo {
        font-size: 26px;
        color: #ccc;
      }
    }
  }
  .recommend-show {
    .show-class {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 30px;
      > div {
        width: 200px;
        height: 350px;
        margin-bottom: 40px;

        img {
          width: 100%;
          height: 300px;
          border-radius: 10px;
        }
        div {
          font-size: 34px;
        }
      }
    }
  }
}
</style>