import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    question: {},
    rowObj: {}, //当前行数据
    username: "", //用户名
    code: "", //验证码
    locationHref: "http://m.tk.spanishknow.com/#/Testsort", //当前位置
  },
  mutations: {
    changeCreated(state, question) {
      state.question = question
    },
    changeRowObj(state, rowObj) {
      state.rowObj = rowObj
    },
    changeUsername(state, username) {
      state.username = username
    },
    changeCode(state, code) {
      state.code = code
    },
    changelocationHref(state, locationHref) {
      state.locationHref = locationHref
    },
  },
  actions: {},
  modules: {}
})