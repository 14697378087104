<template>
  <div id="Forget">
    <div class="logo">
      <img src="@/assets/images/logo.jpg" alt="" />
    </div>
    <div class="main">
      <h6>找回密码</h6>
      <div>
        <input type="text" placeholder="请输入手机号" v-model="from.username" />
        <div class="research">
          <input type="text" v-model="from.research" placeholder="验证码" />
          <button @click="clickSendCode">验证码</button>
        </div>
        <input type="password" placeholder="密码" v-model="from.password" />
      </div>
      <button class="confirm" @click="confirmBtn">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      from: {
        username: "", //账号
        research: "", //验证码
        password: "", //密码
      },
    };
  },
  methods: {
    clickSendCode() {
      let _this = this;
      if (_this.from.username == "") {
        this.$toast.fail("手机号不能空");
      } else {
        let obj = {
          tel: _this.from.username,
          register: "no",
        };
        _this.$api.easySms(obj).then((res) => {
          this.$toast.success("短信发送成功");
        });
      }
    },
    //验证
    confirmBtn() {
      //手机号登陆
      let _this = this;
      if (_this.from.username == "") {
        this.$toast.fail("手机号不能空");
      } else if (_this.from.research == "") {
        this.$toast.fail("验证码不能为空");
      } else if (_this.from.password == "") {
        this.$toast.fail("密码不能为空");
      } else {
        let obj = {
          tel: _this.from.username,
          username: _this.from.username,
          password: _this.from.password,
          code: _this.from.research,
        };
        _this.$api.forgetpwd(obj).then((res) => {
          // 提示信息
          this.$toast.success("找回密码成功,请登录");

          // 1秒跳转
          setTimeout(() => {
            _this.$router.push("/");
          }, 1000);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
#Forget {
  width: 100%;
  padding: 0 40px;
  background: url("../assets/images/bg.png") no-repeat 50%;
  .logo {
    padding: 60px 0;
    width: 100%;
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .main {
    width: 680px;
    height: 700px;
    background: #fff;
    box-shadow: 2px 2px 10px #e9e9e9;

    padding: 60px 40px 0 40px;
    h6 {
      text-align: center;
      font-size: 36px;
      margin-bottom: 40px;
    }
    div {
      input {
        padding: 15px 0;
        border: solid 1px #ccc;
        text-indent: 20px;
        font-size: 28px;
        color: #666;
        &::placeholder {
          font-size: 28px;
        }
        &:first-child,
        &:last-child {
          width: 100%;
        }
      }
      .research {
        display: flex;
        width: 100%;
        margin: 40px 0;
        input {
          flex: 4;
          margin-right: 10px;
          box-sizing: border-box;
        }
        button {
          font-size: 28px;

          flex: 2;
          box-sizing: border-box;
          border: solid 1px #ccc;
          color: rgb(116, 116, 116);
        }
      }
    }
    .confirm {
      width: 100%;
      background: #4395ff;
      color: #fff;
      border: none;
      font-size: 28px;
      padding: 20px 0;
      margin-top: 40px;
    }
    .login {
      margin-left: 340px;
      span {
        font-size: 28px;
        &:first-child {
          color: rgb(163, 163, 163);
        }
        &:last-child {
          color: #0095ff;
        }
      }
    }
  }
}
</style>