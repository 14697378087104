<template>
  <div id="SpecialExercises">
    <!-- 专项练习 -->
    <div class="title">
      <div>{{ name }}</div>
      <div>题型选择</div>
    </div>
    <div
      class="list"
      v-for="(item, index) in textlist"
      :key="index"
      @click="clickSend('专项练习', item.num)"
    >
      <div>
        <img :src="item.imageUrl" alt="" /><span>{{ item.name }}</span>
      </div>
      <div><van-icon name="arrow" /></div>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  data() {
    return {
      textlist: [
        {
          name: "单选题",
          num: 1,
          imageUrl: require("@/assets/images/danxuanti.png"),
        },
        {
          name: "多选题",
          num: 2,
          imageUrl: require("@/assets/images/duoxuanti.png"),
        },
        {
          name: "判断题",
          num: 3,
          imageUrl: require("@/assets/images/panduanti.png"),
        },
        {
          name: "问答题",
          num: 4,
          imageUrl: require("@/assets/images/wendati.png"),
        },
        {
          name: "多元填空",
          num: 6,
          imageUrl: require("@/assets/images/duoyuantiankong.png"),
        },
        {
          name: "阅读理解",
          num: 9,
          imageUrl: require("@/assets/images/yueduti.png"),
        },
        {
          name: "听力题",
          num: 10,
          imageUrl: require("@/assets/images/tingliti.png"),
        },
      ], //试卷分类
      loadingBool: true,
      name: "西语",
    };
  },
  created() {
    let _this = this;
    _this.loadingBool = true;
    _this.name = _this.$route.query.name;
    setTimeout(() => {
      _this.loadingBool = false;
    }, 200);
      localStorage.routerPush="/PersonalCenter/SpecialExercises"

  },
  methods: {
    clickSend(val, num) {
      let _this = this;
      console.log(val, num);
      _this.$router.push({
        path: "/Practice",
        query: {
          val: val,
          num: num,
          id: _this.$route.query.id,
          name: _this.name,
        },
      });
    },
    routerPush() {
      let _this = this;
      _this.$router.push("/PersonalCenter/SpecialExercises");
      
    },
  },
  mounted() {
    bus.$on("routerPush", this.routerPush);
  },
};
</script>

<style lang="less" scoped>
#SpecialExercises {
  width: 100%;
  height: 100%;
  background: #fcfcfc;
  margin-top: 100px;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  > .title {
    width: 100%;
    height: 180px;
    background: url(../../../assets/images/bg1.png) 0 0;
    background-size: 100% 100%;
    padding: 0 40px;
    div {
      font-size: 42px;
      color: #fff;
      align-items: center;
      padding: 25px 0;
      img {
        width: 160px;
        height: 160px;
        margin-top: 20px;
      }
      &:nth-child(2) {
        font-size: 30px;
        padding: 0;
      }
    }
  }
  .list {
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    font-size: 28px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          margin-right: 40px;
        }
      }
    }
  }
}
</style>