<template>
  <div id="Exam">
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
    <div class="title">
      <div>
        <div>
          <i class="iconfont icon-wenjian"></i
          ><span>{{ defaultIndex + 1 }}/{{ total }}</span>
        </div>
        <div v-if="false">
          <i class="iconfont icon-zhengque"></i><span>0</span
          ><i class="iconfont icon-cuowu"></i><span>0</span>
        </div>
        <div @click="clickshowTop">
          <i class="iconfont icon-submit2"></i><span>交卷</span>
        </div>
      </div>
      <p :style="{ width: widthActive + '%' }"></p>
    </div>
    <div class="main">
      <div class="single" v-for="(v, i) in allList" :key="i">
        <div v-show="v.isShowList">
          <!-- 多元填空 -->
          <div id="multipleList" v-if="v.question.type == 6 ? true : false">
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{
                  v.question.type == 6 ? "多元填空题" : ""
                }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>

            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div
                  class="stem"
                  :class="'stem' + i"
                  v-html="v.question.stem"
                  @input="clickInput($event, i)"
                ></div>

                <div
                  class="single_choices"
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                >
                  <span>{{ listSum[index] }}</span>
                  <div style="display: block">{{ item.answer }}</div>
                </div>
              </div>
            </div>
            <div v-if="v.question.stem_type == 2 ? true : false">
              <div class="audioTitle">
                <div class="audioBox">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>
                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                </div>
                <div
                  class="stem"
                  v-html="v.question.stem"
                  :class="'stem' + i"
                  @input="clickInput($event, i)"
                ></div>

                <div
                  class="single_choices"
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                >
                  <span>{{ listSum[index] }}</span>
                  <div style="display: block">{{ item.answer }}</div>
                </div>
              </div>
            </div>
            <div v-if="v.question.stem_type == 4 ? true : false">
              <div class="audioTitle">
                <img
                  v-if="v.question.file"
                  :src="v.question.file.playpath"
                  alt=""
                  @click="clickShowImagePreview(i)"
                />
                <div
                  class="stem"
                  v-html="v.question.stem"
                  @input="clickInput($event, i)"
                  :class="'stem' + i"
                ></div>

                <div
                  class="single_choices"
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                >
                  <span>{{ listSum[index] }}</span>
                  <div style="display: block">{{ item.answer }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="v.isShowList">
          <!-- 单选 -->
          <div v-if="v.question.type == 1 ? true : false">
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 1 ? "单选题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>
            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div class="stem" v-html="v.question.stem"></div>
                <div
                  class="single_choices"
                  @click="clickSingle(i, index)"
                  v-for="(item, index) in v.question.single_choices"
                  :key="index"
                >
                  <span :class="item.isShow ? 'active' : ''">{{
                    listSum[index]
                  }}</span>
                  <p v-html="item.answer"></p>
                </div>
              </div>
            </div>
            <!-- 音频 -->
            <div v-if="v.question.stem_type == 2 ? true : false">
              <div class="audioTitle">
                <div class="audioBox">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>
                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                </div>
              </div>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                class="single_choices"
                @click="clickSingle(i, index)"
                v-for="(item, index) in v.question.single_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="v.question.stem_type == 3 ? true : false">
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                class="single_choices"
                @click="clickSingle(i, index)"
                v-for="(item, index) in v.question.single_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 多选 -->
          <div v-if="v.question.type == 2 ? true : false">
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 2 ? "多选题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>
            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div class="stem" v-html="v.question.stem"></div>

                <div
                  @click="clickMany(i, index)"
                  class="single_choices"
                  v-for="(item, index) in v.question.multiple_choices"
                  :key="index"
                >
                  <span :class="item.isShow ? 'active' : ''">{{
                    listSum[index]
                  }}</span>
                  <p v-html="item.answer"></p>
                </div>
              </div>
            </div>
            <!-- 音频 -->
            <div v-if="v.question.stem_type == 2 ? true : false">
              <div class="audioTitle">
                <div class="audioBox">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>
                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                </div>
              </div>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="clickMany(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.multiple_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="v.question.stem_type == 3 ? true : false">
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="clickMany(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.multiple_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 判断 -->
          <div v-if="v.question.type == 3 ? true : false">
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 3 ? "判断题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>

            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div class="stem" v-html="v.question.stem"></div>

                <div
                  @click="clickJudges(i, index)"
                  class="single_choices"
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                >
                  <span :class="item.isShow ? 'active' : ''"></span
                  >{{ item.answer }}
                </div>
              </div>
            </div>
            <!-- 音频 -->
            <div v-if="v.question.stem_type == 2 ? true : false">
              <div class="audioTitle">
                <div class="audioBox">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>
                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                </div>
              </div>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="clickJudges(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.judges"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''"></span
                >{{ item.answer }}
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="v.question.stem_type == 3 ? true : false">
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="clickJudges(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.judges"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''"></span
                >{{ item.answer }}
              </div>
            </div>
          </div>

          <!-- 阅读题 -->
          <div id="reading" v-if="v.question.type == 9 ? true : false">
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 9 ? "阅读题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>

            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div
                  class="stem"
                  :style="{ height: '100vh' - `${newHeight}px` }"
                  v-html="v.question.stem"
                ></div>

                <div id="box1" :style="{ height: newHeight + 'px' }">
                  <div class="touchmove">
                    <button
                      @touchstart="touchstart($event)"
                      @touchmove="touchmove($event)"
                    >
                      按住拖动可调整页面
                    </button>
                  </div>
                  <div
                    id="box2"
                    class="analysis"
                    :style="{ height: newHeight + 'px' }"
                  >
                    <div v-for="(v1, i1) in v.question.reads" :key="i1">
                      <div>
                        {{ i1 + 1 }}.{{
                          v1.question.stem == "null" ? "" : v1.question.stem
                        }}<span>({{ v1.question.grade }}分)</span>
                      </div>

                      <div
                        class="single_choices"
                        v-for="(item, index) in v1.question.single_choices"
                        :key="index"
                        @click="clickReading(i, i1, index)"
                      >
                        <span :class="item.isShow ? 'active' : ''">{{
                          listSum[index]
                        }}</span>
                        <p v-html="item.answer"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 音频 -->
            <div v-if="v.question.stem_type == 2 ? true : false">
              <div class="stem" :style="{ height: '100vh' - `${newHeight}px` }">
                <div class="audioTitle">
                  <div class="audioBox">
                    <div class="btn">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/images/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ v.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/images/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio controls controlslist="nodownload">
                      <source
                        :src="v.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>
                </div>
                <div v-html="v.question.stem"></div>
              </div>

              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <div v-for="(v1, i1) in v.question.reads" :key="i1">
                    <div>
                      {{ i1 + 1 }}.{{
                        v1.question.stem == "null" ? "" : v1.question.stem
                      }}<span>({{ v1.question.grade }}分)</span>
                    </div>

                    <div
                      class="single_choices"
                      v-for="(item, index) in v1.question.single_choices"
                      :key="index"
                      @click="clickReading(i, i1, index)"
                    >
                      <span :class="item.isShow ? 'active' : ''">{{
                        listSum[index]
                      }}</span>
                      <p v-html="item.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="v.question.stem_type == 3 ? true : false">
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div
                class="stem"
                v-html="v.question.stem"
                :style="{ height: '100vh' - `${newHeight}px` }"
              ></div>
              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <div v-for="(v1, i1) in v.question.reads" :key="i1">
                    <div>
                      {{ i1 + 1 }}.{{
                        v1.question.stem == "null" ? "" : v1.question.stem
                      }}<span>({{ v1.question.grade }}分)</span>
                    </div>

                    <div
                      class="single_choices"
                      v-for="(item, index) in v1.question.single_choices"
                      :key="index"
                      @click="clickReading(i, i1, index)"
                    >
                      <span :class="item.isShow ? 'active' : ''">{{
                        listSum[index]
                      }}</span>
                      <p v-html="item.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 图片 -->
            <div v-if="v.question.stem_type == 4 ? true : false">
              <div class="audioTitle">
                <img
                  v-if="v.question.file"
                  :src="v.question.file.playpath"
                  alt=""
                  @click="clickShowImagePreview(i)"
                />
                <!-- <audio :src="v.question.file.playpath" controls="controls"></audio> -->
              </div>
              <div
                class="stem"
                v-html="v.question.stem"
                :style="{ height: '100vh' - `${newHeight}px` }"
              ></div>
              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <div v-for="(v1, i1) in v.question.reads" :key="i1">
                    <div>
                      {{ i1 + 1 }}.{{
                        v1.question.stem == "null" ? "" : v1.question.stem
                      }}<span>({{ v1.question.grade }}分)</span>
                    </div>

                    <div
                      class="single_choices"
                      v-for="(item, index) in v1.question.single_choices"
                      :key="index"
                      @click="clickReading(i, i1, index)"
                    >
                      <span :class="item.isShow ? 'active' : ''">{{
                        listSum[index]
                      }}</span>
                      <p v-html="item.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 问答 -->
          <div
            id="question-answer"
            v-if="v.question.type == 4 ? true : false"
            style="word-break: break-all"
          >
            <van-overlay :show="v.showModel" @click="v.showModel = false">
              <div class="wrapper" @click.stop>
                <div class="block">
                  <div>请判分</div>
                  <div>
                    <input
                      type="text"
                      v-model="v.modelScore"
                      placeholder="请填写分数，不填写默认为0"
                      @input="inputModelScore(i)"
                    />
                  </div>
                  <div>备注：不能超过最高分</div>
                  <div>
                    <button @click="clickShowJudgeDown">自主判分</button
                    ><button @click="v.showModel = false">不需要</button>
                  </div>
                </div>
              </div>
            </van-overlay>
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 4 ? "问答题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>

            <div v-if="v.question.stem_type == 1 ? true : false">
              <div class="audioTitle">
                <div
                  class="stem"
                  :style="{ height: '100vh' - `${newHeight}px` }"
                  v-html="v.question.stem"
                ></div>

                <div id="box1" :style="{ height: newHeight + 'px' }">
                  <div class="touchmove">
                    <button
                      @touchstart="touchstart($event)"
                      @touchmove="touchmove($event)"
                    >
                      按住拖动可调整页面
                    </button>
                  </div>
                  <div
                    id="box2"
                    class="analysis"
                    :style="{ height: newHeight + 'px' }"
                  >
                    <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                    <textarea
                      v-model="v.modelValue"
                      @change="changeText(i)"
                      type="text"
                      placeholder="请输入答案"
                      ref="yourinput"
                    />

                    <div class="btn">
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <van-button type="danger" @click="clickShowJx(i)">{{
                        v.massage
                      }}</van-button>
                      <van-button
                        type="danger"
                        :class="!v.btnBool ? 'active' : ''"
                        :disabled="!v.btnBool"
                        @click="clickShowJudge(i)"
                        style="background: #47c88a"
                        >{{ v.massageScore }}</van-button
                      >
                    </div>
                    <div class="listAudio">
                      <div v-for="(item, index) in v.listAuido" :key="index">
                        <div class="listAuidoBox">
                          <div
                            class="ad_zan"
                            v-if="item.BofangBool"
                            @click="clickBoAudio(i, index)"
                          ></div>
                          <div
                            class="ad_bo"
                            v-else
                            @click="clickBoAudioStop(i, index)"
                          ></div>
                          {{ item.time }}
                        </div>
                        <div>
                          <span
                            @click="clickRemove(i, index)"
                            class="iconfont icon-cuowu2"
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="analysis" v-show="v.isShowJx">
                      <div class="jiexi">
                        <div>解析:</div>
                        <div v-html="v.question.analysis"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 上传语音弹出层 -->
              <van-popup
                class="classShowAudio"
                v-model="v.showAudio"
                closeable
                :overlay="false"
                position="bottom"
                :style="{ height: '150px' }"
              >
                <div class="time">
                  <div>{{ timeNum }}</div>
                  <span>/</span>
                  <div>01:00</div>
                </div>
                <div class="luyingbox">
                  <div
                    @click="clickPaly(i, true)"
                    v-if="!v.AudioBool"
                    class="luyin"
                  ></div>
                  <div
                    @click="clickPaly(i, false)"
                    v-else
                    class="zanting"
                  ></div>
                </div>
                <p>{{ v.AudioText }}</p>
              </van-popup>
            </div>
            <!-- 图片 -->
            <div v-if="v.question.stem_type == 4 ? true : false">
              <div class="audioTitle">
                <div
                  class="stem"
                  :style="{ height: '100vh' - `${newHeight}px` }"
                >
                  <img
                    v-if="v.question.file"
                    :src="v.question.file.playpath"
                    alt=""
                    @click="clickShowImagePreview(i)"
                  />
                  <div v-html="v.question.stem"></div>
                </div>

                <div id="box1" :style="{ height: newHeight + 'px' }">
                  <div class="touchmove">
                    <button
                      @touchstart="touchstart($event)"
                      @touchmove="touchmove($event)"
                    >
                      按住拖动可调整页面
                    </button>
                  </div>
                  <div
                    id="box2"
                    class="analysis"
                    :style="{ height: newHeight + 'px' }"
                  >
                    <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                    <textarea
                      v-model="v.modelValue"
                      @change="changeText(i)"
                      type="text"
                      placeholder="请输入答案"
                      ref="yourinput"
                    />

                    <div class="btn">
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <van-button type="danger" @click="clickShowJx(i)">{{
                        v.massage
                      }}</van-button>
                      <van-button
                        type="danger"
                        :class="!v.btnBool ? 'active' : ''"
                        :disabled="!v.btnBool"
                        @click="clickShowJudge(i)"
                        style="background: #47c88a"
                        >{{ v.massageScore }}</van-button
                      >
                    </div>
                    <div class="listAudio">
                      <div v-for="(item, index) in v.listAuido" :key="index">
                        <div class="listAuidoBox">
                          <div
                            class="ad_zan"
                            v-if="item.BofangBool"
                            @click="clickBoAudio(i, index)"
                          ></div>
                          <div
                            class="ad_bo"
                            v-else
                            @click="clickBoAudioStop(i, index)"
                          ></div>
                          {{ item.time }}
                        </div>
                        <div>
                          <span
                            @click="clickRemove(i, index)"
                            class="iconfont icon-cuowu2"
                          ></span>
                        </div>
                      </div>
                    </div>

                    <div class="analysis" v-show="v.isShowJx">
                      <div class="jiexi">
                        <div>解析:</div>
                        <div v-html="v.question.analysis"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="v.question.stem_type == 3 ? true : false">
              <div class="audioTitle">
                <video
                  :src="v.question.file.playpath"
                  controls="controls"
                  preload="auto"
                  poster=""
                  x5-video-player-fullscreen="true"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                  playsinline="true"
                  x5-video-player-type="h5"
                ></video>
                <div
                  class="stem"
                  v-html="v.question.stem"
                  :style="{ height: '100vh' - `${newHeight}px` }"
                ></div>

                <div id="box1" :style="{ height: newHeight + 'px' }">
                  <div class="touchmove">
                    <button
                      @touchstart="touchstart($event)"
                      @touchmove="touchmove($event)"
                    >
                      按住拖动可调整页面
                    </button>
                  </div>
                  <div
                    id="box2"
                    class="analysis"
                    :style="{ height: newHeight + 'px' }"
                  >
                    <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                    <textarea
                      v-model="v.modelValue"
                      @change="changeText(i)"
                      type="text"
                      placeholder="请输入答案"
                      ref="yourinput"
                    />

                    <div class="btn">
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <van-button type="danger" @click="clickShowJx(i)">{{
                        v.massage
                      }}</van-button>
                      <van-button
                        type="danger"
                        :class="!v.btnBool ? 'active' : ''"
                        :disabled="!v.btnBool"
                        @click="clickShowJudge(i)"
                        style="background: #47c88a"
                        >{{ v.massageScore }}</van-button
                      >
                    </div>
                    <div class="listAudio">
                      <div v-for="(item, index) in v.listAuido" :key="index">
                        <div class="listAuidoBox">
                          <div
                            class="ad_zan"
                            v-if="item.BofangBool"
                            @click="clickBoAudio(i, index)"
                          ></div>
                          <div
                            class="ad_bo"
                            v-else
                            @click="clickBoAudioStop(i, index)"
                          ></div>
                          {{ item.time }}
                        </div>
                        <div>
                          <span
                            @click="clickRemove(i, index)"
                            class="iconfont icon-cuowu2"
                          ></span>
                        </div>
                      </div>
                    </div>

                    <div class="analysis" v-show="v.isShowJx">
                      <div class="jiexi">
                        <div>解析:</div>
                        <div v-html="v.question.analysis"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 听力 -->
          <div id="listening" v-if="v.question.type == 10 ? true : false">
            <van-overlay :show="v.showModel" @click="v.showModel = false">
              <div class="wrapper" @click.stop>
                <div class="block">
                  <div>请判分</div>
                  <div>
                    <input
                      type="text"
                      v-model="v.modelScore"
                      placeholder="请填写分数，不填写默认为0"
                      @input="inputModelScore(i)"
                    />
                  </div>
                  <div>备注：不能超过最高分</div>
                  <div>
                    <button @click="clickShowJudgeDown">自主判分</button
                    ><button @click="v.showModel = false">不需要</button>
                  </div>
                </div>
              </div>
            </van-overlay>
            <div class="topicTitle">
              <div>
                {{ i + 1 }}.<span
                  v-if="v.question.defined == '' ? false : true"
                  >{{ v.question.defined }}</span
                ><span v-else>{{ v.question.type == 10 ? "听力题" : "" }}</span
                ><span>({{ v.question.grade }}分)</span>
              </div>
              <div class="collection">
                <div v-if="v.question.collection == 1">
                  <van-icon
                    color="#ffb41c"
                    name="star"
                    @click="clickCancel(i, 0, v)"
                  />
                  <span>收藏</span>
                </div>
                <div v-else>
                  <van-icon
                    @click="clickCancel(i, 1, v)"
                    color="#ccc"
                    name="star"
                  />
                  <span>收藏</span>
                </div>
              </div>
            </div>

            <!-- 音频 -->
            <div class="Listen" v-if="v.question.stem_type == 2 ? true : false">
              <div
                class="hearingtype"
                :style="{ height: '100vh' - `${newHeight}px` }"
              >
                <div class="audioTitle">
                  <div class="audioBox" v-if="v.question_class_id != 275">
                    <div class="btn">
                      <div class="back" @click="clickSnd(-15)">
                        <img src="../../assets/images/kuaijin1.png" alt="" />
                      </div>
                      <div>{{ v.question.file.filename }}</div>
                      <div class="forward" @click="clickSnd(15)">
                        <img src="../../assets/images/kuaijin2.png" alt="" />
                      </div>
                    </div>
                    <audio controls controlslist="nodownload">
                      <source
                        :src="v.question.file.playpath"
                        type="audio/mp3"
                      />
                      您的浏览器不支持 HTML5 audio 标签。
                    </audio>
                  </div>
                  <div class="newAudio audioBox" v-else>
                    <audio
                      controls
                      controlslist="nodownload"
                      v-show="false"
                      :ref="'audio' + i"
                      @timeupdate="updateTime"
                      @ended="ended"
                    >
                      <source
                        :src="v.question.file.playpath"
                        type="audio/mp3"
                      />
                    </audio>
                    <div class="filename">{{ v.question.file.filename }}</div>
                    <div class="bottm">
                      <van-icon
                        size="20px"
                        name="pause-circle"
                        v-if="v.pause"
                      />
                      <van-icon
                        size="20px"
                        name="play-circle"
                        @click="clickAudio(i)"
                        v-else
                      />

                      <div class="bottomprogress">
                        <div>{{ formatTime(v.time || 0) }}</div>
                        <div class="progress">
                          <div :style="{ width: v.progress + '%' }"></div>
                        </div>
                        <div>{{ v.question.file.duration }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="stem" v-html="v.question.stem"></div>
              </div>
              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                  <textarea
                    v-model="v.modelValue"
                    @change="changeText(i)"
                    type="text"
                    placeholder="请输入答案"
                  />

                  <div class="btn">
                    <div class="listAudioBtn">
                      <span
                        class="iconfont icon-yuyin"
                        @click="uploadAudio(i)"
                      ></span>
                      <van-button type="danger" @click="uploadAudio(i)">
                        点击录音</van-button
                      >
                    </div>
                    <van-button type="danger" @click="clickShowJx(i)">{{
                      v.massage
                    }}</van-button>
                    <van-button
                      type="danger"
                      :class="!v.btnBool ? 'active' : ''"
                      :disabled="!v.btnBool"
                      @click="clickShowJudge(i)"
                      style="background: #47c88a"
                      >{{ v.massageScore }}</van-button
                    >
                  </div>
                  <div class="listAudio">
                    <div v-for="(item, index) in v.listAuido" :key="index">
                      <div class="listAuidoBox">
                        <div
                          class="ad_zan"
                          v-if="item.BofangBool"
                          @click="clickBoAudio(i, index)"
                        ></div>
                        <div
                          class="ad_bo"
                          v-else
                          @click="clickBoAudioStop(i, index)"
                        ></div>
                        {{ item.time }}
                      </div>
                      <div>
                        <span
                          @click="clickRemove(i, index)"
                          class="iconfont icon-cuowu2"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="analysis" v-if="v.isShowJx">
                    <div class="jiexi">
                      <div>解析:</div>
                      <div v-html="v.question.analysis"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="date">
        <i class="iconfont icon-shijian"></i>
        <van-count-down :time="time" @change="changtime()" />
        <!-- {{ hr }}:{{ min }}:{{ sec }} -->
      </div>
      <div @click="clickCard">
        <i class="iconfont icon-datiqia"></i> <span class="card">答题卡</span>
      </div>
      <div>
        <button
          class="confim"
          :class="upperBool ? 'active' : ''"
          :disabled="!upperBool"
          @click="clickShow(-1)"
        >
          上一题
        </button>
        <button
          class="confim"
          :class="downBool ? 'active' : ''"
          :disabled="!downBool"
          v-if="downBool"
          @click="clickShow(1)"
        >
          下一题
        </button>
        <button class="confim active" v-if="!downBool" @click="clickshowTop">
          交卷
        </button>
      </div>
    </div>

    <van-action-sheet
      v-if="show"
      v-model="show"
      title="答题卡"
      :style="{ height: '50%' }"
    >
      <!-- 答题卡 -->
      <div class="content">
        <h6>
          <span>答题概况</span> (已答{{ countyes }},未答{{
            countno - countyes
          }})
        </h6>
        <div class="circle">
          <div
            :class="item.active ? 'active' : ''"
            v-for="(item, index) in allList"
            :key="index"
            @click="clickActiveTest(index)"
            :style="item.isShowList ? 'color:#25c17c;border-color:#25c17c' : ''"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </van-action-sheet>

    <van-popup
      v-model="showTop"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      class="showTop"
    >
      <div class="content">
        <h6>
          <span>答题概况</span> (已答{{ countyes }},未答{{
            countno - countyes
          }})
        </h6>
        <div class="circle">
          <div
            :class="item.active ? 'active' : ''"
            v-for="(item, index) in allList"
            :key="index"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
      <div class="j-juan">
        <div @click="clickReturn">
          <i class="iconfont icon-fanhui"></i>
          <p>返回</p>
        </div>
        <div @click="clicksubmit">
          <p>交卷</p>
        </div>
      </div>
    </van-popup>
    <van-overlay class="papersShow" :show="papersShow">
      <div class="wrapper">
        <div></div>
        <div>考试时间到了，请交卷</div>
        <div @click="clicksubmit">交卷</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import $ from "jquery";
import { ImagePreview } from "vant";
import wxShare from "@/utils/wxShare.js";
export default {
  head() {
    return {
      title: this.titlename,
    };
  },

  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      loadingBool: true,
      total: 0, //总数
      singleData: [], //单选 1
      manyData: [], //多选 2
      judgeData: [], //判断题 3
      multivariateData: [], //多元填空题 6
      askData: [], //问答题 4
      hearingData: [], //听力题 10
      readingData: [], //阅读题 9
      question: {}, //创建的试卷
      radio: "",
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      // listBool: ["正确","错误"],
      show: false, //答题卡
      showTop: false, //交卷
      date: "", //时间
      countyes: 0, //已答题
      countno: 0, //未答题
      circle: [], //答题卡
      allList: [], //所有数据
      upperBool: false, //上一题
      downBool: true, //下一题
      defaultIndex: 0, //激活题
      widthActive: "", //激活宽度
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      papersShow: false, //交卷show
      multipleList: [], //多元填空input
      multipleListNum: [],
      redList: [], //阅读题小题
      index: 0, //阅读题序号
      allObj: {}, //所有数据
      newHeight: 300,
      images: [],
      time: 0,
      titlename: "",
      timeNumber: 0,
      audioTime: 0,
      timer: "", //计时器
      minutes: 0,
      seconds: 0,
      timeNum: "00:00",
    };
  },
  updated() {
    this.$nextTick(() => {
      $(".audioTitle").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
    this.$nextTick(() => {
      $("#Exam").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
  },
  methods: {
    formatTime(t) {
      let m = parseInt((t % 3600) / 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(t % 60);
      s = s < 10 ? "0" + s : s;
      return m + ":" + s;
    },
    time_to_sec(time) {
      let str = time;
      let arr = str.split(":");
      let ms = parseInt(arr[0] * 60);
      let ss = parseInt(arr[1]);
      let seconds = ms + ss;
      return seconds;
    },
    clickAudio(index) {
      this.$refs["audio" + index][0].play();
      // this.$refs["audio" + index][0].currentTime = 6000;
      this.$set(this.allList[index], "pause", true);
    },
    updateTime(e) {
      let num = this.allList.findIndex((v) => v.isShowList);
      // console.log(this.allList[index]);
      let time = e.target.currentTime;
      // return
      let totalTime = this.allList[num].question.file.duration;
      if (time > this.time_to_sec(totalTime)) {
        this.$set(this.allList[num], "time", this.time_to_sec(totalTime));
      } else {
        this.$set(this.allList[num], "time", time);
      }
      this.allList[num].progress =
        (time / this.time_to_sec(totalTime)).toFixed(3) * 100;
    },
    ended() {
      let num = this.allList.findIndex((v) => v.isShowList);
      this.$set(this.allList[num], "pause", false);
    },
    clickCancel(index, collection, item) {
      let _this = this;
      let str = "";
      if (collection == 0) {
        str = "取消收藏";
      } else {
        str = "收藏";
      }
      _this.$dialog
        .confirm({
          title: str,
        })
        .then(() => {
          let obj = {
            collection: collection /** 收藏动作，1代表收藏，0代表取消收藏 */,
            question_id:
              item.question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
          };

          _this.$api.recordsCollectlist(obj).then((res) => {
            _this.$delete(_this.allList[index].question, "collection");
            _this.$set(_this.allList[index].question, "collection", collection);
            _this.$toast.success(str + "成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      // console.log(audio);
      audio[0].currentTime += time;
      audio[0].play();
    },
    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      _this.images[0] = _this.allList[index].question.file.playpath;
      ImagePreview(_this.images);
    },

    changtime(i) {
      if (this.timeNumber > 0) {
        this.timeNumber -= 1;
        if (this.timeNumber == 0) {
          this.papersShow = true;
          return false;
        }
      }
    },
    touchstart(e) {
      // 按下
      e.preventDefault();
      this.init = e.targetTouches[0].clientY;
      this.parent = document.getElementById("box1");
      this.initHeight = this.parent.offsetHeight;
    },
    touchmove(e) {
      // 移动
      this.end = e.targetTouches[0].clientY;
      if (this.end > 120 && this.end < 500) {
        this.newHeight = -this.end + this.init + this.initHeight;
      }
    },
    inputModelScore(i) {
      // input最大不能超过当前最高分数
      let _this = this;
      if (_this.allList[i].modelScore > _this.allList[i].question.grade) {
        _this.allList[i].modelScore = _this.allList[i].question.grade;
      } else {
        let str = _this.allList[i].modelScore;
        var len1 = str.substr(0, 1);
        var len2 = str.substr(1, 1);
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 == 0 && len2 != ".") {
          str = str.substr(1, 1);
        }
        //第一位不能是.
        if (len1 == ".") {
          str = "";
        }
        //限制只能输入一个小数点
        if (str.indexOf(".") != -1) {
          var str_ = str.substr(str.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
          }
        }
        //正则替换，保留数字和小数点
        str = str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        _this.$delete(_this.allList[i], "modelScore");
        _this.$set(_this.allList[i], "modelScore", str);
        // var reg = /^\d+$|^\d*\.\d+$/g; //只能输入数字和一个小数点的正则表达式
        // if (
        //   _this.allList[i].modelScore != 0 &&
        //   !reg.test(_this.allList[i].modelScore)
        // ) {
        //   _this.$delete(_this.allList[i], "modelScore");
        //   _this.$set(_this.allList[i], "modelScore", 0);
        // }
      }
    },
    clickShowJudge(i) {
      let _this = this;
      this.$delete(this.allList[i], "showModel"); //解决视图不跟新
      _this.$set(_this.allList[i], "showModel", true);

      _this.index = i;
    },
    clickShowJudgeDown() {
      let _this = this;

      if (_this.allList[_this.index].modelScore == "") {
        _this.allList[_this.index].massageScore = 0 + "分";
      } else {
        _this.allList[_this.index].massageScore =
          _this.allList[_this.index].modelScore + "分";
      }

      _this.allList[_this.index].showModel = false;
      _this.allList[_this.index].btnBool = false;
    },

    clickShowJx(i) {
      //解析
      let _this = this;
      if (_this.allList[i].isShowJx) {
        _this.$dialog
          .confirm({
            title: "取消查看解析",
            message: "是否取消查看解析？",
          })
          .then(() => {
            _this.allList[i].massage = "查看解析";
            _this.$set(_this.allList[i], "isShowJx", false);
          });
      } else {
        _this.$dialog
          .confirm({
            title: "查看解析",
            message: "请确认您已完成作答，对照解析后自主判分",
          })
          .then(() => {
            _this.$set(_this.allList[i], "isShowJx", true);
            _this.allList[i].massage = "收起";
          })
          .catch(() => {});
      }
    },

    clickActiveTest(index) {
      // 点击答题卡激活对应题
      let _this = this;
      _this.defaultIndex = index;
      _this.allList.forEach((v, i) => {
        if (index == i) {
          v.isShowList = true;
        } else {
          v.isShowList = false;
        }
        if (
          _this.defaultIndex > 0 &&
          _this.defaultIndex < _this.allList.length - 1
        ) {
          _this.downBool = true; //激活下一题
          _this.upperBool = true; //激活上一题
        } else if (_this.defaultIndex == 0) {
          _this.downBool = true; //激活下一题
          _this.upperBool = false; //去掉上一题
        } else if (_this.defaultIndex == _this.allList.length - 1) {
          _this.downBool = false; //去掉下一题
          _this.upperBool = true; //激活上一题
        }
      });
      clearInterval(this.timer);
      if (
        _this.allList[_this.defaultIndex].question.type == 4 ||
        _this.allList[_this.defaultIndex].question.type == 10
      ) {
        _this.allList[_this.defaultIndex].listAuido.forEach((v, indexx) => {
          v.BofangBool = true;
          wxShare.wx.stopVoice({
            localId: v.localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
          });
        });
      }
    },
    clickReturn() {
      let _this = this;
      _this.showTop = false;
    },
    clickCard() {
      // 点击答题卡
      let _this = this;
      _this.show = true;
      let num = 0;
      _this.allList.forEach((v) => {
        //已答和未答
        if (v.active) {
          ++num;
        }
      });

      _this.countyes = num;
    },
    clickshowTop() {
      // 点击交卷
      let _this = this;
      let num = 0;
      _this.allList.forEach((v) => {
        //已答和未答
        if (v.active) {
          ++num;
        }
      });

      _this.countyes = num;
      _this.showTop = true;
    },
    clickShow(val) {
      //下一题上一题
      let _this = this;
      _this.newHeight = 300;
      $("#Exam audio").each((i, v) => {
        v.pause();
      });
      $("#Exam video").each((i, v) => {
        v.pause();
      });
      _this.defaultIndex += val;
      _this.allList.forEach((v, i) => {
        if (_this.defaultIndex == i) {
          _this.$set(_this.allList[i], "isShowList", true);
        } else {
          _this.$set(_this.allList[i], "isShowList", false);
        }
      });
      clearInterval(this.timer);
      if (
        _this.allList[_this.defaultIndex].question.type == 4 ||
        _this.allList[_this.defaultIndex].question.type == 10
      ) {
        _this.allList[_this.defaultIndex].listAuido.forEach((v, indexx) => {
          v.BofangBool = true;
          wxShare.wx.stopVoice({
            localId: v.localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
          });
        });
      }
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allList.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allList.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },
    clickJudges(i, index) {
      // 判断
      let _this = this;
      _this.allList[i].active = true;
      // _this.allList[i].question.judges[index].isShow = true;
      _this.$delete(_this.allList[i].question.judges[index], "isShow"); //解决视图不跟新
      _this.$set(_this.allList[i].question.judges[index], "isShow", true);

      _this.allList[i].question.judges.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
    },
    clickSingle(i, index) {
      // 单选
      let _this = this;
      _this.allList[i].active = true; //是否显示
      _this.allList[i].doBool = true; //做题

      // _this.allList[i].question.single_choices[index].isShow = true;

      _this.$delete(_this.allList[i].question.single_choices[index], "isShow"); //解决视图不跟新
      _this.$set(
        _this.allList[i].question.single_choices[index],
        "isShow",
        true
      );

      _this.allList[i].question.single_choices.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
    },
    clickMany(i, index) {
      // 多选题
      let _this = this;
      _this.allList[i].active = true;
      if (!_this.allList[i].question.multiple_choices[index].isShow) {
        _this.$delete(
          _this.allList[i].question.multiple_choices[index],
          "isShow"
        ); //解决视图不跟新
        _this.$set(
          _this.allList[i].question.multiple_choices[index],
          "isShow",
          true
        );
      } else {
        _this.$delete(
          _this.allList[i].question.multiple_choices[index],
          "isShow"
        ); //解决视图不跟新
        _this.$set(
          _this.allList[i].question.multiple_choices[index],
          "isShow",
          false
        );
      }
    },
    clickReading(i, i1, index) {
      // 阅读题
      let _this = this;
      _this.allList[i].active = true; //显示答题卡
      let list = _this.allList[i].question.reads[i1];
      // list.question.single_choices[index].isShow = true; //显示激活
      _this.$delete(
        _this.allList[i].question.reads[i1].question.single_choices[index],
        "isShow"
      ); //解决视图不跟新
      _this.$set(
        _this.allList[i].question.reads[i1].question.single_choices[index],
        "isShow",
        true
      );
      list.active = true; //小题是否做了
      list.question.single_choices.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
    },
    records() {
      // 创建试卷
      let _this = this;

      //获取所有数据
      _this.$api.shows(_this.question).then((res) => {
        _this.allObj = res.data.question_class_record;

        _this.time =
          res.data.question_class_record.question_class.durations * 60 * 1000;
        _this.timeNumber = _this.allObj.question_class.durations * 60;

        let allarrlist = [];
        res.data.question_class_record.items.forEach((v, i) => {
          _this.$set(v, "progress", 0);
          v.question.stem = v.question.stem.replace(
            /line-height/g,
            `line-height:50px;`
          );
          v.isShowList = false; //题显示隐藏
          // return
          if (v.question.type == 1) {
            if (v.question_id_son == null) {
              v.isShowList = false; //题显示隐藏
              v.question.single_choices.forEach((v1, i1) => {
                v1.isShow = false;
              });
              // _this.singleData.push(v); //单选
            } else {
              _this.redList.push(v);
            }
          } else if (v.question.type == 2) {
            v.question.multiple_choices.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.manyData.push(v); //多选
          } else if (v.question.type == 3) {
            v.question.judges.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.judgeData.push(v); //判断题
          } else if (v.question.type == 4) {
            v.modelValue = "";
            v.showModel = false; //判分框
            v.massage = "查看解析";
            v.modelScore = "";
            v.massageScore = "自主判分";
            v.btnBool = true; //判分按钮
            v.showAudio = false; //录音版展示
            v.AudioText = "开始录音";
            v.AudioBool = false; //暂停录音
            v.Audiotime = 60000; //倒计时
            v.listAuido = [
              // {
              //   localId: "weixin://resourceid/74f40c87cbe546bb967bc54b44b58bd3",
              //   time: "00:20",
              //   minutes: 0, //分
              //   seconds: 20, //秒
              //   BofangBool: true,
              // },
              // {
              //   localId: "999",
              //   time: "00:30",
              //   minutes: 0, //分
              //   seconds: 23, //秒
              //   BofangBool: true,
              // },
            ]; //录音所有数据
            // _this.askData.push(v); //问答题
          } else if (v.question.type == 6) {
            v.question.stem = v.question.stem.replace(
              /____/g,
              `<input data-id=${v.id} placeholder='点击答题'  />`
            );

            var n = v.question.stem.split("input").length - 1;
            _this.multipleListNum.push({ id: v.id, num: n });
            v.input = [];
            for (let index = 0; index < n; index++) {
              v.input[index] = "";
            }
            // _this.multivariateData.push(v); //多元填空题
          } else if (v.question.type == 9) {
            // _this.readingData.push(v); //阅读题
          } else if (v.question.type == 10) {
            v.modelValue = "";
            // _this.hearingData.push(v); //听力题
            v.massage = "查看解析";
            v.massageScore = "自主判分";
            v.modelScore = "";
            v.btnBool = true; //判分按钮
            v.showModel = false; //判分框
            v.showAudio = false; //录音版展示
            v.AudioBool = false; //暂停录音
            v.AudioText = "开始录音";
            v.Audiotime = 60000; //倒计时
            v.listAuido = []; //录音所有数据
          }
          if (v.question_id_son == null) {
            allarrlist.push(v);
          }
        });
        _this.allList = allarrlist;
        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            item.question.single_choices.forEach((v2) => {
              v2.isShow = false;
            });
            _this.allList.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allList[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allList[_this.defaultIndex].isShowList = true;
        _this.allList.forEach((v) => {
          v.active = false; //是否做题
        });
        _this.total = _this.allList.length;
        _this.countno = _this.allList.length; //未作体术
        _this.loadingBool = false;
      });
    },
    //交卷
    clicksubmit() {
      let _this = this;
      _this.$dialog
        .confirm({
          title: "交卷",
          message: "是否确定交卷",
        })
        .then(() => {
          let list = [];
          _this.allList.forEach((v) => {
            if (v.question.type == 1) {
              //单选
              if (v.active) {
                //做了
                v.question.single_choices.forEach((v1, i1) => {
                  switch (v.yes) {
                    case 0:
                      v.yes = false;
                      break;

                    case 1:
                      v.yes = true;
                      break;
                  }
                  if (v1.isShow) {
                    if (v1.yes == v1.isShow) {
                      list.push({
                        id: v.id, //创建的试题id
                        question_class_id: v.question_class_id, //试题所属试卷id
                        question_class_record_id: v.question_class_record_id, //创建的试卷id
                        question_id: v.question_id,
                        answers: _this.listSum[i1],
                        state: 1,
                        grade: v.question.grade,
                      });
                    } else {
                      list.push({
                        id: v.id, //创建的试题id
                        question_class_id: v.question_class_id, //试题所属试卷id
                        question_class_record_id: v.question_class_record_id, //创建的试卷id
                        question_id: v.question_id,
                        answers: _this.listSum[i1],
                        state: 0,
                        grade: 0,
                      });
                    }
                  }
                });
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            } else if (v.question.type == 2) {
              //多选
              if (v.active) {
                //做了
                let youSel = ""; //你选中的
                let yesSel = ""; //答案;
                v.question.multiple_choices.forEach((v1, i1) => {
                  switch (v.yes) {
                    case 0:
                      v.yes = false;
                      break;
                    case 1:
                      v.yes = true;
                      break;
                  }
                  if (v1.isShow) {
                    youSel += _this.listSum[i1]; //你选中的
                  }
                  if (v1.yes) {
                    yesSel += _this.listSum[i1]; //答案
                  }
                });
                if (youSel == yesSel) {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: youSel,
                    state: 1,
                    grade: v.question.grade,
                  });
                } else {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: youSel,
                    state: 0,
                    grade: 0,
                  });
                }
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            } else if (v.question.type == 3) {
              //判断题
              if (v.active) {
                //做了
                v.question.judges.forEach((v1, i1) => {
                  switch (v.yes) {
                    case 0:
                      v.yes = false;
                      break;

                    case 1:
                      v.yes = true;
                      break;
                  }
                  if (v1.isShow) {
                    if (v1.yes == v1.isShow) {
                      list.push({
                        id: v.id, //创建的试题id
                        question_class_id: v.question_class_id, //试题所属试卷id
                        question_class_record_id: v.question_class_record_id, //创建的试卷id
                        question_id: v.question_id,
                        answers: v1.answer,
                        state: 1,
                        grade: v.question.grade,
                      });
                    } else {
                      list.push({
                        id: v.id, //创建的试题id
                        question_class_id: v.question_class_id, //试题所属试卷id
                        question_class_record_id: v.question_class_record_id, //创建的试卷id
                        question_id: v.question_id,
                        answers: v1.answer,
                        state: 0,
                        grade: 0,
                      });
                    }
                  }
                });
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            } else if (v.question.type == 4) {
              //问答题
              if (v.active) {
                //做了
                // if (v.question.answers[0].answer == v.modelValue) {
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: v.modelValue,
                  state: 1,
                  grade:
                    v.modelScore == ""
                      ? 0
                      : parseFloat(v.modelScore).toFixed(2),
                });

                // } else {
                //   list.push({
                //     id: v.id, //创建的试题id
                //     question_class_id: v.question_class_id, //试题所属试卷id
                //     question_class_record_id: v.question_class_record_id, //创建的试卷id
                //     question_id: v.question_id,
                //     answers: v.modelValue,
                //     state: 0,
                //     grade: 0,
                //   });
                // }
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            } else if (v.question.type == 6) {
              //多元填空题

              if (v.active) {
                //做了
                // let strArr = [];
                let str = "";
                // $("#Exam #multipleList .stem p input").each((i1, v1) => {
                //   if ($(v1).data("id") == v.id) {
                //     strArr.push($(v1).val());
                //     str += $(v1).val() + "+";
                //   }
                // });

                let selStr = [];
                v.question.multiple_clozes.forEach((v1, i1) => {
                  let arr = [];
                  v1.answers.forEach((v2, i2) => {
                    arr.push(v2.answer);
                  });
                  selStr.push(arr);
                });
                let grade = 0;
                let num = 0;
                v.input.forEach((item, index) => {
                  str += item + "+";
                  if (selStr[index].includes(item)) {
                    grade += v.question.multiple_clozes[index].grade;
                    num += 1;
                  }
                });
                if (num == v.input.length) {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: str,
                    state: 1,
                    grade: grade,
                  });
                } else {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: str,
                    state: 0,
                    grade: grade,
                  });
                }
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            } else if (v.question.type == 9) {
              // debugger;
              //阅读题
              v.question.reads.forEach((v1, i1) => {
                if (v1.active) {
                  //做了
                  v1.question.single_choices.forEach((v3, i3) => {
                    switch (v3.yes) {
                      case 0:
                        v3.yes = false;
                        break;

                      case 1:
                        v3.yes = true;
                        break;
                    }
                    if (v3.isShow) {
                      if (v3.yes == v3.isShow) {
                        list.push({
                          id: v1.id,
                          question_class_id: v.question_class_id,
                          question_class_record_id: v.question_class_record_id, //创建的试卷id
                          question_id: v1.question_id,
                          answers: _this.listSum[i3],
                          state: 1,
                          grade: v1.question.grade,
                        });
                      } else {
                        list.push({
                          id: v1.id,
                          question_class_id: v.question_class_id,
                          question_class_record_id: v.question_class_record_id, //创建的试卷id
                          question_id: v1.question_id,
                          answers: _this.listSum[i3],
                          state: 0,
                          grade: 0,
                        });
                      }
                    }
                  });
                } else {
                  //没做
                  list.push({
                    id: v1.id,
                    question_class_id: v.question_class_id,
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v1.question_id,
                    answers: "",
                    state: "",
                    grade: 0,
                  });
                }
              });
            } else if (v.question.type == 10) {
              //听力题
              if (v.active) {
                //做了
                // if (v.question.dictations[0].answer == v.modelValue) {
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: v.modelValue,
                  state: 1,
                  grade: v.modelScore == "" ? 0 : v.modelScore,
                });
                // } else {
                //   list.push({
                //     id: v.id, //创建的试题id
                //     question_class_id: v.question_class_id, //试题所属试卷id
                //     question_class_record_id: v.question_class_record_id, //创建的试卷id
                //     question_id: v.question_id,
                //     answers: v.modelValue,
                //     state: 0,
                //     grade: 0,
                //   });
                // }
              } else {
                //没做
                list.push({
                  id: v.id, //创建的试题id
                  question_class_id: v.question_class_id, //试题所属试卷id
                  question_class_record_id: v.question_class_record_id, //创建的试卷id
                  question_id: v.question_id,
                  answers: "",
                  state: "",
                  grade: 0,
                });
              }
            }
          });
          // return;
          let error_total = 0;
          let grade = 0;

          list.forEach((v) => {
            if (v.state != 1) {
              error_total += 1;
            }
            grade += parseFloat(v.grade);
          });

          // return;
          let obj = {
            id: _this.question.id,
            param: {
              id: _this.question.id, //（创建的试卷id）
              question_class_id: _this.question.question_class_id, //（试卷id）
              user_id: _this.question.user_id, //（用户id）
              name: localStorage.name, //试卷名称 （试卷名称）
              total: list.length, //题目总数（计算一下）
              error_total: error_total, //错题数量（计算一下）
              durations:
                _this.allObj.question_class.durations -
                Math.floor(_this.timeNumber / 60),
              grade: grade,
              items: list,
            },
          };

          _this.$api.submittest(obj).then((res) => {
            _this.$toast.success(res.data.message);
            setTimeout(() => {
              _this.$router.push("./Result");
            }, 1000);
          });
        })
        .catch(() => {});
    },
    clickInput(e, i) {
      //激活样式
      let _this = this;
      let arr = [];
      $(`.stem${i} input`).each((index, item) => {
        arr.push($(item).val());
      });
      _this.allList[i].input = arr;
      if (e.target.localName == "input") {
        if (e.target.value != "") {
          this.allList[i].active = true;
        }
      }
    },
    changeText(i) {
      let _this = this;
      if (_this.allList[i].modelValue != "") {
        _this.allList[i].active = true;
      }
    },
    // 上传语音
    uploadAudio(index) {
      // this.showAudio = true;
      let _this = this;
      // _this.allList[index].showAudio = true;
      _this.$delete(_this.allList[index], "showAudio");
      _this.$set(_this.allList[index], "showAudio", true);
    },
    // 暂停开始语音
    clickPaly(index, bool) {
      let _this = this;

      if (bool) {
        // //平台、设备和操作系统
        var system = {
          win: false,
          mac: false,
          xll: false,
          ipad: false,
        };
        //检测平台
        var p = navigator.platform;
        system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        system.x11 = p == "X11" || p.indexOf("Linux") == 0;
        system.ipad = navigator.userAgent.match(/iPad/i) != null ? true : false;
        //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面
        if (system.win || system.mac || system.xll || system.ipad) {
          _this.$toast("仅支持微信打开,该平台不支持录音功能");
        } else {
          this.boxIndex = index;
          var ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //开始录音
            _this.$delete(_this.allList[index], "AudioBool");
            _this.$set(_this.allList[index], "AudioBool", true);
            _this.$delete(_this.allList[index], "AudioText");
            _this.$set(_this.allList[index], "AudioText", "点击停止");
            _this.timer = setInterval(this.startTimer, 1000); //开启计时器

            wxShare.wx.startRecord({
              success: function () {},
              cancel: function (res) {
                // alert(JSON.stringify(res));
              },
            });
          } else {
            _this.$toast("仅支持微信打开,该平台不支持录音功能");
          }
        }
      } else {
        //结束录音
        clearInterval(this.timer);
        _this.$delete(_this.allList[index], "AudioBool");
        _this.$set(_this.allList[index], "AudioBool", false);
        _this.$delete(_this.allList[index], "showAudio");
        _this.$set(_this.allList[index], "showAudio", false);
        _this.$delete(_this.allList[index], "AudioText");
        _this.$set(_this.allList[index], "AudioText", "重新录音");
        _this.$delete(_this.allList[index], "active"); //解决视图不跟新
        _this.$set(_this.allList[index], "active", true);
        wxShare.wx.stopRecord({
          //把localId放在当前list数组
          success: function (res) {
            _this.allList[index].listAuido.push({
              localId: res.localId, //当前录音id
              time: _this.timeNum, //时间
              minutes: _this.minutes, //分
              seconds: _this.seconds, //秒
              BofangBool: true,
            });
            _this.timeNum = "00:00";
            _this.minutes = 0;
            _this.seconds = 0;
          },
          fail: function (res) {},
        });
      }
    },
    clickBoAudio(i, index) {
      //播放当前录音
      let _this = this;
      // _this.allList[i].listAuido[index].BofangBool = false;
      let localId = _this.allList[i].listAuido[index].localId;
      _this.allList.splice(_this.allList.length); //刷新视图
      _this.allList[i].listAuido.forEach((item, indexx) => {
        if (index != indexx) {
          _this.$delete(item, "BofangBool");
          _this.$set(item, "BofangBool", true);
        } else {
          _this.$delete(item, "BofangBool");
          _this.$set(item, "BofangBool", false);
        }
      });
      wxShare.wx.playVoice({
        localId: localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
      });
      wxShare.wx.onVoicePlayEnd({
        success: function (res) {
          _this.$delete(_this.allList[i].listAuido[index], "BofangBool");
          _this.$set(_this.allList[i].listAuido[index], "BofangBool", true);
        },
      });
    },
    clickBoAudioStop(i, index) {
      let _this = this;
      _this.allList.splice(_this.allList.length); //刷新视图
      _this.$delete(_this.allList[i].listAuido[index], "BofangBool");
      _this.$set(_this.allList[i].listAuido[index], "BofangBool", true);
      let localId = _this.allList[i].listAuido[index].localId;
      wxShare.wx.stopVoice({
        localId: localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
      });
    },
    clickRemove(i, index) {
      let _this = this;
      _this.allList.splice(_this.allList.length); //刷新视图
      _this.allList[i].listAuido.splice(index, 1);
    },
    startTimer() {
      //计时器
      let _this = this;
      _this.seconds += 1;
      if (_this.seconds == 60) {
        _this.seconds = 0;
        _this.minutes = _this.minutes + 1;
        //结束录音
        clearInterval(this.timer);
        _this.allList.splice(_this.allList.length); //刷新视图
        _this.$delete(_this.allList[_this.boxIndex], "AudioBool");
        _this.$set(_this.allList[_this.boxIndex], "AudioBool", false);
        _this.$delete(_this.allList[_this.boxIndex], "showAudio");
        _this.$set(_this.allList[_this.boxIndex], "showAudio", false);
        _this.$delete(_this.allList[_this.boxIndex], "AudioText");
        _this.$set(_this.allList[_this.boxIndex], "AudioText", "重新录音");
        _this.$delete(_this.allList[_this.boxIndex], "active"); //解决视图不跟新
        _this.$set(_this.allList[_this.boxIndex], "active", true);
        wxShare.wx.stopRecord({
          //把localId放在当前list数组
          success: function (res) {
            _this.allList[_this.boxIndex].listAuido.push({
              localId: res.localId, //当前录音id
              time: _this.timeNum, //时间
              minutes: _this.minutes, //分
              seconds: _this.seconds, //秒
              BofangBool: true,
            });
            _this.timeNum = "00:00";
            _this.minutes = 0;
            _this.seconds = 0;
          },
          fail: function (res) {},
        });
      }
      _this.timeNum =
        (_this.minutes < 10 ? "0" + _this.minutes : _this.minutes) +
        ":" +
        (_this.seconds < 10 ? "0" + _this.seconds : _this.seconds);
    },
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: _this.titlename,
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },
  },
  mounted() {
    this.wechatSharefund();
  },
  created() {
    let _this = this;
    _this.$store.commit("changelocationHref", window.location.href);
    // _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
    _this.question = _this.$store.state.question;
    _this.loadingBool = true;
    _this.records();
    this.titlename = this.question.name + " - 西知题库";
  },
  watch: {
    total() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
    },
    // defaultIndex() {
    //   let _this = this;
    //   _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
    //   if (_this.defaultIndex == 0) {
    //     _this.downBool = true; //激活下一题
    //     _this.upperBool = false; //激活上一题
    //   } else if (_this.defaultIndex == _this.allList.length - 1) {
    //     _this.downBool = false; //激活下一题
    //     _this.upperBool = true; //激活上一题
    //   }
    // },
    allList: {
      handler() {
        this.$nextTick(() => {
          $(`.stem${this.defaultIndex} input`).each((index, item) => {
            if (this.allList[this.defaultIndex].question.type == 6) {
              $(item).val(this.allList[this.defaultIndex].input[index]);
            }
          });
        });
        if (this.defaultIndex == this.allList.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#Exam {
  background: #fff;
  width: 100%;
  min-height: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  /deep/ #reading {
    // padding: 0 40px;
    /deep/p {
      line-height: 30px !important;
    }
    /deep/span {
      line-height: 30px !important;
    }
  }
  /deep/.collection {
    > div {
      display: flex;
      align-items: center;
      .van-icon {
        margin-right: 8px;
      }
      span {
        font-weight: 500;
        color: #666;
        font-size: 28px;
        margin-top: 10px;
      }
    }
  }

  /deep/img {
    width: 100%;
    height: 400px;
    padding: 20px;
  }
  /deep/.audioBox {
    // margin: 0 30px;
    position: relative;
    padding-top: 100px;
    box-shadow: 0px 0px 10px #cfcfcf;
    // padding-left: 20px;
    margin-bottom: 20px;

    .btn {
      background: #fff;
      position: absolute;
      z-index: 100;
      display: flex;
      padding: 10px 130px !important;
      padding-bottom: 0 !important;
      padding-left: 100px !important;

      top: 20px;
      width: 100%;
      img {
        width: 60px !important;
        height: 60px !important;
        padding: 0;
      }
      div {
        &:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 430px;
          margin: 0 20px;
        }
      }
      // .forward {
      //   // margin-left: 300px;
      // }
    }
    audio {
      width: 94%;
      height: 110px;
      // outline: none;
      // display: flex;
      // background:#fff !important;
      // filter: invert(180,90,360); //c3 filter(滤镜) 属性
      // color: red;
      margin-left: 20px;
      border-radius: 10px;
    }

    audio::-webkit-media-controls-enclosure {
      // background: rgba(255, 255, 255, 1); //白色底
      /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
      border-radius: 10px;
    }
    .media-controls-container,
    .media-controls-container {
      background: #7eb637;
      //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
      border-radius: 10px;
      // color: red;
    }
    audio::-webkit-media-controls-play-button {
      height: 44px;
      width: 44px;
      min-width: 44px;
      border-radius: 50%;
      flex-basis: 44px;
      // border-color: red;
      //  background: red;
      // background: #000;
      // color: #4280f4;
      // color: #25c17c;
    }
    // source{
    //   background: red;
    // }
    // 音量隐藏
    audio::-webkit-media-controls-volume-control-container {
      display: none !important;
      // color: red;
      // background: red;
    }

    audio::-webkit-media-controls-current-time-display {
      order: 1; //设置弹性盒对象元素的顺序
      color: #000;
      text-shadow: unset;
    }

    audio::-webkit-media-controls-time-remaining-display {
      order: 2;
      color: #000;
      text-shadow: unset;
    }
  }
  .newAudio {
    padding: 0 !important;
    padding: 20px !important;
    margin-left: 20px;
    margin-right: 20px;
    .filename {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    /deep/.el-progress-bar__outer {
      height: 2px !important;
    }
    .bottm {
      display: flex;
      padding-top: 10px;
      align-items: center;
      .van-icon {
        font-size: 30px;
        margin-right: 10px;
      }
      .bottomprogress {
        flex: 1;
        display: flex;
        align-items: center;
        .progress {
          flex: 1;
          background: #ebeef5;
          height: 4px;
          border-radius: 4px;
          margin: 0 10px;
          overflow: hidden;
          div {
            background: #409eff;
            height: 100%;
          }
        }
      }
    }
  }
  /deep/video {
    z-index: 1;
  }
  /deep/#box1 {
    z-index: 100;
  }
  .title {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0px 10px #cfcfcf;

    color: #666;
    font-size: 20px;
    p {
      border: 2px solid #4280f4;
      height: 2px;
      position: absolute;
      top: 98px;
      background: #4280f4;
    }
    > div {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        &:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .iconfont {
      font-size: 32px;
      margin-right: 5px;
    }
    .icon-zhengque {
      color: #25c17c;
    }
    .icon-cuowu {
      color: #f44336;
      font-size: 42px;
    }
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        span {
          margin-right: 20px;
        }
      }
    }
  }
  /deep/ .main {
    /deep/table {
      width: 700px !important;
      overflow-x: auto;
      td {
        border-bottom: 1px solid #000 !important;

        border-right: 1px solid #000 !important;
        border-top: none !important;
        border-left: none !important;
      }

      border-top: 1px solid #000 !important;

      border-left: 1px solid #000 !important;

      border-spacing: 0 !important; /*去掉单元格间隙*/
    }
    /deep/p {
      line-height: 50px !important;
    }
    .classShowAudio {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      height: 300px;
      background: #fff;
      box-shadow: 0px 0px 10px #cfcfcf;
      padding: 30px 140px;
      text-align: center;
      .icon-cuowu1 {
        position: absolute;
        top: 10px;
        right: 18px;
        font-size: 28px;
        color: #ccc;
      }
      .time {
        text-align: center;
        color: #858585 !important;
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        font-size: 28px !important;
        .van-count-down {
          color: #858585 !important;
        }
        span {
          margin: 0 5px;
        }
      }
      .luyingbox {
        margin-top: 50px;
        .luyin {
          width: 100px;
          height: 100px;
          background: url(../../assets/images/luyin.png) no-repeat 0 0;
          background-size: 100% 100%;
          border-radius: 50%;
          margin: 5px auto;
        }
        .zanting {
          width: 100px;
          height: 100px;
          background: url(../../assets/images/zanting.png) no-repeat 0 0;
          background-size: 105% 105%;
          border-radius: 50%;
          margin: 5px auto;
        }
      }
      p {
        text-align: center;
        color: #858585;
      }
    }
    .listAudioBtn {
      display: flex;
      // align-items: center;
      .icon-yuyin {
        font-size: 38px;
        // margin-left: 50px;
      }
      button {
        background: #df3833 !important;
        border: none;
        width: 150px;
        height: 50px;
        font-size: 18px;
        padding: 0;
      }
    }
    .listAudio {
      margin: 0 30px;
      > div {
        display: flex;
        align-items: center;
        .icon-cuowu2 {
          font-size: 32px;
          margin-left: 30px;
        }
      }
      .listAuidoBox {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 5px;
        color: #fff;
        align-items: center;
        // border: 1px solid #000;
        width: 350px;
        height: 70px;
        border-radius: 40px;
        margin: 30px 0;
        background: url(../../assets/images/ad_bg.png) 110px 0 no-repeat #f74f52;
        background-size: 30% 100%;
        .ad_zan {
          background: url(../../assets/images/ad_zan.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .ad_bo {
          background: url(../../assets/images/ad_bo.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }
    .audioTitle {
      margin-top: 40px;
      .bottommsg {
        font-size: 28px;
        color: #000;
        text-align: center;
        margin: 20px 0;
      }
    }
    video {
      margin-top: 40px;
    }
    /deep/input {
      // width: 150px;
      color: #4280f4;
      border: none;
      border-bottom: 1px solid #000;
      font-size: 20px;
      background: #fff;
      text-align: center;
      &::placeholder {
        font-size: 20px;
      }
    }
    .single_choices {
      display: flex;
      // margin-bottom: 100px;
      margin-top: 20px;
      span {
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 50%;
        line-height: 50px;
        background: #eee;
        &.active {
          background: #4280f4;
          color: #fff;
        }
      }
      div {
        width: 500px;
      }
    }

    textarea {
      border-radius: 10px;
      height: 300px;
      width: 686px;
      border: 1px solid #ccc;
      text-align: left;
      resize: none;
      padding: 20px 15px 0px 20px;
    }

    > div {
      font-size: 30px;
      > div {
        line-height: 50px !important;
      }

      .stem {
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
      video {
        width: 100%;
        padding: 0 30px;
      }
      audio {
        margin: 0 30px;
      }
      textarea {
        margin: 0 30px;
      }
      .single_choices {
        span {
          margin-right: 20px;
        }
      }
    }
    #reading {
      .audioTitle {
        #box1 {
          box-shadow: 0px 0px 10px #cfcfcf;
          #box2 {
            padding: 40px 40px 250px 40px;
            .btn {
              padding: 0;
            }
            > div {
              margin-bottom: 40px;
              background: #fff;
              box-shadow: 0px 0px 10px #cfcfcf;
              padding: 20px;
            }
          }
        }
      }
      #box1 {
        #box2 {
          padding: 40px 40px 250px 40px;
          > div {
            margin-bottom: 40px;
            background: #fff;
            box-shadow: 0px 0px 10px #cfcfcf;
            padding: 20px;
          }
        }
      }

      .stem {
        height: 460px;
        overflow: auto;
        padding: 0 30px;
        padding-bottom: 207px !important;
      }

      .goods-left-box {
        height: 600px;
        overflow-y: scroll;
        background: #fff;
      }
      .choce {
        // height: 600px;
        // overflow: auto;
        padding-bottom: 80px !important;
        padding: 0 30px;
        // position: fixed;
        // bottom: 100px;
        > div {
          border-radius: 10px;
          background: #fff;
          box-shadow: 1px 1px 10px #e9e9e9;
          padding: 10px 20px;
          margin-top: 40px;
        }
      }
      /deep/p {
        word-break: break-all;
      }
    }
    .single_choices {
      padding-left: 30px;
      p {
        width: 500px;
      }
    }
    .single {
      // > div {
      //   > div {
      //     >div{

      //     }
      //     &:nth-child(1) {
      //       margin-left: 30px;
      //     }
      //   }
      // }
      .stem {
        padding: 0 30px;
      }
      .choce {
        padding: 0 30px;
      }
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 2px 2px 10px #bebebe;
    padding: 0 30px;
    color: #666;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card {
      color: #4280f4;
    }
    i {
      font-size: 28px;
    }
    .date {
      display: flex;
      align-items: center;
    }
    div {
      .confim {
        width: 150px;
        line-height: 70px;
        background: #fff;
        border: none;
        color: #ccc;
        &.active {
          color: #4280f4;
        }
      }
    }
  }
  .van-action-sheet,
  .van-popup {
    .content {
      h6 {
        padding: 20px 30px;
        font-size: 28px;
        border-bottom: 1px solid #ccc;
        span {
          font-weight: 400;
          margin-right: 20px;
        }
      }
      .circle {
        width: 100%;
        padding: 30px 45px;
        display: flex;
        flex-wrap: wrap;
        max-height: 460px;
        overflow: auto;
        div {
          text-align: center;
          line-height: 100px;
          color: #666;
          font-size: 28px;
          width: 100px;
          height: 100px;
          border: 1px solid #666;
          border-radius: 50%;
          margin-right: 38px;
          margin-bottom: 38px;
          &:nth-child(5n + 5) {
            margin-right: 0;
          }
          &.active {
            background: #25c17c;
            border: none;
            color: #fff !important;
          }
        }
      }
    }
  }

  .showTop {
    .circle {
      max-height: 1100px !important;
    }
  }
  // .van-action-sheet{
  //
  // }
  .van-overlay {
    z-index: 1000;
  }
  .van-popup {
    height: 100%;
    // .van-overlay{
    //   background: none;
    // }
    .j-juan {
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      div {
        &:first-child {
          flex: 1.5;
          border-top: 1px solid #ccc;
          color: #666;
          padding: 10px 0;
          background: #fff;
          i {
            font-size: 36px;
            color: #ccc;
          }
        }
        &:last-child {
          flex: 4.5;
          background: #4280f4;
          padding: 10px 0;
          p {
            color: #fff;
            line-height: 94px;
          }
        }
      }
    }
  }
  .papersShow {
    .wrapper {
      padding: 400px 120px;
      div {
        width: 500px;
        height: 280px;

        &:nth-child(1) {
          background: url("../../assets/images/dialog-bg-3.png") no-repeat
            rgba(247, 49, 82, 1);
          background-size: 500px 260px;
        }
        &:nth-child(2) {
          background: #fff;
          line-height: 280px;
          text-align: center;
          font-size: 35px;
          font-weight: 700;
          border-bottom: 1px solid #ccc;
        }
        &:nth-child(3) {
          height: 100px;
          background: #fff;
          line-height: 100px;
          text-align: center;
          font-size: 30px;
          color: #1989fa;
        }
      }
    }
  }
  #question-answer {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .block {
      width: 600px;
      height: 500px;
      background-color: #fff;
      border-radius: 20px;
      div {
        text-align: center;
        padding: 20px 40px;
        input {
          width: 80%;
          height: 80px;
          border: 1px solid #ccc;
          background: #fff;
        }
        &:nth-child(1) {
          line-height: 100px;
        }
        &:nth-child(3) {
          font-size: 20px;
          color: red;
        }
        &:nth-child(4) {
          margin-top: 20px;
          display: flex;
          justify-content: space-around;
          button {
            width: 150px;
            border: none;
            font-size: 20px;
            &:nth-child(1) {
              background: #47c88a;
              color: #fff;
            }
            &:nth-child(2) {
              border: 1px solid #ccc;
              background: #f5f5f5;
            }
          }
        }
      }
    }

    .audioTitle {
      #box1 {
        #box2 {
          .analysis {
            // padding: 40px;
            padding: 40px 40px 150px 40px;
            .jiexi {
              padding: 20px;
              background: #fff;
              box-shadow: 0px 0px 10px #cfcfcf;
            }
          }
        }
      }

      .stem {
        min-height: 460px;
        overflow: auto;
        padding: 0 30px;
        padding-bottom: 207px !important;
      }

      .goods-left-box {
        overflow: auto;
        // background: #fff;
        padding-bottom: 40px !important;
      }

      .answer {
        textarea {
          box-shadow: 0px 0px 10px #cfcfcf;
          margin-bottom: 20px;
        }
      }
    }
  }
  .btn {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    button {
      background: #4280f4;
      border: none;
      width: 150px;
      height: 50px;
      font-size: 18px;
      padding: 0;
      margin-bottom: 40px;
      &.active {
        background: #ccc;
        color: #000;
      }
    }
  }
  .analysis {
    // padding: 0 20px;
    padding-bottom: 100px;
  }
  #listening {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .block {
      width: 600px;
      height: 500px;
      background-color: #fff;
      border-radius: 20px;
      div {
        text-align: center;
        padding: 20px 40px;
        input {
          width: 80%;
          height: 80px;
          border: 1px solid #ccc;
          background: #fff;
        }
        &:nth-child(1) {
          line-height: 100px;
        }
        &:nth-child(3) {
          font-size: 20px;
          color: red;
        }
        &:nth-child(4) {
          margin-top: 20px;
          display: flex;
          justify-content: space-around;
          button {
            width: 150px;
            border: none;
            font-size: 20px;
            &:nth-child(1) {
              background: #47c88a;
              color: #fff;
            }
            &:nth-child(2) {
              border: 1px solid #ccc;
              background: #f5f5f5;
            }
          }
        }
      }
    }
    div {
      &:nth-child(3) {
        .hearingtype {
          height: 460px;
          overflow: auto;
          // padding: 0 30px;
          padding-bottom: 207px !important;
        }
        .vdr.active:before {
          outline: none;
        }
        .vdr {
          // position: fixed;
          // bottom: 100px;
          width: 100% !important;
          background: #fff;
          box-shadow: 0px 0px 10px #c3c3c3;
          // overflow: auto;
          // touch-action: auto;
          /deep/.vdr-stick {
            display: none;
          }
          /deep/.vdr-stick-tm {
            display: block;
            width: 300px !important;
            height: 50px !important;
            margin-left: -150px !important;
            top: 2px !important;
            border-radius: 0px 0px 40px 40px;
            background: rgba(255, 255, 255, 0);
            border: none;
          }
          .touchmove {
            text-align: center;
            margin-bottom: 20px;
            // background: red;
            button {
              width: 300px !important;
              height: 58px;
              line-height: 40px;
              overflow: hidden;
              border-radius: 0px 0px 45px 45px;
              font-size: 20px !important;

              padding: 0 30px;
              border: none;
              color: #999;
              background: #f7f7f7;
            }
          }
        }
        .goods-left-box {
          overflow: auto;
          background: #fff;
          padding-bottom: 40px !important;
        }
      }
    }
    .Listen {
      #box1 {
        #box2 {
          .analysis {
            padding: 0 30px 100px 30px;
            .jiexi {
              background: #fff;
              padding: 30px;
              box-shadow: 0px 0px 10px #cfcfcf;
            }
          }
        }
      }
    }
  }

  #box1 {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px 0px 10px #cfcfcf;
    padding-bottom: 100px;
    .touchmove {
      text-align: center;
      margin-bottom: 20px;
      // background: red;
      button {
        width: 300px !important;
        height: 58px;
        line-height: 40px;
        overflow: hidden;
        border-radius: 0px 0px 45px 45px;
        font-size: 20px !important;

        padding: 0 30px;
        border: none;
        color: #999;
        background: #f7f7f7;
      }
    }
  }
  #box2 {
    overflow: auto;
    // background: red;
  }
  img {
    width: 100%;
    height: 400px;
    padding: 20px;
  }
  // .audioTitle {
  //   padding: 0 30px;
  // }
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  // 标题
  .topicTitle {
    display: flex;
    justify-content: space-between;

    div {
      font-weight: bold;
      padding: 0 20px;
    }
  }
}
</style>
