<template>
  <!-- 配音秀 -->
  <div id="DubbingShowDetails">
    <!-- 配音秀详情 -->
    <div class="vanicon">
      <van-icon
        @click="returnup"
        name="arrow-left"
        color="#fff"
        v-show="isShowIcon"
      ></van-icon>
    </div>
    <div class="top">
      <!-- <div id="vs"></div> -->
      <video
        id="vds"
        src="http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4"
        :controls="true"
        preload="auto"
        poster="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1400380998,2889488836&fm=26&gp=0.jpg"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        :show-center-play-btn="false"
        x5-video-player-type="h5"
        @play="playVideo"
        @pause="pauseVideo"
        @ended="ztstartRecor"
        @touchstart="touchstartVideo"
      ></video>
    </div>
    <div class="audiolistBox" v-if="audiolist.length > 0">
      <div class="audiolist" v-for="(item, index) in audiolist" :key="index">
        <audio :id="'audio' + index" :src="item.url" controls></audio>
        <div class="listAuidoBox">
          <div
            class="ad_zan"
            v-if="!item.isBofan"
            @click="clickBofangLu(index)"
          ></div>
          <div class="ad_bo" v-else @click="clickzntinLu(index)"></div>
          <div>{{ item.time }}s</div>
        </div>
        <div>
          <span class="iconfont icon-cuowu2" @click="clickDel(index)"></span>
        </div>
      </div>
    </div>

    <div v-if="!startRecor" class="title">
      <div>
        <div @click="clickBofang">
          <span class="iconfont icon-zanting" v-if="!Bofang"></span>
          <img src="@/assets/images/yy.gif" alt="" v-else />
        </div>
        <div><span class="iconfont icon-yuyin" @click="recording"></span></div>
      </div>

      <!-- <van-button type="primary" @click="recording">主要按钮</van-button>
      <van-button type="primary" @click="playRecorder">播放录音</van-button> -->
    </div>
    <div v-else class="yingbo">
      <div>点击波纹停止录音</div>
      <img src="@/assets/images/yingbo.gif" alt="" @click="ztstartRecor" />
    </div>
    <div class="article">
      Para quienes nacimos hacia la mitad del pasado siglo, en España Franco ha
      sido una figura omnipresente en nuestras vidas. Mientras vivió, las
      llenaba por entero, y después de muerto, regresa cada poco como un vampiro
      (吸血鬼) moderno para recordarnos que nunca se ha ido del todo. Para
      quienes nacimos hacia la mitad del pasado siglo, en España Franco ha sido
      una figura omnipresente en nuestras vidas. Mientras vivió, las llenaba por
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      moderno para recordarnos que nunca se ha ido del todo. Para quienes
      nacimos hacia la mitad del pasado siglo, en España Franco ha sido una
      figura omnipresente en nuestras vidas. Mientras vivió, las llenaba por
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      moderno para recordarnos que nunca se ha ido del todo. Para quienes
      nacimos hacia la mitad del pasado siglo, en España Franco ha sido una
      figura omnipresente en nuestras vidas. Mientras vivió, las llenaba por
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      entero, y después de muerto, regresa cada poco como un vampiro (吸血鬼)
      moderno para recordarnos que nunca se ha ido del todo.
    </div>
    <div class="bottom">
      <van-button type="danger" @click="clickSend">保存配音</van-button>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import Recorder from "js-audio-recorder";
import "video.js/dist/video-js.css";
let recorder = new Recorder();
export default {
  data() {
    return {
      isShowIcon: true, //图标显示
      startRecor: false, //是否录音
      audiolist: [], //录音数组
      Bofang: false, //视频播放
      player: "",
      audioTime: 0,
    };
  },
  methods: {
    //按下录音（方法）
    recording(e) {
      let that = this;
      that.Bofang = false;
      //获取录音时长，这个可全局监听
      recorder.onprogress = function (params) {
        that.rideoTimesNow = parseInt(params.duration);
        // console.log(params.duration);
        that.audioTime = params.duration.toFixed(2);
      };

      // 开始录音
      recorder.start().then(
        () => {
          this.startRecor = true; //开始录音标识
          document.querySelector("#vds").play();
        },
        (error) => {
          // 出错了
          // console.log(error);
          // this.$toast.fail("请不要重复点击");
        }
      );
      this.startRecor = true; //开始录音标识
      document.querySelector("#vds").play();
    },
    ztstartRecor() {
      if (this.startRecor) {
        //判断是否已经开始
        if (this.rideoTimesNow < 1) {
          //判断录音时长小于一秒停止录音
          this.$toast.fail("录音时间太短");
        } else {
          let wavData = recorder.getWAVBlob(); //获取wav数据
          let files = new window.File([wavData], "新语音", {
            type: "audio/wav",
          }); //blob数据转化为文件信息（这是上传服务器时需要的转换，也可以不转换直接存储二进制blob数据，不了解的可以百度或者直接问知道后台的）
          this.audiolist.push({
            url: URL.createObjectURL(files),
            isBofan: false,
            time: this.audioTime,
          });
          this.startRecor = false; //结束重置录音状态
          // document.querySelector("#vds").pause();
          // 重置下currentTime属性
          document.querySelector("#vds").currentTime = 0.1;
          document.querySelector("#vds").pause();
        }
      }
    },
    // // 录音播放
    // playRecorder() {
    //   recorder.play();
    // },
    returnup() {
      this.$router.push("/DubbingShowDetails");
    },
    clickDel(index) {
      let _this = this;
      _this.audiolist.splice(index, 1);
      document.querySelector("#vds").currentTime = 0.1;
      document.querySelector("#vds").pause();
    },
    // 播放视频
    clickBofang() {
      let _this = this;
      this.$nextTick(() => {
        _this.Bofang = !_this.Bofang;
        if (_this.Bofang) {
          document.querySelector("#vds").play();
        } else {
          document.querySelector("#vds").pause();
        }
      });
      // console.log(this.player);
    },
    // 播放录音
    clickBofangLu(index) {
      let _this = this;
      _this.audiolist.forEach((v, i) => {
        if (i == index) {
          _this.$delete(_this.audiolist[index], "isBofan");
          _this.$set(_this.audiolist[index], "isBofan", true);
          document.querySelector("#vds").currentTime = 0.1;
          document.querySelector("#vds").play();
          document.querySelector(`#audio${index}`).play();
        } else {
          _this.$delete(_this.audiolist[i], "isBofan");
          _this.$set(_this.audiolist[i], "isBofan", false);
          document.querySelector(`#audio${i}`).pause();
          document.querySelector(`#audio${i}`).currentTime = 0;
        }
      });
    },
    // 暂停录音
    clickzntinLu(index) {
      let _this = this;
      _this.audiolist.forEach((v, i) => {
        if (i == index) {
          _this.$delete(_this.audiolist[index], "isBofan");
          _this.$set(_this.audiolist[index], "isBofan", false);
          document.querySelector("#vds").currentTime = 0.1;
          document.querySelector("#vds").pause();
          document.querySelector(`#audio${i}`).pause();
          document.querySelector(`#audio${i}`).currentTime = 0;
        }
      });
    },
    // 保存录音
    clickSend() {
      let _this = this;
      if (_this.audiolist.length > 0) {
        _this.$router.push('/Submit')
      } else {
        _this.$toast.fail("还未录音");
      }
    },
    pauseVideo() {
      //视频暂停
      let _this = this;
      _this.Bofang = false;
      _this.isShowIcon = true;
    },
    playVideo() {
      //视频开始
      let _this = this;
      _this.Bofang = true;
      setTimeout(() => {
        _this.isShowIcon = false;
      }, 500);
    },
    touchstartVideo() {
      // 点击视频
      let _this = this;
      if (!_this.isBofang) {
        _this.isShowIcon = true;
      } else {
        setTimeout(() => {
          _this.isShowIcon = true;
        }, 400);
        setTimeout(() => {
          _this.isShowIcon = false;
        }, 3000);
      }
    },
  },
  watch: {},
  created() {
    Recorder.getPermission().then(
      () => {
        //点击录音按钮获取权限，也可在created里进入页面就获取录音权限
        console.log("给权限了");
      },
      (error) => {
        // alert(`${error.name} : ${error.message}`);
        console.log(`${error.name} : ${error.message}`);
      }
    );
  },
  mounted() {
    this.$nextTick(() => {
      var gtx = $("#vds");
      console.log(gtx);
      console.log(gtx[0].shadowRoot);
    });
  },
};
</script>

<style lang="less" scoped>
#DubbingShowDetails {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding-bottom: 100px;
  padding-top: 420px;
  background: #f5f5f5;
  .vanicon {
    position: fixed;
    z-index: 1000;
    top: 0px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    line-height: 80px;
    .van-icon {
      font-size: 50px;
      margin-top: 20px;
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .top {
    background: #f5f5f5;
    width: 100%;
    position: fixed;
    z-index: 99;
    height: 420px;
    top: 0;
    // border-bottom: 1px solid #f60;
    // video {
    //   width: 100%;
    //   height: 400px;
    // }
    // .van-icon {
    //   position: absolute;
    //   top: 20px;
    //   color: #fff;
    //   z-index: 1000;
    // }
  }

  .title {
    // margin-top: 420px;
    background: #fff;
    padding: 30px;
    margin: 20px;
    box-shadow: 0px 0px 10px #cfcfcf;
    border-radius: 20px;
    padding-left: 250px;
    > div {
      display: flex;
      align-items: center;
      > div {
        width: 80px;
        height: 80px;
        line-height: 60px;
        background: #818898;
        border-radius: 50%;
        text-align: center;
        font-size: 60px;
        margin-right: 40px;
        &:nth-child(1) {
          .iconfont {
            line-height: 80px;
            padding-left: 8px;
          }
        }
        &:nth-child(2) {
          font-size: 80px;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .iconfont {
      font-size: 40px;
      color: #fff;
    }
  }
  .audiolistBox {
    padding: 30px;
    background: #fff;
    margin: 20px;

    box-shadow: 0px 0px 10px #cfcfcf;
    border-radius: 20px;
    .audiolist {
      margin: 0 30px;
      display: flex;
      > div {
        display: flex;
        align-items: center;
        .icon-cuowu2 {
          font-size: 32px;
          margin-left: 30px;
        }
      }
      audio {
        display: none;
      }
      .listAuidoBox {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 5px;
        color: #fff;
        align-items: center;
        // border: 1px solid #000;
        width: 350px;
        height: 70px;
        border-radius: 40px;
        margin: 20px 0;
        background: url(../../../assets/images/ad_bg.png) 110px 0 no-repeat
          #f74f52;
        background-size: 30% 100%;
        .ad_zan {
          background: url(../../../assets/images/ad_zan.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .ad_bo {
          background: url(../../../assets/images/ad_bo.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        > div {
          &:last-child {
            font-size: 26px;
          }
        }
      }
    }
  }
  .yingbo {
    background: #fff;
    box-shadow: 0px 0px 10px #cfcfcf;
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
    div {
      text-align: center;
      padding-top: 40px;
      font-size: 28px;
    }
    /deep/ img {
      width: 100% !important;
      height: 100px;
    }
  }
  .article {
    background: #fff;
    margin-top: 20px;
    margin: 20px;
    font-size: 30px;
    padding: 30px;
    box-shadow: 0px 0px 10px #cfcfcf;
    border-radius: 20px;
  }
  .bottom {
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 0;
    button {
      width: 100%;
      background: #df3833;
      border: none;
    }
  }
}
</style>