<template>
  <!-- 配音秀 -->
  <div id="DubbingShowDetails">
    <div class="vanicon">
      <van-icon
        @click="returnup"
        name="arrow-left"
        color="#fff"
        v-show="isShowIcon"
      ></van-icon>
    </div>
    <!-- 配音秀详情 -->

    <div class="top">
      <!-- <div id="vs"></div> -->
      <video
        id="vds"
        src="http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4"
        controls="controls"
        preload="auto"
        poster="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg&refer=http%3A%2F%2Fa4.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616305492&t=e9cfbd584f7393baf923892a070c9c2f"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        :show-center-play-btn="false"
        x5-video-player-type="h5"
        @touchstart="touchstartVideo"
        @pause="pauseVideo"
        @play="playVideo"
      ></video>
    </div>
    <div class="introduction">
      <div>
        <div>媒婆为木兰说媒</div>
      </div>
      <div>
        媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒
      </div>
      <div>已有999阅读本文</div>
    </div>
    <div class="mypoduct">
      <div>我的作品</div>
    </div>
    <div class="headPortrait">
      <div>共有999人完成本课</div>
      <ul>
        <li v-for="item in 20" :key="item" @click="showDetaillatext">
          <img
            src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg&refer=http%3A%2F%2Fa4.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616305492&t=e9cfbd584f7393baf923892a070c9c2f"
            alt=""
          />
          <div>精神小伙</div>
        </li>
      </ul>
    </div>
    <div class="rankingList">
      <div>点赞排行榜</div>
      <ul>
        <li v-for="(item, index) in 20" :key="index" @click="showDetaillatext">
          <div>{{ index + 1 }}</div>
          <div>
            <img
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg&refer=http%3A%2F%2Fa4.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616305492&t=e9cfbd584f7393baf923892a070c9c2f"
              alt=""
            />
            <div class="rankingListBox">
              <div>
                <div>
                  <div class="rankingListBox_title">
                    精神小伙精神小伙精神小伙精神小伙
                    精神小伙精神小伙精神小伙精神小伙
                  </div>
                  <div>2021-01-10 22:29:34</div>
                </div>
                <div class="icon">
                  <span>1</span><i class="iconfont icon-dianzan"></i>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <van-button type="danger" @click="clickSend">我要配音</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowIcon: true, //左上角图标显示
      isBofang: false, //视频默认不播放
    };
  },
  methods: {
    clickSend() {
      let _this = this;
      _this.$router.push("/DubbingShowDetailsInfo");
    },
    returnup() {
      this.$router.push("/DubbingShow");
    },
    showDetaillatext() {
      this.$router.push("/DubbingShowDetailstext");
    },
    touchstartVideo() {
      // 点击视频
      let _this = this;
      if (!_this.isBofang) {
        _this.isShowIcon = true;
      } else {
        setTimeout(() => {
          _this.isShowIcon = true;
        }, 400);
        setTimeout(() => {
          _this.isShowIcon = false;
        }, 3000);
      }
    },
    pauseVideo() {
      //视频暂停
      let _this = this;
      _this.isBofang = false;
      _this.isShowIcon = true;
    },
    playVideo() {
      //视频开始
      let _this = this;
      _this.isBofang = true;
      setTimeout(() => {
        _this.isShowIcon = false;
      }, 500);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#DubbingShowDetails {
  width: 100%;
  min-height: 100%;
  margin: 0;
  background: #f5f5f5;
  .vanicon {
    position: fixed;
    z-index: 1000;
    top: 0px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    line-height: 80px;
    .van-icon {
      font-size: 50px;
      margin-top: 20px;
    }
  }

  //播放按钮

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .top {
    width: 100%;
    position: fixed;
    z-index: 99;
    height: 420px;
    top: 0;
    // border-bottom: 1px solid #f60;
    // video {
    //   width: 100%;
    //   height: 400px;
    // }
    // .van-icon {
    //   position: absolute;
    //   top: 20px;
    //   color: #fff;
    //   z-index: 1000;
    // }
  }
  .introduction {
    margin-top: 440px;
    padding: 20px;
    background: #fff;
    border-bottom: 1px solid #e3e8f1;
    margin-bottom: 20px;
    > div {
      font-size: 30px;
      &:nth-child(1) {
        margin: 5px 0 15px 0;
        display: flex;
        justify-content: space-between;
        div {
          &:nth-child(2) {
            font-size: 28px;
            color: #7d7d7d;
            .icon-fanhui {
              font-size: 28px;
            }
          }
        }
      }
      &:nth-child(2) {
        color: #7d7d7d;
        margin-bottom: 10px;
      }
      &:nth-child(3) {
        color: #7d7d7d;
        font-size: 28px;
      }
    }
  }
  .mypoduct {
    background: #fff;
    border-top: 1px solid #e3e8f1;
    border-bottom: 1px solid #e3e8f1;
    margin-bottom: 20px;
  }
  .headPortrait {
    background: #fff;
    border-top: 1px solid #e3e8f1;
    border-bottom: 1px solid #e3e8f1;
    margin-bottom: 20px;

    > div {
      padding: 20px;
      font-size: 30px;
      // padding-bottom: ;
      &:nth-child(1) {
        margin: 5px 0 15px 0;
      }
    }
    ul {
      display: flex;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 15px;
      &::-webkit-scrollbar {
        height: 0;
      }
      li {
        padding: 0 15px;
        &:nth-child(1) {
          padding-left: 20px;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        div {
          font-size: 22px;
          text-align: center;
          padding: 0 10px;
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 1; //显示的行
        }
      }
    }
  }
  .rankingList {
    padding: 20px;
    background: #fff;
    border-top: 1px solid #e3e8f1;
    margin-bottom: 20px;
    > div {
      font-size: 30px;
      &:nth-child(1) {
        margin: 5px 0 10px 0;
      }
    }
    ul {
      width: 100%;
      padding-bottom: 15px;
      font-size: 26px;
      padding-bottom: 48px;
      li {
        display: flex;
        padding: 20px 0;
        &:last-child {
          > div {
            &:nth-child(2) {
              border-bottom: none;
            }
          }
        }
        > div {
          &:nth-child(1) {
            margin-right: 20px;
            line-height: 100px;
            width: 20px;
            text-align: center;
          }
          &:last-child {
            border-bottom: none;
          }
          &:nth-child(2) {
            display: flex;
            border-bottom: 1px solid #e3e8f1;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin-right: 20px;
            }
            .rankingListBox {
              width: 560px;

              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 5px;
                &:nth-child(1) {
                  .rankingListBox_title {
                    width: 400px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box; //作为弹性伸缩盒子模型显示。
                    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
                    -webkit-line-clamp: 1; //显示的行
                    margin-bottom: 20px;
                  }

                  span {
                    margin-right: 35px;
                  }
                  margin-bottom: 18px;
                }
                &:nth-child(2) {
                  color: #7d7d7d;
                }
                .icon {
                  .iconfont {
                    font-size: 28px;
                  }
                }
              }
            }
          }
        }
        .icon-dianzan {
          // color: #7d7d7d;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 0;
    button {
      width: 100%;
      background: #df3833;
    }
  }
}
</style>