<template>
  <div id="PersonalCenterIndex">
    <div class="title"></div>
    <div class="detail-wrap">
      <div class="item" v-for="(item, index) in numList" :key="index">
        <p class="num">{{ item.num }}</p>
        <p class="title">{{ item.title }}</p>
      </div>
    </div>
    <div class="main">
      <div class="question">
        <div>题库中心<span>（针对性练习）</span></div>
      </div>
      <ul>
        <li @click="error">错题练习<span></span></li>
        <li @click="clickSpecialExercises">专项练习<span></span></li>
        <li @click="clickSendCollection">我的收藏<span></span></li>
        <li @click="clickSendRankingList">排行榜<span></span></li>
      </ul>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import wxShare from "@/utils/wxShare.js";
import bus from "@/utils/bus";

export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      loadingBool: true,
      titlename: "个人中心 - 西知题库",
      numList: [
        {
          num: 0,
          title: "错题总数",
        },
        {
          num: 0,
          title: "已答题数",
        },
        {
          num: 0,
          title: "收藏题数",
        },
      ],
    };
  },
  methods: {
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: _this.titlename,
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },

    clickSendRankingList() {
      // 排行榜
      let _this = this;
      _this.$router.push("/PersonalCenter/RankingList");
    },
    clickSendCollection() {
      // 收藏
      let _this = this;
      _this.$router.push("/PersonalCenter/Collection");
    },
    clickSpecialExercises() {
      // 专项练习
      let _this = this;
      _this.$router.push("/PersonalCenter/SpecialExercises");
    },
    getAlllist() {
      let _this = this;
      _this.$api.index().then((res) => {
        _this.numList[0].num = res.data.qcounterror; //错题总数
        _this.numList[1].num = res.data.qcount; //已答题数
        _this.numList[2].num = res.data.qcountcollection; //错题总数
        _this.loadingBool = false;
      });
    },
    error() {
      let _this = this;
      _this.$router.push("/PersonalCenter/Showanalysis");
      _this.loadingBool = false;
    },
    routerPush() {
      let _this = this;
      _this.$router.push("/Testsort");

    },
  },
  mounted() {
    this.wechatSharefund();
    bus.$on("routerPush", this.routerPush);
  },
  created() {
    let _this = this;
    _this.loadingBool = true;
    _this.getAlllist();
      localStorage.routerPush="/Testsort"

  },
};
</script>

<style lang="less" scoped>
#PersonalCenterIndex {
  background: #fcfcfc;
  width: 100%;
  min-height: 100%;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  .top {
    padding: 0 30px;
    height: 96px;
    width: 100%;
    z-index: 10;
    background: #fff;
    border-bottom: 1px solid #dfe1e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    .returndown {
      font-size: 28px;
      i {
        font-size: 28px;
        margin-right: 5px;
      }
    }
    img {
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
  }

  > .title {
    width: 100%;
    height: 244px;
    background: url(../../../assets/images/wrong-book-bg.png) 0 0;
    background-size: 100% 100%;
    margin-top: 100px;
  }
  .detail-wrap {
    display: flex;
    font-size: 28px;
    width: 710px;
    padding: 50px 20px;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0px 5px 14px #cfcfcf;
    background-color: #fff;
    margin: -150px auto 0;
    .item {
      width: 220px;
      height: 100%;
      text-align: center;
      .num {
        font-size: 40px;
      }
      .title {
        color: #999;
        line-height: 60px;
      }
    }
  }
  .main {
    margin: 0 25px;
    .question {
      padding: 85px 0 60px 0;
      font-size: 40px;
      font-weight: 700;
      color: #333;
      display: flex;
      span {
        font-size: 28px;
        font-weight: 500;
        color: #999;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 330px;
        height: 200px;
        line-height: 200px;
        margin-bottom: 30px;
        border-radius: 20px;
        overflow: hidden;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 30px;
          height: 30px;
          display: inline-block;
          background: url(../../../assets/images/right.png) 0 0;
          background-size: 100% 100%;
          margin-left: 5px;
        }
        &:nth-child(2n-1) {
          margin-right: 30px;
        }
        &:nth-child(1) {
          background: url(../../../assets/images/random-bg.png) 0 0;
          background-size: 100% 100%;
        }
        &:nth-child(2) {
          background: url(../../../assets/images/order-bg.png) 0 0;
          background-size: 100% 100%;
        }
        &:nth-child(3) {
          background: url(../../../assets/images/error-bg.png) 0 0;
          background-size: 100% 100%;
        }
        &:nth-child(4) {
          background: url(../../../assets/images/more-bg.png) 0 0;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>