<template>
  <div class="timer">
    <div>{{ timeNum }}</div>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    time: {
      type: Number,
    },
  },
  data() {
    return {
      timer: "",
      minutes: 0,
      seconds: 0,
      timeNum: "00:00",
    };
  },
  created() {
    this.timer = setInterval(this.startTimer, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      this.seconds += 1;
      if (this.seconds == 60) {
        this.seconds = 0;
        this.minutes = this.minutes + 1;
        clearInterval(this.timer);
      }
      this.timeNum =
        (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
        ":" +
        (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },
    stop() {
      clearInterval(this.timer);
    },
    start() {
      this.timer = setInterval(this.startTimer, 1000);
    },
  },
};
</script>
