<template>
  <div>
    <startTimer></startTimer>
  </div>
</template>

<script>
import startTimer from "@/components/startTimer.vue";
export default {
  components: {
    startTimer,
  },
};
</script>

<style lang="less" scoped>
</style>