<template>
  <div id="Avater">
    <!--头像  -->
    <div class="top"><van-icon @click="clickLink" name="arrow-left" /></div>
    <div class="main">
      <div>欢迎来到西知网</div>
      <div>补充个人信息，开始学习之旅吧</div>
      <van-uploader :after-read="afterRead" :max-count="1" :accept="'image/*'">
        <van-button>更换头像</van-button>
        <img :src="fileList[0].url" alt="" />
      </van-uploader>
      <div class="userr">
        <label for="username"
          ><van-icon name="contact" /><span>用户名</span></label
        ><input v-model="username" id="username" />
      </div>
      <div class="btn">
        <van-button type="primary" @click="confirmBtn">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "", //用户名
      tel: "", //手机号
      code: "", //验证码
      fileList: [{ url: require("../assets/images/avatar.png") }],
      imgUrl: "",
    };
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      let _this = this;
      let obj = {
        filetype: "avatar",
      };
      _this.$api.avatarInfo(obj).then((res) => {
        console.log(res.data);
        _this.imgUrl = res.data.filepath;
        let formdata = new FormData();
        formdata.append("OSSAccessKeyId", res.data.access_id);
        formdata.append("policy", res.data.policy);
        formdata.append("signature", res.data.signature);
        formdata.append("key", res.data.filepath);
        formdata.append("file", file.file);
        axios.post(res.data.url, formdata).then((res1) => {
          _this.fileList[0].url = res.data.path;
        });
      });
    },
    //验证
    confirmBtn() {
      //手机号登陆
      let _this = this;
      if (_this.username == "") {
        this.$toast.fail("用户名不能为空");
      } else {
        let obj = {
          avatar: _this.imgUrl,
          username: _this.username,
          name: _this.username,
        };
        _this.$api.editme(obj).then((res) => {
          // 提示信息
          this.$toast.success("登录成功");
          // 1秒跳转
          setTimeout(() => {
            _this.$router.push("/Testsort");
          }, 1000);
        });
      }
    },
    clickLink() {
      let _this = this;
      _this.$router.push("/phoneLogin");
    },
  },
  created() {
    let _this = this;
     _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
    _this.tel = _this.$store.state.username;
    _this.code = _this.$store.state.code;
  },
};
</script>

<style lang="less" scoped>
@bgcolor: #df3833;
#Avater {
  width: 100%;
  padding: 30px 56px;
  .top {
    line-height: 100px;
    font-size: 36px;
  }
  .main {
    text-align: center;
    > div {
      &:nth-child(1) {
        height: 60px;
        font-size: 44px;
        text-align: center;
        color: #333;
        line-height: 60px;
        margin: 0;
      }
      &:nth-child(2) {
        height: 44px;
        font-size: 32px;
        text-align: center;
        color: #999;
        line-height: 44px;
        margin: 12px 0 60px;
      }
    }
    .van-uploader {
      position: relative;
      z-index: 9999;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 50px;
      img {
        width: 160px;
        height: 160px;
      }
      /deep/.van-uploader__input{
          z-index: 9999;
      }
      button {
        position: absolute;
        z-index: 999;
        width: 100%;
        height: 55px;
        font-size: 24px;
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 14px;
        color: #fff;
      }
    }
    .userr {
      display: flex;
      border-radius: 80px;
      width: 640px;
      margin: 40px 0;
      height: 84px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      font-size: 30px;
      padding-left: 36px;
      .van-icon {
        font-size: 40px;
        color: #ccc;
        margin-right: 20px;
      }
      label {
        display: flex;
        align-items: center;
        color: #666;
      }
      input {
        width: 300px;
        border: none;
        padding-left: 20px;
      }
    }
    .btn {
      button {
        font-size: 30px;
        box-sizing: border-box;
        border-radius: 80px;
        width: 640px;
        border-radius: 80px;
        margin-bottom: 40px;
        height: 100px;
        background: @bgcolor;
        border: @bgcolor;
        // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
      }
    }
  }
}
</style>