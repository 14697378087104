<!--  -->
<template>
  <div id="TestInfo">
    <van-overlay class="loadingBool" :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
    <div class="TestInfo_top">
      <img src="@/assets/images/2020082701261706063151.jpg" alt="" />
      <div class="name">{{ listData.name }}</div>
      <div class="TestInfo_top_bottom">
        <div class="imageList">
          <ul>
            <li v-for="(item, index) in listData.records" :key="index">
              <img
                :src="
                  item.user
                    ? item.user.avatar
                    : require('../../assets/images/avatar.png')
                "
                alt=""
              />
            </li>
          </ul>
          <div>
            <div>
              已有{{ listData.records_count + listData.falsedata }}人答题
            </div>
            <div class="studypeple" @click="clickTestsort">
              <i class="iconfont icon-fanhui"></i>返回列表
            </div>
          </div>
        </div>
        <div class="chuanguan">
          <div v-if="listData.pattern != '练习模式'">
            总分：{{ listData.grade_sum }}
          </div>
          <span v-if="listData.pattern != '练习模式'">|</span>
          <div>
            <span>难度：</span>
            <van-rate
              v-model="listData.difficultys"
              :size="14"
              readonly
              allow-half
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </div>
        </div>
        <h4>福利</h4>
        <div class="line"></div>
        <!-- <div class="fenxiang" @click="clickFenxiang"></div> -->
      </div>
    </div>

    <div class="TestInfo_list" v-if="name.indexOf('八') != -1">
      <img src="@/assets/images/zhuanbamsg.png" alt="" />
    </div>
    <div class="warm_prompt">
      <h4>温馨提示</h4>
      <ul>
        <li>试题内容如有错误，欢迎指正，感谢您的支持。我们定努力做到最好！</li>
        <li>
          如果您在使用西知题库过程中有任何问题和建议请与我们联系，请添加微信号反馈：a13811013052
        </li>
        <li>
          希望能为西语人提供最适合的答题库，题库功能将持续优化更新，敬请期待！
        </li>
      </ul>
    </div>
    <div class="return_top" @click="backTop" v-if="btnFlag">
      <van-icon name="back-top" color="#999" />
    </div>
    <div class="TestInfo_content" :class="listData.id == 275?'active':''">
      <div
        v-show="listData.id != 275"
        :style="{
          'margin-left': listData.pattern == '自选模式' ? '0' : '75px',
        }"
        @click="clickResults(1)"
        v-if="listData.pattern == '自选模式' || listData.pattern == '练习模式'"
      >
        练习模式
      </div>
      <p v-show="listData.id != 275" v-if="listData.pattern == '自选模式'"></p>
      <div
        :style="{
          'margin-left': listData.pattern == '自选模式' ? '0' : '75px',
        }"
        @click="clickProblem(2)"
        v-if="listData.pattern == '自选模式' || listData.pattern == '考试模式'"
      >
        考试模式
      </div>
    </div>
    <van-popup class="password" v-model="passwordshow" closeable :style="{ height: '35%' }">
      <div class="passwordtitle">输入密码进入考试</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import wxShare from "@/utils/wxShare.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    //这里存放数据
    return {
      loadingBool: true,
      showShare: false,
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link" },
      ],
      listData: {
        records_count: 0,
        falsedata: 0,
      },
      loading: false,
      finished: false,
      rowId: 0,
      pageList: {
        limit: 5, //一页多少条
        page: 1, //当前第几页
      },
      list: [],
      name: "",
      btnFlag: false,
      titlename: "",
      id: 0, //上一级列表id
      passwordshow: false, //密码框
      password: "", //密码输入框
      passworderror: false, //错误提示信息
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: _this.titlename,
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },
    clickTestsort() {
      // console.log(this.rowId);
      // window.history.go(-1);
      let _this = this;
      _this.$router.push({
        path: "/Index",
        query: {
          id: _this.listData.parent_id,
        },
      });
    },
    //练习模式
    async clickResults(num) {
      //查看成绩
      let _this = this;
      let obj = {
        id: _this.$route.query.id,
        pattern: num,
      };
      await _this.$api.records(obj).then((res) => {
        _this.question = res.data.question_class_record;
        _this.$store.commit("changeCreated", _this.question);
        _this.$store.commit("changeRowObj", _this.rowObj);
        _this.$router.push({
          path: "/Practice",
          query: {
            id: _this.listData.parent_id,
          },
        });
      });
    },
    async onSubmit(values) {
      let _this = this;

      let obj = {
        id: _this.$route.query.id,
        password: _this.password,
      };

      _this.$api.passwordusercheck(obj).then((res) => {
        _this.passwordshow = false;

        let obj = {
          id: _this.$route.query.id,
          pattern: 2,
        };
        _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.rowObj);
          _this.$router.push({
            path: "/Exam",
          });
        });
      });
    },
    //考试模式
    async clickProblem(num) {
      // 开始做题
      let _this = this;
      if (_this.listData.password) {
        _this.$api
          .APIpasswordusercheck({
            question_class_id: _this.$route.query.id,
          })
          .then((res) => {
            if (res.data.question_class_password_user) {
              this.$toast.fail(
                "恭喜您已完成本次专四模拟考试，不可再次答题哦。"
              );
            } else {
              _this.passwordshow = true;
              _this.show = false;
            }
          });
      } else {
        let obj = {
          id: _this.$route.query.id,
          pattern: num,
        };
        await _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.rowObj);
          _this.$router.push({
            path: "/Exam",
          });
        });
      }
    },
    clickSendTestInfo(val) {
      let _this = this;

      _this.$router.push({
        path: "/TestInfo",
        query: {
          id: val,
        },
      });
      location.reload();
    },
    clickFenxiang() {
      let _this = this;
      _this.showShare = true;
    },
    onSelect(option) {
      let _this = this;
      _this.$toast(option.name);
      this.showShare = false;
    },
    async allList() {
      //获取列表
      let _this = this;
      let obj = {
        limit: _this.pageList.limit,
        page: _this.pageList.page,
        "filter[parent_id]": _this.rowId,
        id: _this.$route.query.id,
      };
      await _this.$api.classesindexlist(obj).then((res) => {
        _this.total = res.data.lists.total;
        let item = res.data.lists.data || [];
        if (obj.page == 1) {
          _this.list = [];
        }
        item.forEach((val) => {
          _this.list.push(val);
        });
        if (_this.pageList.limit * _this.pageList.page >= _this.total) {
          _this.finished = true;
        } else {
          _this.finished = false;
        }
        // if(_this.list.length<4){
        //     _this.finished = false;
        // }
        _this.loading = false;
        _this.pageList.page += 1;
      });
    },
    list_onload_more() {
      let _this = this;
      _this.allList();

      // 请求数据
    },
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 50);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
      } else {
        //跳转pc端页面
        console.log("pc端页面");
        window.location.href =
          "http://tk.spanishknow.com/#/Index/TestInfo?id=" +
          this.$route.query.id;
        // window.location.href =
        //   "http://192.168.1.103:4444/#/Index/TestInfo?id=" + this.$route.query.id;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    let _this = this;
    // _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
    _this.$store.commit("changelocationHref", window.location.href);
    _this.rowId = localStorage.rowId;
    this.loadingBool = true;
    let obj = {
      id: _this.$route.query.id,
    };

    await _this.$api.questionclassshow(obj).then((res) => {
      _this.listData = res.data.list;
      _this.loadingBool = false;

      _this.titlename = _this.listData.name + " - 西知题库";
    });

    let msg = {
      id: _this.listData.parent_id,
    };
    _this.$api.classQuestion(msg).then((res) => {
      _this.name = res.data.name;
    });

    _this.id = _this.$route.query.id;
    _this.browserRedirect();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.wechatSharefund();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
#TestInfo {
  width: 100%;
  background: #f8f8f8;
  min-height: 100%;
  position: relative;
  .password{
     width: 560px;
    border-radius: 20px;
    .passwordtitle {
    padding-top: 40px;
    font-size: 38px;
    text-align: center;
    padding-bottom: 60px;
  }
  .error {
    font-size: 12px;
    color: #f74f52;
    text-align: center;
    padding-top: 10px;
  }
  .passwordbtn {
    padding-top: 60px;
    display: flex;
    justify-content: space-around;
  }
  }

  .loadingBool {
    .van-loading {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .TestInfo_top {
    background: #fff;
    width: 100%;

    img {
      width: 100%;
      height: 328px;
      //   border: 1px solid #000;
    }
    .name {
      margin: 0 30px;
      padding: 20px 0 6px;
      letter-spacing: -1px;
      font-size: 44px;
      font-weight: 500;
      word-wrap: break-word;
      line-height: normal;
      color: #2f3742;
      line-height: 60px;
      margin-bottom: 20px;
    }
    .TestInfo_top_bottom {
      margin: 0 32px;
      padding-bottom: 60px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
    }
    .imageList {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      line-height: 20px;
      ul {
        width: 140px;
        height: 50px;
        margin-right: 28px;
        display: flex;
        align-items: center;
        li {
          margin-right: -16px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            vertical-align: middle;
          }
        }
      }
      > div {
        width: 540px;
        font-size: 24px;
        height: 50px;
        color: #95a1af;
        margin-left: 4px;
        line-height: 50px;
        display: flex;
        align-items: center;
        // padding-top: 20px;
        justify-content: space-between;
        /deep/.studypeple {
          color: #95a1af;
          font-size: 24px;
          /deep/ i {
            font-size: 28px !important;
          }
        }
      }
    }
    .chuanguan {
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-top: 8px;
      color: #2f3742;
      // width: 155 * 2px;
      height: 16 * 2px;
      > span {
        margin: 0 10px;
      }
      // background: url(../../assets/images/ff4705b6afdfaf961f405b006186a1f9.png)
      //   no-repeat;
      // background-size: contain;
      // background-position: 0 100%;
      div {
        display: flex;
        align-items: center;
      }
    }
    h4 {
      font-size: 44px;
      font-weight: bold;
      position: absolute;
      top: 140px;
    }
    .line {
      margin-top: 90px;

      width: 90px;
      height: 8px;
      background: #9cb0f9;
    }
    .fenxiang {
      width: 70px;
      height: 70px;
      background: url(../../assets/images/fenxiang.svg) no-repeat;
      background-size: contain;
    }
  }
  .TestInfo_content {
    background: #fff;
    box-shadow: 0px 0px 10px #cfcfcf;
    width: 90%;
&.active{
  justify-content: center;
}
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding: 20px 80px;
    bottom: 20px;
    left: 5%;
    border-radius: 10px;
    div {
      // width: 50%;
      // height: 100px;
      // line-height: 100px;
      text-align: center;
      font-size: 34px;
      font-weight: 400;
      color: #fff;
      width: 200px;
      background: #526ecc;
      padding: 10px;
      border-radius: 40px;
      font-size: 28px;
      // &:nth-child(1) {
      //   padding-right: 250px;
      //   border-right: 1px solid #95a1af;
      // }
    }
    p {
      width: 2px;
      height: 44px;
      background: #2f5aff;
      margin-top: 15px;
    }
  }
  .TestInfo_list {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .warm_prompt {
    margin-top: 48px;
    padding: 16px 16px 103px;
    background: #f6f7f9;
    padding-left: 24px;
    h4 {
      margin-bottom: 7px;
      font-size: 34px;
      line-height: 1.4;
      color: #2f3742;
      font-weight: 600;
    }
    ul {
      li {
        color: #2f3742;
        line-height: 52px;
        font-size: 28px;
        font-weight: 300;
        word-break: break-all;
        word-wrap: break-word;
        padding-left: 20px;
        &::before {
          position: absolute;
          left: 0;
          top: 20px;
          content: "";
          display: inline-block;
          background: #687583;
          width: 10px;
          height: 10px;
          border-radius: 100%;
        }
      }
    }
  }
  .return_top {
    position: fixed;
    width: 90px;
    height: 90px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 50%;

    padding: 4px 0 15px 15px;
    bottom: 150px;
    right: 20px;
    .van-icon {
      font-size: 58px;
    }
  }
}
</style>