<template>
  <div id="index">
    <van-overlay class="loadingBool" :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
    <van-overlay :show="topShow" @click="topShow = false">
      <div class="top-wrapper" @click.stop>
        <div class="top-block">
          <i @click="renturnicon"><van-icon name="cross" color="#A7A7A7" /></i>
          <p>大家体验过程中如有任何疑问和功能建议，欢迎在群内向我们反馈。</p>
        </div>
      </div>
    </van-overlay>
    <div
      class="topvideo"
      v-if="objTitle.name.indexOf('八') != -1"
      v-show="shows"
    >
      <video
        v-if="objTitle.name.indexOf('八') != -1"
        :src="objTitle.questionClass.file.playpath"
        controls="controls"
        preload="auto"
        poster=""
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        :show-center-play-btn="false"
        x5-video-player-type="h5"
      ></video>
      <div class="bgsuo" v-if="videoBool">
        <div class="button" @click="clickpaly"></div>
      </div>
    </div>
    <div
      class="top_bg"
      :style="{
        top: objTitle.name.indexOf('八') != -1 ? '-20px' : 0,
      }"
    >
      <div class="top">
        <p>{{ objTitle.name }}</p>
      </div>
      <div class="shows">
        <div class="showsclass">
          <p>永久免费</p>
          <p>模拟考试</p>
          <p>高效学习</p>
        </div>
      </div>
      <div class="fuwu">
        <div>
          <p>
            包含试卷<span>({{ objTitle.count }})</span>
          </p>
          <div class="studypeple" @click="clickTestsort">
            <i class="iconfont icon-fanhui"></i>返回分类
          </div>
        </div>
        <div>
          <p>题库功能/试卷内容持续更新中</p>
          <p @click="content"><img src="@/assets/images/index.png" alt="" /></p>
        </div>
      </div>
      <div
        class="top_bottom"
        v-if="objTitle.name == '西班牙语专业八级历年真题'"
      >
        <p>专八考试倒计时</p>
        <div>
          <div class="time">
            <p>仅剩</p>
            <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒">
              <template #default="timeData">
                <span class="block">{{ timeData.days }}</span>
                <span class="colon">天:</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">时:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">分:</span>
                <span class="block">{{ timeData.seconds }}</span>
                <span class="colon">秒</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
      <div
        class="zhuansi_title"
        v-else-if="objTitle.name == '西班牙语专业四级历年真题'"
      ></div>
      <div class="top_bottom" v-if="objTitle.name == '西知专八训练营'">
        <p>专八考试倒计时</p>
        <div>
          <div class="time">
            <p>仅剩</p>
            <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒">
              <template #default="timeData">
                <span class="block">{{ timeData.days }}</span>
                <span class="colon">天:</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">时:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">分:</span>
                <span class="block">{{ timeData.seconds }}</span>
                <span class="colon">秒</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content"
      :style="{
        top: objTitle.name.indexOf('八') != -1 ? '-10px' : '10px',
      }"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="list_onload_more"
      >
        <div v-for="(v, i) in list" :key="i">
          <h4 @click="clickSendTestInfo(v.id)">{{ v.name }}</h4>
          <p class="grade">
            <span v-if="v.pattern == '自选模式' || v.pattern == '考试模式'"
              >总分：{{ v.grade_sum }}</span
            >
            <span v-if="v.pattern == '自选模式' || v.pattern == '考试模式'"
              >|</span
            >
            <span>难度：</span>
            <van-rate
              v-model="v.difficultys"
              :size="14"
              readonly
              allow-half
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </p>
          <div class="score">
            <div>
              <!-- <span
              >本卷共<i>{{ v.questions_count }}</i
              >题</span
            > -->
              <span
                >已有<i>{{ v.records_count + v.falsedata }}</i
                >人答题</span
              >
            </div>
            <div></div>
            <van-button @click="showPopup(i, v)">去做题</van-button>
          </div>
        </div>
      </van-list>
    </div>
    <div
      class="cebanner"
      @click="cebannerBool = true"
      v-if="objTitle.name != '西班牙语专业四级历年真题'"
      v-show="shows"
    >
      <p>一起备考专八</p>
    </div>
    <van-popup v-model="show" closeable>
      <div class="title-bg"></div>
      <div class="title">{{ rowObj.name }}</div>
      <div class="grade">
        <div
          v-if="rowObj.pattern == '自选模式' || rowObj.pattern == '考试模式'"
        >
          总分：{{ rowObj.grade_sum }}
        </div>
        <div>本卷共：{{ rowObj.questions_count }}大题</div>

        <div>
          难度：<van-rate
            v-model="rowObj.difficultys"
            readonly
            allow-half
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
            size="14px"
          />
        </div>
      </div>
      <div class="btn">
        <div
          class="btn_chakan"
          :style="{
            width:
              rowObj.pattern != '自选模式' || rowObj.pattern != '考试模式'
                ? '100%'
                : '50%',
            'border-right':
              rowObj.pattern != '自选模式' ? 'none' : '1px solid #eaeaea',
          }"
          @click="clickResults(1)"
          v-if="rowObj.pattern == '自选模式' || rowObj.pattern == '练习模式'"
          v-show="rowObj.id != 275"
        >
          练习模式
        </div>
        <div
          :style="{
            width:
              rowObj.pattern != '自选模式' || rowObj.pattern != '练习模式'
                ? '100%'
                : '50%',
          }"
          v-if="rowObj.pattern == '自选模式' || rowObj.pattern == '考试模式'"
          @click="clickProblem(2)"
        >
          考试模式
        </div>
      </div>
    </van-popup>

    <van-popup v-model="passwordshow" closeable :style="{ height: '35%' }">
      <div class="passwordtitle">输入密码进入考试</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <van-overlay class="cebannerBool" :show="cebannerBool">
      <div class="wrapper" @click.stop>
        <div class="block">
          <img src="../assets/images/erweima.png" alt="" />
          <div class="del" @click="cebannerBool = false"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import wxShare from "@/utils/wxShare.js";
import { getCookie, setCookie } from "@/utils/commit.js";
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      cebannerBool: false,
      show: false, //模态框
      passwordshow: false, //密码框
      password: "", //密码输入框
      passworderror: false, //错误提示信息
      rowpassword: {},
      objTitle: {
        name: "",
      }, //试卷分类
      list: [],
      rowObj: {}, //当前行数据
      pageList: {
        limit: 5, //一页多少条
        page: 1, //当前第几页
      },
      question: {},
      id: 0,
      loadingBool: true,
      showParts: false,
      totalPage: "",
      qualityData: [],
      showXl: false,
      loading: false,
      finished: false,
      total: 1,
      titlename: "",
      time: 0,
      shows: false,
      topShow: false, //题库更新弹框
      imgUrl: "",
      videoBool: true,
    };
  },
  mounted() {
    this.wechatSharefund();
  },
  methods: {
    clickpaly() {
      let _this = this;
      let video = document.getElementsByTagName("video");
      video[0].play();
      _this.videoBool = false;
    },
    clickSendTestInfo(val) {
      let _this = this;
      _this.$router.push({
        path: "/TestInfo",
        query: {
          id: val,
        },
      });
    },
    touchstart(e) {
      // 按下
      e.preventDefault();
    },
    //题库感叹号弹框
    content() {
      this.topShow = true;
    },
    renturnicon() {
      this.topShow = false;
    },
    clickTestsort() {
      let _this = this;
      _this.$router.push("/Testsort");
    },
    async showPopup(index, val) {
      //去做题
      let _this = this;
      if (localStorage.userInfo) {
        _this.rowObj = _this.list[index];
        _this.rowpassword = val;
        localStorage.name = _this.rowObj.name;
        _this.show = true;
      } else {
        _this.$toast.fail("请登录");
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          setTimeout(() => {
            _this.$router.push("/wxMMLogin");
          }, 1000);
        } else {
          setTimeout(() => {
            _this.$router.push("/");
          }, 1000);
        }
      }
    },

    async allList() {
      //获取列表
      let _this = this;
      let obj = {
        limit: _this.pageList.limit,
        page: _this.pageList.page,
        "filter[parent_id]": _this.id,
      };
      await _this.$api.classesindexlist(obj).then((res) => {
        _this.total = res.data.lists.total;
        let item = res.data.lists.data || [];
        if (obj.page == 1) {
          _this.list = [];
        }
        item.forEach((val) => {
          _this.list.push(val);
        });
        if (_this.pageList.limit * _this.pageList.page >= _this.total) {
          _this.finished = true;
        } else {
          _this.finished = false;
        }
        // if(_this.list.length<4){
        //     _this.finished = false;
        // }
        _this.loading = false;
        _this.pageList.page += 1;
      });
    },
    list_onload_more() {
      let _this = this;
      _this.allList();

      // 请求数据
    },
    //练习模式
    async clickResults(num) {
      //查看成绩
      let _this = this;
      let obj = {
        id: _this.rowObj.id,
        pattern: num,
      };
      await _this.$api.records(obj).then((res) => {
        _this.question = res.data.question_class_record;
        _this.$store.commit("changeCreated", _this.question);
        _this.$store.commit("changeRowObj", _this.rowObj);
        _this.$router.push({
          path: "/Practice",
          query: {
            id: _this.$route.query.id,
          },
        });
      });
    },
    //考试模式
    async clickProblem(num) {
      // 开始做题
      let _this = this;
      if (_this.rowpassword.password) {
        _this.$api
          .APIpasswordusercheck({
            question_class_id: _this.rowpassword.id,
          })
          .then((res) => {
            if (res.data.question_class_password_user) {
              this.$toast.fail("恭喜您已完成本次专四模拟考试，不可再次答题哦。");
            } else {
              _this.passwordshow = true;
              _this.show = false;
            }
          });
      } else {
        let obj = {
          id: _this.rowObj.id,
          pattern: num,
        };
        await _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.rowObj);
          _this.$router.push({
            path: "/Exam",
          });
        });
      }
    },
    async onSubmit(values) {
      let _this = this;

      let obj = {
        id: _this.rowpassword.id,
        password: _this.password,
      };

      _this.$api.passwordusercheck(obj).then((res) => {
        _this.passwordshow = false;

        let obj = {
          id: _this.rowpassword.id,
          pattern: 2,
        };
        _this.$api.records(obj).then((res) => {
          _this.question = res.data.question_class_record;
          _this.$store.commit("changeCreated", _this.question);
          _this.$store.commit("changeRowObj", _this.rowpassword);
          _this.$router.push({
            path: "/Exam",
          });
        });
      });
    },
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: _this.titlename,
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
      } else {
        //跳转pc端页面
        console.log("pc端页面");
        window.location.href = "http://tk.spanishknow.com/#/Index/Home";
        // window.location.href =
        //   "http://192.168.1.103:4444/#/Index/Home";
      }
    },
  },
  created() {
    let _this = this;
    // _this.$store.commit("changelocationHref", window.location.href.substring(1, window.location.hash.length));
    _this.$store.commit("changelocationHref", window.location.href);
    this.loadingBool = true;
    // _this.id = localStorage.rowId;
    _this.id = _this.$route.query.id;
    localStorage.rowId = _this.$route.query.id;
    let obj = {
      id: _this.id,
    };
    _this.$api.classQuestion(obj).then((res) => {
      _this.objTitle = res.data;
      localStorage.name = res.data.name;
      _this.shows = true;

      this.titlename = this.objTitle.name + " - 西知题库";
      _this.loadingBool = false;
    });
    _this.$api.getcountdown().then((res) => {
      let time = res.data.datatp - res.data.nowtp;
      _this.time = time;
    });
    // _this.browserRedirect();
  },
};
</script>
<style lang="less" scoped>
#index {
  width: 100%;
  background: #f8f8f8;
  min-height: 100%;

  .passwordtitle {
    padding-top: 40px;
    font-size: 38px;
    text-align: center;
    padding-bottom: 60px;
  }
  .error {
    font-size: 12px;
    color: #f74f52;
    text-align: center;
    padding-top: 10px;
  }
  .passwordbtn {
    padding-top: 60px;
    display: flex;
    justify-content: space-around;
  }
  .van-overlay {
    z-index: 300;
  }
  .loadingBool {
    .van-loading {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .top-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .top-block {
    width: 90%;

    background-color: #fff;
    border-radius: 20px;
    padding: 20px 10px 50px;
    i {
      margin-left: 300px;
      font-size: 38px;
    }
    p {
      font-size: 30px;
      text-align: center;
      // line-height: 120px;
    }
  }
  .topvideo {
    width: 100%;
    position: relative;
    video {
      width: 100%;
    }
    .bgsuo {
      width: 100%;
      height: 410px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background: url(../assets/images/shipingsuoluotu.png);
      background-size: 100% 100%;
      .button {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        // line-height: 70px;
        background: url(../assets/images/bofang.png) no-repeat center;
        background-size: 100% 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding-left: 15px;
        padding-bottom: -30px;
        // // padding-top: -20px;
        // i {
        //   // width: 100px;
        //   // height: 100px;
        //   font-size: 35px;
        //   margin-bottom: 90px;
        // }
      }
    }
  }
  .top_bg {
    position: relative;
    z-index: 10;
    width: 100%;
    // height: 360px;
    // background: url("../assets/images/detail-bg.png") no-repeat;
    // background-size: 100% 340px;
    padding: 40px 0;
    padding-bottom: 0;
    background: #fff;
    border-radius: 15px 15px 0px 0;
    top: -20px;
    .top {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 50px;

      .iconfont {
        font-size: 32px;
        margin-right: 5px;
      }
      div {
        color: #000;
        width: 200px;
        font-size: 30px;
      }
      p {
        color: #000;
        font-size: 48px;
        font-weight: 700;
        height: 60px;
        width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .shows {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding: 0 20px;
      .showsclass {
        display: flex;
        p {
          color: #95a1af;
          font-size: 24px;
          // width: 110px;
          line-height: 32px;
          font-size: 24px;
          color: #2f5aff;
          background: #eaeefe;
          padding: 0 6px;
          margin-right: 20px;
        }
      }
    }
    /deep/.studypeple {
      color: #95a1af;
      font-size: 24px;
      /deep/ i {
        font-size: 28px !important;
      }
    }
    .fuwu {
      padding: 0 20px;
      font-size: 24px;
      color: #95a1af;
      img {
        width: 40%;
        margin-top: 5px;
        margin-left: 5px;
      }
      p {
        &:first-child {
          margin: 5px 0;
          span {
            margin-left: 10px;
          }
        }
      }
      > div {
        display: flex;
        align-items: center;
        &:nth-child(1) {
          justify-content: space-between;
        }
      }
    }
    .zhuansi_title {
      width: 100%;
      height: 180px;
      background: url("../assets/images/zhuansi.jpg");
      background-size: 100% 100%;
      margin-top: 20px;
    }
    .top_bottom {
      height: 100px;
      line-height: 100px;
      background: linear-gradient(225deg, #ff3271, #ff4ba6);
      box-shadow: 0 2px 4px 0 rgba(255, 50, 113, 0.3);
      padding: 0 20px;
      display: block;
      // margin: 15px -16px 0;
      margin-top: 15px;
      display: -webkit-box;
      display: flex;
      align-items: center;
      -webkit-box-pack: justify;
      justify-content: space-between;

      color: #fff;
      p {
        font-size: 40px;
        margin-bottom: 0px;
        padding-top: 10px;
        del {
          font-size: 26px;
        }
      }
      > div {
        .time {
          display: flex;
          align-items: center;
          p {
            font-size: 20px;
          }
          .van-count-down {
            font-size: 16px;
            margin-left: 10px;
            padding-top: 10px;
          }
          /deep/ .colon {
            display: inline-block;
            margin: 0 4px;
            color: #b2285b;
          }
          /deep/ .block {
            display: inline-block;
            width: 40px;
            color: #fff;
            line-height: 40px;
            font-size: 28px;
            text-align: center;
            background-color: #b2285b;
            // background:;

            // border-radius: 1px;
            // text-align: center;
            // min-width: 16px;
            // color: #fff;
          }
        }
      }
    }
  }
  /deep/ .cebannerBool {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }

    .block {
      width: 580px;
      height: 760px;
      // background: url(../assets/images/erweima.png) no-repeat 0 0;
      // background-size: 100% 100%;
    }
    .del {
      width: 60px;
      height: 92px;
      position: absolute;
      top: -92px;
      right: 0;
      z-index: 9999;
      background: url(../assets/images/del.png) no-repeat 0 0;
      background-size: 100% 100%;
    }
  }
  .content {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 20px;
    position: relative;
    top: -60px;
    padding-bottom: 60px;
    /deep/.van-loading__text {
      // top: 100px;
      display: none;
    }
    /deep/.van-loading {
      display: none;
    }
    // background: #f8f8f8;
    > div {
      > div {
        width: 100%;
        border-radius: 20px;
        background: #fff;
        padding: 20px;
        margin-bottom: 30px;
        :last-child {
          margin-bottom: 0;
        }
        h4 {
          font-size: 32px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
        }
        .grade {
          font-size: 26px;
          color: #999;
          display: flex;
          align-items: center;
          line-height: 50px;
          span {
            &:nth-child(2) {
              padding: 0 20px;
            }
          }
        }
        .score {
          line-height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div {
            &:nth-child(1) {
              span {
                font-size: 26px;
                color: #999;
              }
              i {
                color: #999;
                font-size: 26px;
                margin: 0 20px;
                font-weight: 400;
              }
            }
          }
          button {
            background: #526ecc;
            border: 1px solid #526ecc;
            color: #fff;
            height: 60px;
            width: 150px;
            padding: 0 10px !important;
            font-size: 26px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .cebanner {
    width: 82px;
    height: 240px;
    position: fixed;
    font-size: 12px;
    p {
      width: 40px;
      padding-left: 32px;
      padding-top: 22px;
      font-size: 15px;
      color: #fff;
      font-weight: 500;
    }
    top: 900px;
    right: 20px;
    background: url("../assets/images/cebanner.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
  .van-popup {
    width: 560px;
    border-radius: 20px;

    .title-bg {
      // padding: 0 75px;
      width: 460px;
      height: 380px;
      background: url("../assets/images/model-bg.png") no-repeat 75px 100px;
      background-size: 400px;
    }
    .title {
      padding: 0 75px;
      font-size: 36px;
      font-weight: 700;
      text-align: center;
    }
    .grade {
      // padding: 20px 155px;
      text-align: center;
      font-size: 20px;
      // display: flex;
      // justify-content: space-between;
      color: #999;
      // display: flex;
      // align-items: center;
      line-height: 50px;
      // margin-bottom: 20px;
    }
    .btn {
      border-top: 1px solid #eaeaea;
      display: flex;
      justify-content: space-between;
      div {
        width: 50%;
        text-align: center;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
        color: #f74f52;
        &.btn_chakan {
          border-right: 1px solid #eaeaea;
        }
      }
    }
  }
  .bottom {
    // height: 280px;
    padding-bottom: 20px;
    color: #ccc;
    text-align: center;
    font-size: 20px;
    position: relative;
    bottom: 50px;
  }
}
</style>
