<template>
  <div id="Result">
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
    <div class="top">
      <!-- top -->
      <div>
        <p>本次得分</p>
        <p>{{ scoreStr }}</p>
        <p>{{ title }}</p>
        <p>{{ date }}</p>
      </div>
    </div>
    <!-- main -->
    <div class="main">
      <h6>本次测验总结</h6>
      <p>考试时长 {{ testTime }}(分钟)</p>
      <div class="subjectNum" v-for="item in numlist" :key="item.id">
        <p>{{ item.title }}</p>

        <div :style="{ width: item.width }">
          <span>{{ item.num }}</span>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="footer">
      <div @click="returnBtn">
        <i class="iconfont icon-fanhui"></i>
        <p>返回</p>
      </div>

      <div @click="showBtn">查看解析</div>
      <div @click="clickExamination">重新考试</div>
    </div>
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      question: {}, //当前行消息
      score: "", //得分
      title: "", //标题
      testTime: "", //考试时间
      date: "",
      numlist: [
        { title: "答对题数", num: "0" },
        { title: "答错题数", num: "0" },
        { title: "未答题数", num: "0" },
      ],
      titlename: "",
      rowid: 0,
      scoreStr:""
    };
  },
  created() {
    let _this = this;
    this.loadingBool = true;
    _this.question = _this.$store.state.question;
    _this.titlename = _this.question.name + " - 西知题库";
    _this.$api.recordtiemslist(_this.question).then((res) => {
      _this.rowid = res.data.list.question_class.parent_id;
      this.date = res.data.list.created_at;
      this.title = res.data.list.name;
      this.testTime = res.data.list.durations;
      _this.numlist[0].num = res.data.countyes;

      _this.numlist[0].width = (res.data.countyes / res.data.count) * 100 + "%";
      _this.numlist[1].num = res.data.countno;
      _this.numlist[1].width = (res.data.countno / res.data.count) * 100 + "%";
      _this.numlist[2].num = res.data.countnull;
      _this.numlist[2].width =
        (res.data.countnull / res.data.count) * 100 + "%";

      _this.score = res.data.list.grade;
      if (58.82>_this.score&&_this.score>=45.22) {
         _this.scoreStr="通过"
      }else if(60.42>_this.score&&_this.score>=52.82) {
         _this.scoreStr="良好"

      }else if(_this.score>=60.42){
         _this.scoreStr="优秀"
      }else{
         _this.scoreStr="不通过"

      }
      this.loadingBool = false;
    });
    // console.log(this.question);
  },
  methods: {
    // 查看解析
    showBtn() {
      let _this = this;
      _this.$router.push({
        path: "./Analysis",
      });
    },
    returnBtn() {
      let _this = this;
      _this.$router.push(`/Index?id=${_this.rowid}`);
    },
    clickExamination() {
      // 重新考试
      let _this = this;

      if (_this.$store.state.rowObj.password) {
        _this.$api
          .APIpasswordusercheck({
            question_class_id: _this.$store.state.rowObj.id,
          })
          .then((res) => {
            if (res.data.question_class_password_user) {
              this.$toast.fail("恭喜您已完成本次专四模拟考试，不可再次答题哦。");
            } else {
              _this.passwordshow = true;
              _this.show = false;
            }
          });
      } else {
        let obj = {
          id: _this.$store.state.rowObj.id,
        };
        _this.$api.records(obj).then((res) => {
          _this.$store.commit("changeCreated", res.data.question_class_record);
          _this.$router.push("../Exam");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
//less

#Result {
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  width: 100%;
  background: #f8f8f8;
  padding-bottom: 600px;
  min-height: 100%;
  .top {
    width: 100%;
    height: 840px;
    background: #f87c7e;
    div {
      // padding-right: 50px;
      width: 100%;
      height: 100%;
      background: url(../../assets/images/score-result.png) no-repeat;
      // background-position: 0px 0px;
      background-size: 100% 840px;
      position: relative;
      padding-top: 120px;
      p {
        font-size: 24px;
        color: #fff;
        text-align: center;
        &:first-child {
          width: 100%;
          text-align: center;
        }
        &:nth-child(2) {
          width: 100%;
          text-align: center;
          padding-top: 20px;
          font-size: 80px;
          font-weight: bold;
        }
        &:nth-child(3) {
          width: 100%;
          text-align: center;
          padding-top: 200px;
          font-size: 36px;
          font-weight: bold;
        }
        &:last-child {
          margin-top: 40px;
        }
      }
    }
  }
  .main {
    width: 680px;
    height: 704px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 630px;
    left: 35px;
    padding: 40px 30px;
    h6 {
      font-size: 36px;
      font-weight: bold;
    }
    p {
      font-size: 28px;
      color: rgb(129, 129, 129);
      margin: 10px 0 40px 0;
    }
    div {
      font-size: 36px;
    }
    .subjectNum {
      p {
        margin: 20px 0;
      }
      &:nth-child(3) {
        div {
          height: 80px;
          background: #d7ecfb;
          text-indent: 20px;
          line-height: 80px;
        }
      }
      &:nth-child(4) {
        div {
          height: 80px;
          background: rgb(255, 235, 231);
          text-indent: 20px;
          line-height: 80px;
        }
      }
      &:nth-child(5) {
        div {
          height: 80px;
          background: #f0f0f0;
          margin-right: 20px;
          text-indent: 20px;
          line-height: 80px;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0px;
    display: flex;
    width: 100%;
    div {
      text-align: center;
      font-size: 36px;
      &:first-child {
        flex: 1;
        background: #fff;
        color: #666;
        i {
          font-size: 48px;
        }
        p {
          font-size: 24px;
        }
      }
      &:nth-child(2) {
        flex: 2;
        background: #fd7174;
        border: #fd7174;
        line-height: 90px;
        color: #fff;
      }
      &:last-child {
        flex: 3;
        background: #f74f52;
        border: 1px solid #f74f52;
        line-height: 90px;
        color: #fff;
      }
    }
    button {
      &:first-child {
        width: 152px;
        i {
          font-size: 48px;
        }
      }
      &:nth-child(2) {
        width: 244px;

        color: #fff;
        font-size: 36px;
      }
      &:nth-child(3) {
        width: 354px;
        color: #fff;
        font-size: 36px;
      }
    }
  }
}
</style>