<template>
  <div id="PersonalCenter">
    <div class="top">
      <div class="returndown" @click="returndown">
        <i class="iconfont icon-fanhui"></i>返回上一级
      </div>
      <img
        :src="
          meObj.avatar
            ? meObj.avatar
            : require('../../assets/images/avatar.png')
        "
        alt=""
        @click="clickPersonalCenter"
      />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import bus from "@/utils/bus";

export default {
  data() {
    return {
      meObj: {},
    };
  },
  methods: {
    clickPersonalCenter() {
      // 跳转到个人中心
      let _this = this;
      console.log();
      _this.$router.push("/PersonalCenter");
    },
    returndown() {
      // bus.$emit("routerPush");
      let _this=this
        _this.$router.push( localStorage.routerPush);
    },
  },
  created() {
    let _this = this;
    _this.$api.me().then((res) => {
      _this.meObj = res.data;
    });
    console.log(window.location.hash);
  },
};
</script>

<style lang="less" scoped>
#PersonalCenter {
  background: #fcfcfc;
  width: 100%;
  min-height: 100%;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  .top {
    padding: 0 30px;
    height: 96px;
    width: 100%;
    z-index: 10;
    background: #fff;
    border-bottom: 1px solid #dfe1e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    .returndown {
      font-size: 28px;
      i {
        font-size: 28px;
        margin-right: 5px;
      }
    }
    img {
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
  }
}
</style>