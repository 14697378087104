<template>
  <!-- 配音秀 -->
  <div id="DubbingShowDetails">
    <div class="vanicon">
      <van-icon
        @click="returnup"
        name="arrow-left"
        color="#fff"
        v-show="isShowIcon"
      ></van-icon>
    </div>
    <!-- 配音秀详情 -->

    <div class="top">
      <!-- <div id="vs"></div> -->
      <video
        id="vds"
        src="http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4"
        controls="controls"
        preload="auto"
        poster="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg&refer=http%3A%2F%2Fa4.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616305492&t=e9cfbd584f7393baf923892a070c9c2f"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsinline="true"
        :show-center-play-btn="false"
        x5-video-player-type="h5"
        @touchstart="touchstartVideo"
        @pause="pauseVideo"
        @play="playVideo"
      ></video>
    </div>
    <div class="introduction">
      <div>
        <p>配音频道：大鱼海棠</p>
        <p>></p>
      </div>
      <div>
        <div>媒婆为木兰说媒</div>
      </div>
      <div>
        媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒媒婆为木兰说媒
      </div>
      <div>
        <p>已有999阅读本文</p>
        <p>查看原文></p>
      </div>
    </div>
    <div class="headPortrait">
      <div class="avacter">
        <div class="avcaterleft">
          <img
            src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa4.att.hudong.com%2F27%2F67%2F01300000921826141299672233506.jpg&refer=http%3A%2F%2Fa4.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616305492&t=e9cfbd584f7393baf923892a070c9c2f"
            alt=""
          />
          <div>
            <p>精神小伙</p>
            <p>2021-02-22 <span>0次播放</span></p>
          </div>
        </div>
        <div class="icon">
          <div>0</div>
          <i class="iconfont icon-dianzan"></i>
        </div>
      </div>
      <div class="TAworks">
        <div>
          <div>TA的作品</div>
          <div>更多</div>
        </div>
        <div class="worksshow">
          <div v-for="(item, index) in 2" :key="index">
            <img
              src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2612816471,1892359600&fm=26&gp=0.jpg"
              alt=""
            />
            <p>Hello Kikty</p>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <van-button type="danger" @click="clickSend">我要配音</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowIcon: true, //左上角图标显示
      isBofang: false, //视频默认不播放
    };
  },
  methods: {
    clickSend() {
      let _this = this;
      _this.$router.push("/DubbingShowDetailsInfo");
    },
    returnup() {
      this.$router.push("/DubbingShowDetails");
    },
    touchstartVideo() {
      // 点击视频
      let _this = this;
      if (!_this.isBofang) {
        _this.isShowIcon = true;
      } else {
        setTimeout(() => {
          _this.isShowIcon = true;
        }, 400);
        setTimeout(() => {
          _this.isShowIcon = false;
        }, 3000);
      }
    },
    pauseVideo() {
      //视频暂停
      let _this = this;
      _this.isBofang = false;
      _this.isShowIcon = true;
    },
    playVideo() {
      //视频开始
      let _this = this;
      _this.isBofang = true;
      setTimeout(() => {
        _this.isShowIcon = false;
      }, 500);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#DubbingShowDetails {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #f5f5f5;
  .vanicon {
    position: fixed;
    z-index: 1000;
    top: 0px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    line-height: 80px;
    .van-icon {
      font-size: 50px;
      margin-top: 20px;
    }
  }
  //播放按钮

  video {
    width: 100%;
  }
  .top {
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
  }
  .introduction {
    margin-top: 440px;
    padding: 20px;
    background: #fff;
    border-bottom: 1px solid #e3e8f1;
    margin-bottom: 20px;
    > div {
      font-size: 30px;
      &:nth-child(1) {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        color: #7d7d7d;
      }
      &:nth-child(2) {
        margin: 5px 0 15px 0;
        display: flex;
        justify-content: space-between;
        div {
          &:nth-child(2) {
            font-size: 28px;
            color: #7d7d7d;
            .icon-fanhui {
              font-size: 28px;
            }
          }
        }
      }
      &:nth-child(3) {
        color: #7d7d7d;
        margin-bottom: 10px;
      }
      &:nth-child(4) {
        display: flex;
        justify-content: space-between;
        color: #7d7d7d;
        font-size: 28px;
      }
    }
  }

  .headPortrait {
    background: #fff;
    border-top: 1px solid #e3e8f1;

    margin-bottom: 20px;

    .avacter {
      padding: 20px 20px 20px 30px;
      font-size: 30px;
      // padding-bottom: ;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .avcaterleft {
        display: flex;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 20px;
        }
        > div {
          color: rgb(105, 105, 105);
          p {
            &:last-child {
              margin-top: 20px;
              span {
                margin-left: 20px;
              }
            }
          }
        }
      }
      .icon {
        display: flex;
        font-size: 32px;
        .iconfont {
          font-size: 28px;
          margin-left: 10px;
        }
      }
    }
    .TAworks {
      padding: 20px;
      > div {
        &:first-child {
          display: flex;
          justify-content: space-between;
          font-size: 28px;
          > div {
            &:last-child {
              color: #999;
              font-size: 28px;
            }
          }
        }
      }
      .worksshow {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        > div {
          width: 330px;
          height: 300px;

          img {
            width: 100%;
            height: 200px;
            border-radius: 10px;
          }
          p {
            font-size: 28px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 0;
    button {
      width: 100%;
      background: #df3833;
    }
  }
}
</style>