<template>
  <div id="imgInfo">
    <div class="blur_bj">
      <img src="../../../assets/images/flower.png" />
    </div>
    <div class="blur_bj_gray"></div>
    <van-icon @click="returnup" name="arrow-left" color="#fff"></van-icon>
    <div class="introuduce">
      <img src="../../../assets/images/flower.png" />
      <div>
        <h6>花木兰</h6>
        <div>《花木兰》</div>
      </div>
    </div>
    <div class="fragment">
      <div class="fragment-list">
        <div v-for="(item, index) in 8" :key="index">
          <img src="../../../assets/images/flower2.png" alt="" />
          <div>关于花木兰的传说</div>
          <div class="Advanced">进阶修炼</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    returnup() {
      this.$router.push("/DubbingShow");
    },
  },
};
</script>

<style lang="less" scoped>
#imgInfo {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  .blur_bj {
    position: absolute;
    height: 100%;
    width: 680px;
    top: 0;
    z-index: -1;
    -webkit-filter: blur(5px);
    filter: blur(15px);
    transform: scale(1.2);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .blur_bj_gray {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: -1;
    opacity: 0.5;
  }
  .introuduce {
    display: flex;
    padding: 30px 30px 80px 30px;
    // position: fixed;
    // z-index: 100;
    img {
      width: 200px;
      height: 280px;
    }
    > div {
      padding-left: 20px;
      color: #fff;

      > div {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
  .fragment {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 30px;

    .fragment-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 320px;

        padding-bottom: 40px;
        img {
          width: 100%;
          height: 200px;
          border-radius: 5px;
          position: relative;
        }
        > div {
          font-size: 28px;
        }
        .Advanced {
          width: 120px;
          text-align: center;
          background: crimson;
          padding:5px 0;
          border-radius: 5px;
          position: absolute;
          bottom: 100px;
          right: 10px;
          color: #fff;
          font-size: 26px;
        }
      }
    }
  }
}
</style>