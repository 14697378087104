<template>
  <div id="Practice">
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >

    <div class="title">
      <div>
        <div>
          <i class="iconfont icon-wenjian"></i>
          <span v-if="allList.length > 0"
            >{{ defaultIndex + 1 }}/{{ total }}</span
          >
          <span v-else>0</span>
        </div>
        <!-- <div @click="practiceSave">结束练习</div> -->
        <div v-if="false">
          <i class="iconfont icon-zhengque"></i><span>0</span
          ><i class="iconfont icon-cuowu"></i><span>0</span>
        </div>
        <div @click="clickshowTop">
          <i class="iconfont icon-fanhui"></i><span>结束练习</span>
        </div>
      </div>
      <p :style="{ width: widthActive + '%' }"></p>
    </div>
    <van-popup
      class="discussModel"
      v-model="discussShow"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="discussModelTop">
        <div @click="discussShow = false">关闭</div>
        <div>{{ discussObj.total }}条评论</div>
        <div @click="discussShow1 = true" class="xie">写评论</div>
      </div>
      <div class="discuss">
        <ul class="discuss_list" v-if="discussObj.total > 0">
          <li v-for="(item, index) in discussObj.data" :key="index">
            <div>
              <img v-if="item.user.avatar" :src="item.user.avatar" alt="" />
              <img v-else src="@/assets/images/avatar.png" alt="" />
            </div>
            <div class="discuss_list_right">
              <div class="discuss_list_top">
                <div>{{ item.user.name }}</div>
                <div class="discuss_list_thumbs">
                  <img
                    src="@/assets/images/thumbs-up1.png"
                    alt=""
                    v-if="item.support.length == 0"
                    @click="clickSupport(item.id, '点赞')"
                  />
                  <img
                    src="@/assets/images/thumbs-up.png"
                    alt=""
                    v-else
                    @click="clickSupport(item.id, '取消点赞')"
                  /><span>{{ item.support_count }}</span>
                </div>
              </div>
              <div class="discuss_list_time">{{ item.created_at }}</div>
              <div class="discuss_list_ask">
                <p
                  :class="
                    item.commenttextclass ? 'discuss_list_answer_stem' : ''
                  "
                >
                  {{ item.commenttext }}
                  <span
                    v-if="item.commenttext.length > 14"
                    @click="clickshowask(index)"
                    :class="item.commenttextclass ? 'active' : ''"
                  >
                    {{ item.msg }}
                  </span>
                </p>
              </div>
              <div
                class="discuss_list_answer"
                v-for="(item1, index1) in item.son"
                :key="index1"
              >
                <span v-if="item1.name">{{ item1.name }}：</span
                ><span v-else>{{ item1.user.name }}：</span>
                <p
                  :class="
                    item1.commenttextclass ? 'discuss_list_answer_stem' : ''
                  "
                >
                  {{ item1.commenttext }}
                  <span
                    v-if="item1.commenttext.length > 14"
                    @click="clickshowcommenttext(index, index1)"
                    :class="item1.commenttextclass ? 'active' : ''"
                  >
                    {{ item1.msg }}
                  </span>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-else>暂无评论</div>
      </div>
      <!-- <textarea v-model="discussCreate" rows="" cols=""></textarea>
      <div>
        <div></div>
        <van-button type="danger" @click="clickDiscussCreate"
          >发表评论</van-button
        >
      </div> -->
      <van-divider v-if="discussObj.total > 0">没有更多了</van-divider>
    </van-popup>
    <van-popup
      class="discussModel discussModel1"
      v-model="discussShow1"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="discussModelTop">
        <div @click="discussShow1 = false">取消</div>
        <div style="font-size: 18px">发表评论</div>
        <div @click="clickDiscussCreate">提交</div>
      </div>
      <div>
        <textarea
          v-model="discussCreate"
          rows=""
          cols=""
          placeholder="写下你的评论..."
        ></textarea>
      </div>
    </van-popup>
    <div class="main" v-if="allList.length > 0">
      <div
        v-if="v.isShowList"
        class="single"
        v-for="(v, i) in allList"
        :key="i"
      >
        <!-- 单选题 -->
        <div class="singchoice" v-if="v.question.type == 1 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 1 ? "单选题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>
              <div
                class="single_choices"
                @click="!v.isShowJx && clickSingle(i, index)"
                v-for="(item, index) in v.question.single_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox" v-if="v.question.file">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              @click="!v.isShowJx && clickSingle(i, index)"
              v-for="(item, index) in v.question.single_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <!-- <video
              :src="v.question.file.playpath"
              controls="controls"
              class="avatar"
              playsinline="true"
              webkit-playsinline="true"
            ></video> -->
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              @click="!v.isShowJx && clickSingle(i, index)"
              v-for="(item, index) in v.question.single_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <div class="btn">
            <div></div>
            <van-button
              :disabled="!v.active"
              :class="v.isShowJx ? 'activeJx' : ''"
              type="danger"
              @click="clickShowJx(i)"
              >{{ v.massage }}</van-button
            >
          </div>
          <div class="analysis" v-if="v.isShowJx">
            <div class="analysisDa">
              <div class="analysisDaQuery">
                <div class="nide">
                  <div>你的答案</div>
                  <div
                    class="youanswer"
                    v-for="(item, index) in v.question.single_choices"
                    :key="index"
                    v-show="item.isShow"
                  >
                    {{ listSum[index] }}
                    <i
                      class="iconfont icon-zhengque1"
                      style="color: #25c17c; font-size: 20px"
                      v-show="item.yes == 1 && item.isShow ? true : false"
                    ></i
                    ><i
                      class="iconfont icon-cuowu"
                      style="color: #f4433c; font-size: 20px"
                      v-show="item.yes == 0 && item.isShow ? true : false"
                    ></i>
                  </div>
                </div>
                <div class="daan">
                  <h4>参考答案</h4>
                  <div
                    v-for="(item, index) in v.question.single_choices"
                    :key="index"
                    v-show="item.yes == 1 ? true : false"
                  >
                    {{ listSum[index] }}
                  </div>
                </div>
              </div>
              <div class="jiexi">
                <div>解析:</div>
                <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis == '' ? true : false"
                  >暂无</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 多选题 -->
        <div class="morechoice" v-if="v.question.type == 2 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 2 ? "多选题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="!v.isShowJx && clickMany(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.multiple_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox" v-if="v.question.file">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="!v.isShowJx && clickMany(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.multiple_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <!-- <video
              :src="v.question.file.playpath"
              controls="controls"
              class="avatar"
              playsinline="true"
              webkit-playsinline="true"
            ></video> -->
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="!v.isShowJx && clickMany(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.multiple_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <div class="btn">
            <div></div>
            <van-button
              :disabled="!v.active"
              type="danger"
              :class="v.isShowJx ? 'activeJx' : ''"
              @click="clickShowJx(i)"
              >{{ v.massage }}</van-button
            >
          </div>
          <div class="analysis" v-if="v.isShowJx">
            <div class="analysisDa">
              <div class="nide">
                <div>你的答案</div>
                <div class="more">
                  <div>
                    <span
                      class="youanswer"
                      v-for="(item, index) in v.question.multiple_choices"
                      :key="index"
                      v-show="item.isShow"
                    >
                      {{ listSum[index] }}
                    </span>
                  </div>
                  <i
                    class="iconfont icon-zhengque1"
                    style="color: #25c17c; font-size: 20px"
                    v-show="v.yuxuan == v.daan ? true : false"
                  ></i
                  ><i
                    class="iconfont icon-cuowu"
                    style="color: #f4433c; font-size: 20px"
                    v-show="v.yuxuan != v.daan ? true : false"
                  ></i>
                </div>
              </div>
              <div class="daan">
                <h4>参考答案</h4>
                <span
                  v-for="(item, index) in v.question.multiple_choices"
                  :key="index"
                  v-show="item.yes == 1 ? true : false"
                >
                  {{ listSum[index] }}
                </span>
              </div>
              <div class="jiexi">
                <div>解析：</div>
                <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis == '' ? true : false"
                  >暂无</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 判断题 -->
        <div class="panduan" v-if="v.question.type == 3 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 3 ? "判断题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="!v.isShowJx && clickJudges(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.judges"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''"></span
                >{{ item.answer }}
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox" v-if="v.question.file">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="!v.isShowJx && clickJudges(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.judges"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''"></span
              >{{ item.answer }}
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <!-- <video
              :src="v.question.file.playpath"
              controls="controls"
              class="avatar"
              playsinline="true"
              webkit-playsinline="true"
            ></video> -->
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="!v.isShowJx && clickJudges(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.judges"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''"></span
              >{{ item.answer }}
            </div>
          </div>
          <div class="btn">
            <div></div>
            <van-button
              type="danger"
              :disabled="!v.active"
              :class="v.isShowJx ? 'activeJx' : ''"
              @click="clickShowJx(i)"
              >{{ v.massage }}</van-button
            >
          </div>
          <div class="analysis" v-if="v.isShowJx">
            <div class="analysisDa">
              <div class="nide">
                <div>你的答案</div>
                <span
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                  v-show="item.isShow"
                >
                  {{ item.answer }}
                  <i
                    class="iconfont icon-zhengque1"
                    style="color: #25c17c; font-size: 20px"
                    v-show="item.yes == 1 && item.isShow ? true : false"
                  ></i
                  ><i
                    class="iconfont icon-cuowu"
                    style="color: #f4433c; font-size: 20px"
                    v-show="item.yes == 0 && item.isShow ? true : false"
                  ></i>
                </span>
              </div>
              <div class="daan">
                <h4>参考答案</h4>
                <span
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                  v-show="item.yes == 1 ? true : false"
                >
                  {{ item.answer }}
                </span>
              </div>
              <div class="jiexi">
                <div>解析：</div>
                <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis == '' ? true : false"
                  >暂无</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 多元填空 -->
        <div class="multipleList" v-if="v.question.type == 6 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{
                v.question.type == 6 ? "多元填空题" : ""
              }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>

          <div
            class="audioTitle"
            v-if="v.question.stem_type == 1 ? true : false"
          >
            <div
              class="stem"
              v-html="v.question.stem"
              @input="!v.isShowJx && clickInput($event, i)"
            ></div>

            <!-- <div id="box1" :style="{ height: newHeight + 'px' }"> -->
            <!-- <div class="touchmove">
              <button @touchstart="touchstart($event)" @touchmove="touchmove($event)">
                按住拖动可调整页面
              </button>
            </div> -->
            <div id="box2" class="analysis">
              <div class="btn">
                <div></div>
                <van-button
                  :disabled="!v.active"
                  type="danger"
                  :class="v.isShowJx ? 'activeJx' : ''"
                  @click="clickShowJx(i)"
                  >{{ v.massage }}</van-button
                >
              </div>
              <div class="analysis" v-if="v.isShowJx">
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空</div>
                    <!-- {{ index + 1 }}: -->
                    <div class="nide">
                      你的答案:
                      <span v-if="v.input[index] != ''">{{
                        v.input[index]
                      }}</span>
                      <span v-else>未作答</span>
                    </div>
                    <div class="reference">
                      <span> 参考答案:</span>
                      <div>
                        <div
                          v-for="(item1, index1) in item.answers"
                          :key="index1"
                        >
                          {{ item1.answer }}
                        </div>
                      </div>
                    </div>
                    <div>
                      小题解析：{{ item.analysis }}
                      <span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi" v-if="v.question.analysis">
                  <div style="color: black">解析:</div>
                  <div v-html="v.question.analysis"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="audioTitle"
            v-if="v.question.stem_type == 2 ? true : false"
          >
            <div class="audioBox" v-if="v.question.file">
              <div class="btn">
                <div class="back" @click="clickSnd(-15)">
                  <img src="../../assets/images/kuaijin1.png" alt="" />
                </div>
                <div>{{ v.question.file.filename }}</div>
                <div class="forward" @click="clickSnd(15)">
                  <img src="../../assets/images/kuaijin2.png" alt="" />
                </div>
              </div>
              <audio controls controlslist="nodownload">
                <source :src="v.question.file.playpath" type="audio/mp3" />
                您的浏览器不支持 HTML5 audio 标签。
              </audio>
            </div>
            <div
              class="stem"
              v-html="v.question.stem"
              @input="!v.isShowJx && clickInput($event, i)"
            ></div>

            <!-- <div id="box1" :style="{ height: newHeight + 'px' }"> -->
            <!-- <div class="touchmove">
              <button @touchstart="touchstart($event)" @touchmove="touchmove($event)">
                按住拖动可调整页面
              </button>
            </div> -->
            <div id="box2" class="analysis">
              <div class="btn">
                <div></div>
                <van-button
                  :disabled="!v.active"
                  type="danger"
                  :class="v.isShowJx ? 'activeJx' : ''"
                  @click="clickShowJx(i)"
                  >{{ v.massage }}</van-button
                >
              </div>
              <div class="analysis" v-if="v.isShowJx">
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空</div>
                    <!-- {{ index + 1 }}: -->
                    <div class="nide">
                      你的答案:
                      <span v-if="v.input[index] != ''">{{
                        v.input[index]
                      }}</span>
                      <span v-else>未作答</span>
                    </div>
                    <div class="reference">
                      <span> 参考答案:</span>
                      <div>
                        <div
                          v-for="(item1, index1) in item.answers"
                          :key="index1"
                        >
                          {{ item1.answer }}
                        </div>
                      </div>
                    </div>
                    <div>
                      小题解析：{{ item.analysis }}
                      <span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi" v-if="v.question.analysis">
                  <div style="color: black">解析:</div>
                  <div v-html="v.question.analysis"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="audioTitle"
            v-if="v.question.stem_type == 4 ? true : false"
          >
            <img
              v-if="v.question.file"
              :src="v.question.file.playpath"
              alt=""
              @click="clickShowImagePreview(i)"
            />

            <div
              class="stem"
              v-html="v.question.stem"
              @input="!v.isShowJx && clickInput($event, i)"
            ></div>

            <!-- <div id="box1" :style="{ height: newHeight + 'px' }"> -->
            <!-- <div class="touchmove">
              <button @touchstart="touchstart($event)" @touchmove="touchmove($event)">
                按住拖动可调整页面
              </button>
            </div> -->
            <div id="box2" class="analysis">
              <div class="btn">
                <div></div>
                <van-button
                  :disabled="!v.active"
                  type="danger"
                  :class="v.isShowJx ? 'activeJx' : ''"
                  @click="clickShowJx(i)"
                  >{{ v.massage }}</van-button
                >
              </div>
              <div class="analysis" v-if="v.isShowJx">
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空</div>
                    <!-- {{ index + 1 }}: -->
                    <div class="nide">
                      你的答案:
                      <span v-if="v.input[index] != ''">{{
                        v.input[index]
                      }}</span>
                      <span v-else>未作答</span>
                    </div>
                    <div class="reference">
                      <span> 参考答案:</span>
                      <div>
                        <div
                          v-for="(item1, index1) in item.answers"
                          :key="index1"
                        >
                          {{ item1.answer }}
                        </div>
                      </div>
                    </div>
                    <div>
                      小题解析：{{ item.analysis }}
                      <span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi" v-if="v.question.analysis">
                  <div style="color: black">解析:</div>
                  <div v-html="v.question.analysis"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- 阅读题 -->
        <div class="reading" v-if="v.question.type == 9 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 9 ? "阅读题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>

          <div v-if="v.question.stem_type == 1 ? true : false" >
            <div class="audioTitle">
              <div
                class="stem stem1"
                :style="{ height: 620 - newHeight + 'px' }"
                v-html="v.question.stem"
              ></div>
              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <div v-for="(v1, i1) in v.question.reads" :key="i1">
                    <div>
                      {{ i1 + 1 }}.{{
                        v1.question.stem == "null" ? "" : v1.question.stem
                      }}
                    </div>

                    <div
                      class="single_choices"
                      v-for="(item, index) in v1.question.single_choices"
                      :key="index"
                      @click="!v1.isShowJx && clickReading(i, i1, index)"
                    >
                      <span :class="item.isShow ? 'active' : ''">{{
                        listSum[index]
                      }}</span>
                      <p v-html="item.answer"></p>
                    </div>
                    <div class="btn" style="padding: 0">
                      <div></div>
                      <van-button
                        type="danger"
                        :disabled="!v1.active"
                        :class="v1.isShowJx ? 'activeJx' : ''"
                        @click="clickShowJxRed(i, i1)"
                        >{{ v1.massage }}</van-button
                      >
                    </div>
                    <div class="analysis" v-if="v1.isShowJx">
                      <!-- 答案 -->
                      <div class="practice">
                        <div class="daan">
                          <div class="nide">
                            <div>你的答案</div>
                            <div
                              class="youanswer"
                              v-for="(item, index) in v1.question
                                .single_choices"
                              :key="index"
                              v-show="item.isShow"
                            >
                              {{ listSum[index] }}
                              <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-show="
                                  item.yes == 1 && item.isShow ? true : false
                                "
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-show="
                                  item.yes == 0 && item.isShow ? true : false
                                "
                              ></i>
                            </div>
                          </div>
                          <div>
                            <h4
                              class="firstdiv"
                              style="color: black; font-weight: 400"
                            >
                              参考答案
                            </h4>
                            <div
                              class="single"
                              v-for="(item, index) in v1.question
                                .single_choices"
                              :key="index"
                              v-show="item.yes == 1 ? true : false"
                            >
                              {{ listSum[index] }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="jiexi">
                        <div style="color: black; font-weight: 400">解析:</div>
                        <div v-html="v1.question.analysis"></div>
                        <span v-if="v1.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="btn totalJiexi">
                    <div></div>
                    <van-button
                      :disabled="!v.active"
                      type="danger"
                      :class="v.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJx(i)"
                      >查看解析</van-button
                    >
                  </div>
                  <div v-if="v.isShowJx">
                    <div style="color: black">阅读解析:</div>
                    <div v-html="v.question.analysis"></div>
                    <span v-if="v.question.analysis == '' ? true : false"
                      >暂无</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="stem" :style="{ height: 620 - newHeight + 'px' }">
              <div class="audioTitle">
                <div class="audioBox" v-if="v.question.file">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>

                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                  <!-- <aplayer
                  v-if="v.question.file.playpath"
                  ref="audioPracticeple"
                  @play="clickpause"
                  :music="{
                    src: listAuido[i],
                    pic: require('../../assets/images/audioLogo.png'),
                  }"
                /> -->
                </div>
              </div>
              <div class="aaa" v-html="v.question.stem"></div>
            </div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                    @click="!v1.isShowJx && clickReading(i, i1, index)"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="btn" style="padding: 0px">
                    <div></div>
                    <van-button
                      :disabled="!v1.active"
                      type="danger"
                      :class="v1.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJxRed(i, i1)"
                      >{{ v1.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v1.isShowJx">
                    <!-- 答案 -->
                    <div class="practice">
                      <div class="daan">
                        <div class="nide">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.isShow"
                          >
                            {{ listSum[index] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-show="
                                item.yes == 1 && item.isShow ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-show="
                                item.yes == 0 && item.isShow ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div>
                          <h4
                            class="firstdiv"
                            style="color: black; font-weight: 400"
                          >
                            参考答案
                          </h4>
                          <div
                            class="single"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jiexi">
                      <div style="color: black; font-weight: 400">解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="btn totalJiexi">
                  <div></div>
                  <van-button
                    :disabled="!v.active"
                    type="danger"
                    @click="clickShowJx(i)"
                    :class="v.isShowJx ? 'activeJx' : ''"
                    >查看解析</van-button
                  >
                </div>
                <div class="jiexi" v-if="v.isShowJx">
                  <div style="color: black">阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <!-- <video
              :src="v.question.file.playpath"
              controls="controls"
              class="avatar"
              playsinline="true"
              webkit-playsinline="true"
            ></video> -->
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div
              class="stem"
              :style="{ height: 620 - newHeight + 'px' }"
              v-html="v.question.stem"
            ></div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                    @click="!v1.isShowJx && clickReading(i, i1, index)"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="btn" style="padding: 0px">
                    <div></div>
                    <van-button
                      :disabled="!v1.active"
                      type="danger"
                      @click="clickShowJxRed(i, i1)"
                      :class="v1.isShowJx ? 'activeJx' : ''"
                      >{{ v1.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v1.isShowJx">
                    <!-- 答案 -->
                    <div class="practice">
                      <div class="daan">
                        <div class="nide">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.isShow"
                          >
                            {{ listSum[index] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-show="
                                item.yes == 1 && item.isShow ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-show="
                                item.yes == 0 && item.isShow ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div>
                          <h4
                            class="firstdiv"
                            style="color: black; font-weight: 400"
                          >
                            参考答案
                          </h4>
                          <div
                            class="single"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jiexi">
                      <div style="color: black; font-weight: 400">解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="btn totalJiexi">
                  <div></div>
                  <van-button
                    :disabled="!v.active"
                    type="danger"
                    @click="clickShowJx(i)"
                    :class="v.isShowJx ? 'activeJx' : ''"
                    >查看解析</van-button
                  >
                </div>
                <div class="jiexi" v-if="v.isShowJx">
                  <div style="color: black">阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 图片 -->
          <div v-if="v.question.stem_type == 4 ? true : false">
            <div class="audioTitle">
              <img
                v-if="v.question.file"
                :src="v.question.file.playpath"
                alt=""
                @click="clickShowImagePreview(i)"
              />
            </div>
            <div
              class="stem"
              v-html="v.question.stem"
              :style="{ height: 620 - newHeight + 'px' }"
            ></div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                    @click="!v1.isShowJx && clickReading(i, i1, index)"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="btn" style="padding: 0px">
                    <div></div>
                    <van-button
                      :disabled="!v1.active"
                      type="danger"
                      :class="v1.isShowJx ? 'activeJx' : ''"
                      @click="clickShowJxRed(i, i1)"
                      >{{ v1.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v1.isShowJx">
                    <!-- 答案 -->
                    <div class="practice">
                      <div class="daan">
                        <div class="nide">
                          <div>你的答案</div>
                          <div
                            class="youanswer"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.isShow"
                          >
                            {{ listSum[index] }}
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-show="
                                item.yes == 1 && item.isShow ? true : false
                              "
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-show="
                                item.yes == 0 && item.isShow ? true : false
                              "
                            ></i>
                          </div>
                        </div>
                        <div>
                          <h4
                            class="firstdiv"
                            style="color: black; font-weight: 400"
                          >
                            参考答案
                          </h4>
                          <div
                            class="single"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-show="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jiexi">
                      <div style="color: black; font-weight: 400">解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="btn totalJiexi">
                  <div></div>
                  <van-button
                    :disabled="!v.active"
                    type="danger"
                    @click="clickShowJx(i)"
                    :class="v.isShowJx ? 'activeJx' : ''"
                    >查看解析</van-button
                  >
                </div>
                <div class="jiexi" v-if="v.isShowJx">
                  <div style="color: black">阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 问答题 -->
        <div class="question-answer" v-if="v.question.type == 4 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 4 ? "问答题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>

          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div
                class="stem"
                v-html="v.question.stem"
                :style="{ height: 620 - newHeight + 'px' }"
              ></div>

              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                  <textarea
                    v-model="v.modelValue"
                    @input="changeText(i)"
                    ref="yourinput"
                    type="text"
                    :disabled="v.isShowJx"
                  />

                  <div class="btn">
                    <div>
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <div class="listAudio">
                        <div v-for="(item, index) in v.listAuido" :key="index">
                          <div class="listAuidoBox">
                            <div
                              class="ad_zan"
                              v-if="item.BofangBool"
                              @click="clickBoAudio(i, index)"
                            ></div>
                            <div
                              class="ad_bo"
                              v-else
                              @click="clickBoAudioStop(i, index)"
                            ></div>
                            {{ item.time }}
                          </div>
                          <div>
                            <span
                              @click="clickRemove(i, index)"
                              class="iconfont icon-cuowu2"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <van-button
                      :disabled="!v.active"
                      type="danger"
                      @click="clickShowJx(i)"
                      :class="v.isShowJx ? 'activeJx' : ''"
                      >{{ v.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v.isShowJx">
                    <div class="wenda">
                      <div class="analysis" v-if="v.isShowJx">
                        <div class="jiexi nide">
                          <div>你的答案:</div>
                          <div>{{ v.modelValue }}</div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div v-html="v.question.analysis"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 上传语音弹出层 -->
            <van-popup
              class="classShowAudio"
              v-model="v.showAudio"
              closeable
              :overlay="false"
              position="bottom"
              :style="{ height: '150px' }"
            >
              <div class="time">
                <div>{{ timeNum }}</div>
                <span>/</span>
                <div>01:00</div>
              </div>
              <div class="luyingbox">
                <div
                  @click="clickPaly(i, true)"
                  v-if="!v.AudioBool"
                  class="luyin"
                ></div>
                <div @click="clickPaly(i, false)" v-else class="zanting"></div>
              </div>
              <p>{{ v.AudioText }}</p>
            </van-popup>
          </div>
          <!-- 图片 -->
          <div v-if="v.question.stem_type == 4 ? true : false">
            <div class="audioTitle">
              <div class="stem" :style="{ height: 620 - newHeight + 'px' }">
                <img
                  v-if="v.question.file"
                  :src="v.question.file.playpath"
                  alt=""
                  @click="clickShowImagePreview(i)"
                />
                <div v-html="v.question.stem"></div>
              </div>

              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                  <textarea
                    v-model="v.modelValue"
                    @input="changeText(i)"
                    ref="drag-input"
                    type="text"
                    :disabled="v.isShowJx"
                  />

                  <div class="btn">
                    <div>
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <div class="listAudio">
                        <div v-for="(item, index) in v.listAuido" :key="index">
                          <div class="listAuidoBox">
                            <div
                              class="ad_zan"
                              v-if="item.BofangBool"
                              @click="clickBoAudio(i, index)"
                            ></div>
                            <div
                              class="ad_bo"
                              v-else
                              @click="clickBoAudioStop(i, index)"
                            ></div>
                            {{ item.time }}
                          </div>
                          <div>
                            <span
                              @click="clickRemove(i, index)"
                              class="iconfont icon-cuowu2"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <van-button
                      :disabled="!v.active"
                      type="danger"
                      @click="clickShowJx(i)"
                      :class="v.isShowJx ? 'activeJx' : ''"
                      >{{ v.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v.isShowJx">
                    <div class="wenda">
                      <div class="analysis">
                        <div class="jiexi nide">
                          <div>你的答案:</div>
                          <div>{{ v.modelValue }}</div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div v-html="v.question.analysis"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 上传语音弹出层 -->
            <van-popup
              class="classShowAudio"
              v-model="v.showAudio"
              closeable
              :overlay="false"
              position="bottom"
              :style="{ height: '150px' }"
            >
              <div class="time">
                <div>{{ timeNum }}</div>
                <span>/</span>
                <div>01:00</div>
              </div>
              <div class="luyingbox">
                <div
                  @click="clickPaly(i, true)"
                  v-if="!v.AudioBool"
                  class="luyin"
                ></div>
                <div @click="clickPaly(i, false)" v-else class="zanting"></div>
              </div>
              <p>{{ v.AudioText }}</p>
            </van-popup>
          </div>
          <!--时评 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <div class="audioTitle">
              <!-- <video
                :src="v.question.file.playpath"
                controls="controls"
                class="avatar"
                playsinline="true"
                webkit-playsinline="true"
              ></video> -->
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div
                class="stem"
                v-html="v.question.stem"
                :style="{ height: 620 - newHeight + 'px' }"
              ></div>

              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                  <textarea
                    v-model="v.modelValue"
                    @input="changeText(i)"
                    ref="drag-input"
                    type="text"
                    :disabled="v.isShowJx"
                  />

                  <div class="btn">
                    <div>
                      <div class="listAudioBtn">
                        <span
                          class="iconfont icon-yuyin"
                          @click="uploadAudio(i)"
                        ></span>
                        <van-button type="danger" @click="uploadAudio(i)">
                          点击录音</van-button
                        >
                      </div>
                      <div class="listAudio">
                        <div v-for="(item, index) in v.listAuido" :key="index">
                          <div class="listAuidoBox">
                            <div
                              class="ad_zan"
                              v-if="item.BofangBool"
                              @click="clickBoAudio(i, index)"
                            ></div>
                            <div
                              class="ad_bo"
                              v-else
                              @click="clickBoAudioStop(i, index)"
                            ></div>
                            {{ item.time }}
                          </div>
                          <div>
                            <span
                              @click="clickRemove(i, index)"
                              class="iconfont icon-cuowu2"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <van-button
                      :disabled="!v.active"
                      type="danger"
                      @click="clickShowJx(i)"
                      :class="v.isShowJx ? 'activeJx' : ''"
                      >{{ v.massage }}</van-button
                    >
                  </div>
                  <div class="analysis" v-if="v.isShowJx">
                    <div class="wenda">
                      <div class="analysis">
                        <div class="jiexi nide">
                          <div>你的答案:</div>
                          <div>{{ v.modelValue }}</div>
                        </div>
                        <div class="jiexi">
                          <div>解析:</div>
                          <div v-html="v.question.analysis"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 上传语音弹出层 -->
            <van-popup
              class="classShowAudio"
              v-model="v.showAudio"
              closeable
              :overlay="false"
              position="bottom"
              :style="{ height: '150px' }"
            >
              <div class="time">
                <div>{{ timeNum }}</div>
                <span>/</span>
                <div>01:00</div>
              </div>
              <div class="luyingbox">
                <div
                  @click="clickPaly(i, true)"
                  v-if="!v.AudioBool"
                  class="luyin"
                ></div>
                <div @click="clickPaly(i, false)" v-else class="zanting"></div>
              </div>
              <p>{{ v.AudioText }}</p>
            </van-popup>
          </div>
        </div>
        <!-- 听力题 -->
        <div class="listening" v-if="v.question.type == 10 ? true : false">
          <div class="topicTitle">
            <div>
              {{ i + 1 }}.<span
                v-if="v.question.defined == '' ? false : true"
                >{{ v.question.defined }}</span
              ><span v-else>{{ v.question.type == 10 ? "听力题" : "" }}</span>
            </div>
            <div class="collection">
              <div v-if="v.question.collection == 1">
                <van-icon
                  color="#ffb41c"
                  name="star"
                  @click="clickCancel(i, 0, v)"
                />
                <span>收藏</span>
              </div>
              <div v-else>
                <van-icon
                  @click="clickCancel(i, 1, v)"
                  color="#ccc"
                  name="star"
                />
                <span>收藏</span>
              </div>
            </div>
          </div>

          <!-- 音频 -->
          <div class="Hearing" v-if="v.question.stem_type == 2 ? true : false">
            <div
              class="hearingtype"
              :style="{ height: 620 - newHeight + 'px' }"
            >
              <div class="audioTitle">
                <div class="audioBox" v-if="v.question.file">
                  <div class="btn">
                    <div class="back" @click="clickSnd(-15)">
                      <img src="../../assets/images/kuaijin1.png" alt="" />
                    </div>
                    <div>{{ v.question.file.filename }}</div>
                    <div class="forward" @click="clickSnd(15)">
                      <img src="../../assets/images/kuaijin2.png" alt="" />
                    </div>
                  </div>
                  <audio controls controlslist="nodownload">
                    <source :src="v.question.file.playpath" type="audio/mp3" />
                    您的浏览器不支持 HTML5 audio 标签。
                  </audio>
                </div>
              </div>
              <div class="stem" v-html="v.question.stem"></div>
            </div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="Listen">
                  <h4 style="margin: 10px 20px; font-weight: 600">回答</h4>
                  <textarea
                    v-model="v.modelValue"
                    @input="changeText(i)"
                    type="text"
                    :disabled="v.isShowJx"
                  />
                </div>

                <div class="btn">
                  <div>
                    <div class="listAudioBtn">
                      <span
                        class="iconfont icon-yuyin"
                        @click="uploadAudio(i)"
                      ></span>
                      <van-button type="danger" @click="uploadAudio(i)">
                        点击录音</van-button
                      >
                    </div>
                    <div class="listAudio">
                      <div v-for="(item, index) in v.listAuido" :key="index">
                        <div class="listAuidoBox">
                          <div
                            class="ad_zan"
                            v-if="item.BofangBool"
                            @click="clickBoAudio(i, index)"
                          ></div>
                          <div
                            class="ad_bo"
                            v-else
                            @click="clickBoAudioStop(i, index)"
                          ></div>
                          {{ item.time }}
                        </div>
                        <div>
                          <span
                            @click="clickRemove(i, index)"
                            class="iconfont icon-cuowu2"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <van-button
                    :disabled="!v.active"
                    type="danger"
                    @click="clickShowJx(i)"
                    :class="v.isShowJx ? 'activeJx' : ''"
                    >{{ v.massage }}</van-button
                  >
                </div>
                <div class="analysis" v-if="v.isShowJx">
                  <div class="tingli" style="margin-top: 0">
                    <div class="jiexi nide">
                      <div>你的答案:</div>
                      <div>{{ v.modelValue }}</div>
                    </div>
                    <div class="jiexi">
                      <div>解析:</div>
                      <div v-html="v.question.analysis"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 上传语音弹出层 -->
            <van-popup
              class="classShowAudio"
              v-model="v.showAudio"
              closeable
              :overlay="false"
              position="bottom"
              :style="{ height: '150px' }"
            >
              <div class="time">
                <div>{{ timeNum }}</div>
                <span>/</span>
                <div>01:00</div>
              </div>
              <div class="luyingbox">
                <div
                  @click="clickPaly(i, true)"
                  v-if="!v.AudioBool"
                  class="luyin"
                ></div>
                <div @click="clickPaly(i, false)" v-else class="zanting"></div>
              </div>
              <p>{{ v.AudioText }}</p>
            </van-popup>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="zanwu">暂无试题</div>
    <div class="bottom" v-if="allList.length > 0">
      <div @click="clickCard">
        <i class="iconfont icon-datiqia"></i> <span class="card">答题卡</span>
      </div>
      <div @click="clickDiscuss">
        <i class="iconfont icon-pinglun"></i>
        <span style="margin-left: 5px">{{ discussObj.total }}</span>
      </div>
      <div>
        <button
          class="confim"
          :class="upperBool ? 'active' : ''"
          :disabled="!upperBool"
          @click="clickShow(-1)"
        >
          上一题
        </button>
        <button
          class="confim"
          :class="downBool ? 'active' : ''"
          v-if="downBool"
          @click="clickShow(1)"
        >
          下一题
        </button>
        <button class="confim" v-if="!downBool" @click="clickshowTop">
          <i class="iconfont icon-fanhui"></i>
          <span>结束练习</span>
        </button>
      </div>
    </div>
    <van-action-sheet
      v-if="show"
      v-model="show"
      title="答题卡"
      :style="{ height: '50%' }"
    >
      <!-- 答题卡 -->
      <div class="content">
        <h6>
          <span>答题概况</span> (已答{{ countyes }},未答{{
            countno - countyes
          }})
        </h6>
        <div class="circle">
          <div
            :class="item.active ? 'active' : ''"
            v-for="(item, index) in allList"
            :key="index"
            @click="clickActiveTest(index)"
            :style="item.isShowList ? 'color:#25c17c;border-color:#25c17c' : ''"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import $ from "jquery";
import { ImagePreview } from "vant";
import wxShare from "@/utils/wxShare.js";
// import Aplayer from "vue-aplayer";
// Aplayer.disableVersionBadge = true;
export default {
  // components: {
  //   Aplayer,
  // },
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      discussShow: false,
      discussShow1: false,
      loadingBool: true,
      total: 0, //总数
      singleData: [], //单选 1
      manyData: [], //多选 2
      judgeData: [], //判断题 3
      multivariateData: [], //多元填空题 6
      askData: [], //问答题 4
      hearingData: [], //听力题 10
      readingData: [], //阅读题 9
      question: {}, //创建的试卷
      radio: "",
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      // listBool: ["正确","错误"],
      show: false, //答题卡
      showTop: false, //交卷
      date: "", //时间
      countyes: 0, //已答题
      countno: 0, //未答题
      circle: [], //答题卡
      allList: [], //所有数据
      upperBool: false, //上一题
      downBool: true, //下一题
      defaultIndex: 0, //激活题
      widthActive: "", //激活宽度
      papersShow: false, //交卷show
      multipleList: [], //多元填空input
      multipleListNum: [],
      // massage:"查看解析"
      newHeight: 300,
      images: [],
      titlename: "",
      audioTime: 0,
      timer: "", //计时器
      minutes: 0,
      seconds: 0,
      timeNum: "00:00",
      discussObj: {
        total: 0,
      }, //评论
      discussListPage: {
        //评论分页
        limit: 99999,
        page: 1,
      },
      discussId: 0, //评论id
      discussCreate: "", //评论
    };
  },

  methods: {
    clickCancel(index, collection, item) {
      let _this = this;
      let str = "";
      if (collection == 0) {
        str = "取消收藏";
      } else {
        str = "收藏";
      }
      _this.$dialog
        .confirm({
          title: str,
        })
        .then(() => {
          let obj = {
            collection: collection /** 收藏动作，1代表收藏，0代表取消收藏 */,
            question_id:
              item.question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
          };

          _this.$api.recordsCollectlist(obj).then((res) => {
            _this.$delete(_this.allList[index].question, "collection");
            _this.$set(_this.allList[index].question, "collection", collection);

            if (_this.$route.query.val == "错题集") {
              _this.allList.forEach((v) => {
                if (_this.allList[index].question_id == v.question_id) {
                  v.question.collection = collection;
                }
              });
            }

            _this.$toast.success(str + "成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    clickshowcommenttext(index, index1) {
      let _this = this;
      if (_this.discussObj.data[index].son[index1].commenttextclass) {
        _this.$delete(_this.discussObj.data[index].son[index1], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index].son[index1], "msg", "收起");
        _this.$delete(
          _this.discussObj.data[index].son[index1],
          "commenttextclass"
        ); //解决视图不跟新
        _this.$set(
          _this.discussObj.data[index].son[index1],
          "commenttextclass",
          false
        );
      } else {
        _this.$delete(_this.discussObj.data[index].son[index1], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index].son[index1], "msg", "展开");
        _this.$delete(
          _this.discussObj.data[index].son[index1],
          "commenttextclass"
        ); //解决视图不跟新
        _this.$set(
          _this.discussObj.data[index].son[index1],
          "commenttextclass",
          true
        );
      }
    },
    clickshowask(index) {
      let _this = this;
      if (_this.discussObj.data[index].commenttextclass) {
        _this.$delete(_this.discussObj.data[index], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "msg", "收起");
        _this.$delete(_this.discussObj.data[index], "commenttextclass"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "commenttextclass", false);
      } else {
        _this.$delete(_this.discussObj.data[index], "msg"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "msg", "展开");
        _this.$delete(_this.discussObj.data[index], "commenttextclass"); //解决视图不跟新
        _this.$set(_this.discussObj.data[index], "commenttextclass", true);
      }
    },
    clickDiscuss() {
      // 评论
      let _this = this;
      _this.discussShow = true;
    },
    clickDiscussCreate() {
      // 创建评论
      let _this = this;
      if (_this.discussCreate == "") {
        _this.$toast.fail("评论不能为空");
      } else {
        let obj = {
          question_id: _this.discussId, //试题id
          commenttext: _this.discussCreate, //评论
        };
        if (_this.$route.query.val) {
          obj.question_class_id = _this.allList[_this.defaultIndex]; //试卷id
          console.log(obj);
        } else {
          obj.question_class_id = _this.question.question_class_id; //试卷id
        }
        // return
        _this.$api.discussCreate(obj).then((res) => {
          _this.$toast.success("发表成功");
          _this.discussShow1 = false;
          _this.discussCreate = "";
          _this.discuss();
        });
      }
    },
    discuss() {
      // 获取评论
      let _this = this;
      let obj = {
        question_id: _this.discussId, //试题id
        commentable_type: "Question",
      };
      _this.$api.discuss(obj).then((res) => {
        // console.log(res.data.lists);
        _this.discussObj = res.data.lists;
        res.data.lists.data.forEach((v) => {
          v.commenttextclass = true; //收起和展开评论
          v.msg = "展开"; //收起和展开
          v.son.forEach((v1) => {
            v1.commenttextclass = true; //收起和展开回复
            v1.msg = "展开"; //收起和展开
          });
        });
      });
    },
    clickSupport(id, val) {
      // 创建赞/取消赞
      let _this = this;
      let obj = {
        id: id,
      };
      _this.$api.support(obj).then((res) => {
        _this.$toast.success(val);
        _this.discuss();
      });
    },
    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      _this.images[0] = _this.allList[index].question.file.playpath;
      ImagePreview(_this.images);
    },
    touchstart(e) {
      e.preventDefault();
      // var audio = document.getElementsByTagName("audio");
      // this.audioTime = audio[0].currentTime;
      // console.log(this.audioTime);
      // // // audio[0].play();
      // this.$nextTick(() => {
      //   audio.currentTime = this.audioTime;
      //   audio[0].play();
      // });
      this.init = e.targetTouches[0].clientY;
      this.parent = document.getElementById("box1");
      this.initHeight = this.parent.offsetHeight;
    },
    touchmove(e) {
      this.end = e.targetTouches[0].clientY;
      if (this.end > 120 && this.end < 500) {
        this.newHeight = -this.end + this.init + this.initHeight;
        //    var audio = document.getElementsByTagName("audio");
        // let time = audio[0].currentTime;
        // // audio[0].play();
        // this.$nextTick(() => {
        //   audio.currentTime = time;
        //   audio[0].play();
        // });
      }
    },
    clickActiveTest(index) {
      let _this = this;
      _this.defaultIndex = index;
      _this.allList.forEach((v, i) => {
        if (index == i) {
          v.isShowList = true;
        } else {
          v.isShowList = false;
        }
        if (
          _this.defaultIndex > 0 &&
          _this.defaultIndex < _this.allList.length - 1
        ) {
          _this.downBool = true; //激活下一题
          _this.upperBool = true; //激活上一题
        } else if (_this.defaultIndex == 0) {
          _this.downBool = true; //激活下一题
          _this.upperBool = false; //去掉上一题
        } else if (_this.defaultIndex == _this.allList.length - 1) {
          _this.downBool = false; //去掉下一题
          _this.upperBool = true; //激活上一题
        }
      });
      clearInterval(this.timer);

      if (
        _this.allList[_this.defaultIndex].question.type == 4 ||
        _this.allList[_this.defaultIndex].question.type == 10
      ) {
        _this.allList[_this.defaultIndex].listAuido.forEach((v, indexx) => {
          v.BofangBool = true;
          wxShare.wx.stopVoice({
            localId: v.localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
          });
        });
      }
      //评论
      _this.discussId = _this.allList[_this.defaultIndex].question_id;
      _this.discuss();
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    clickShowJx(i) {
      //解析
      let _this = this;
      // _this.allList[i].isShowJx = !_this.allList[i].isShowJx;

      // if (_this.allList[i].isShowJx) {
      //   _this.allList[i].massage = "收起";
      // } else {
      //   _this.allList[i].massage = "验证答案";
      // }
      if (!_this.allList[i].isShowJx) {
        _this.allList[i].massage = "验证答案";
        _this.$set(_this.allList[i], "isShowJx", true);
        let daan = "";
        let yuxuan = "";
        if (_this.allList[i].question.type == 2) {
          //  _this.allList[i].yuxuan=
          _this.allList[i].question.multiple_choices.forEach((v, index) => {
            if (v.yes == 1) {
              daan += _this.listSum[index];
            }
            if (v.isShow) {
              yuxuan += _this.listSum[index];
            }
          });
        }
        _this.$set(_this.allList[i], "daan", daan);
        _this.$set(_this.allList[i], "yuxuan", yuxuan);
        // console.log(_this.allList[i]);
      }
      // console.log(1);
      // else {
      //   _this.allList[i].massage = "验证答案";
      //   _this.$set(_this.allList[i], "isShowJx", false);
      //   // bool.isShowJx = false;
      // }
    },
    clickShowJxRed(i, index) {
      // 阅读解析
      let _this = this;
      let bool = _this.allList[i].question.reads[index];
      if (!bool.isShowJx) {
        bool.massage = "验证答案";
        _this.$set(bool, "isShowJx", true);
      }
      // else {
      //   bool.massage = "验证答案";
      //   _this.$set(bool, "isShowJx", false);
      //   // bool.isShowJx = false;
      // }
    },
    clickReturn() {
      let _this = this;
      _this.showTop = false;
    },
    clickCard() {
      // 点击答题卡
      let _this = this;
      _this.show = true;
      let num = 0;
      _this.allList.forEach((v) => {
        //已答和未答
        if (v.active) {
          ++num;
        }
      });

      _this.countyes = num;
    },
    clickshowTop() {
      // 点击交卷
      let _this = this;
      _this.$dialog
        .confirm({
          title: "结束答题",
          message: "是否结束答题",
        })
        .then(() => {
          if (_this.$route.query.val == "收藏") {
            _this.$router.push("/PersonalCenter/Collection");
          } else if (_this.$route.query.val == "错题集") {
            _this.$router.push("/PersonalCenter/Showanalysis");
          } else if (_this.$route.query.val == "专项练习") {
            _this.$router.push({
              path: "/PersonalCenter/QuestionType",
              query: {
                id: _this.$route.query.id,
                name: _this.$route.query.name,
              },
            });
          } else {
            let obj = {
              id: _this.question.question_class_id,
            };
            _this.$api.delPracticeSave(obj).then((res) => {
              _this.$router.push({
                path: "/Index",
                query: {
                  id: _this.$route.query.id,
                },
              });
            });
          }
        });
    },
    clickShow(val) {
      //下一题上一题
      let _this = this;
      _this.newHeight = 300;
      $("#Practice audio").each((i, v) => {
        v.pause();
      });
      $("#Practice video").each((i, v) => {
        v.pause();
      });

      _this.defaultIndex += val;
      _this.allList.forEach((v, i) => {
        if (_this.defaultIndex == i) {
          _this.$set(_this.allList[i], "isShowList", true);
          // v.isShowList = true;
        } else {
          // v.isShowList = false;
          _this.$set(_this.allList[i], "isShowList", false);
        }
      });
      clearInterval(this.timer);

      if (
        _this.allList[_this.defaultIndex].question.type == 4 ||
        _this.allList[_this.defaultIndex].question.type == 10
      ) {
        _this.allList[_this.defaultIndex].listAuido.forEach((v, indexx) => {
          v.BofangBool = true;
          wxShare.wx.stopVoice({
            localId: v.localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
          });
        });
      }

      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allList.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allList.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
      // discuss
      //评论
      _this.discussId = _this.allList[_this.defaultIndex].question_id;
      _this.discuss();
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    clickJudges(i, index) {
      // 判断
      let _this = this;
      _this.allList[i].active = true;
      // _this.allList[i].question.judges[index].isShow = true;
      _this.$delete(_this.allList[i].question.judges[index], "isShow"); //解决视图不跟新
      _this.$set(_this.allList[i].question.judges[index], "isShow", true);

      _this.allList[i].question.judges.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
    },
    clickSingle(i, index) {
      // 单选
      let _this = this;
      _this.allList[i].active = true; //是否显示
      _this.allList[i].doBool = true; //做题

      // _this.allList[i].question.single_choices[index].isShow = true;

      _this.$delete(_this.allList[i].question.single_choices[index], "isShow"); //解决视图不跟新
      _this.$set(
        _this.allList[i].question.single_choices[index],
        "isShow",
        true
      );

      _this.allList[i].question.single_choices.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    clickMany(i, index) {
      // 多选题
      let _this = this;
      _this.allList[i].active = true;
      if (!_this.allList[i].question.multiple_choices[index].isShow) {
        _this.$delete(
          _this.allList[i].question.multiple_choices[index],
          "isShow"
        ); //解决视图不跟新
        _this.$set(
          _this.allList[i].question.multiple_choices[index],
          "isShow",
          true
        );
      } else {
        _this.$delete(
          _this.allList[i].question.multiple_choices[index],
          "isShow"
        ); //解决视图不跟新
        _this.$set(
          _this.allList[i].question.multiple_choices[index],
          "isShow",
          false
        );
      }
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    clickReading(i, i1, index) {
      // 阅读题
      let _this = this;
      _this.allList[i].active = true; //显示答题卡
      let list = _this.allList[i].question.reads[i1];
      // list.question.single_choices[index].isShow = true; //显示激活
      _this.$delete(
        _this.allList[i].question.reads[i1].question.single_choices[index],
        "isShow"
      ); //解决视图不跟新
      _this.$set(
        _this.allList[i].question.reads[i1].question.single_choices[index],
        "isShow",
        true
      );
      list.active = true; //小题是否做了
      list.question.single_choices.forEach((v, inde) => {
        if (inde == index) {
          v.isShow = true;
        } else {
          v.isShow = false;
        }
      });
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },

    records() {
      // 创建试卷
      let _this = this;
      this.loadingBool = true;
      let obj = {
        id: _this.question.question_class_id,
      };
      //获取所有数据
      _this.$api.practice(obj).then((res) => {
        console.log(res.data);
        _this.allObj = res.data.question_class_record;
        if (res.data.practicedata != "") {
          _this.$dialog
            .confirm({
              title: "提示",
              message: "检测到上次刷题记录位置<br/>是否跳转到该位置",
            })
            .then(() => {
              // on confirm
              _this.allList = JSON.parse(res.data.practicedata.allList);
              _this.defaultIndex = Number(res.data.practicedata.index);
              _this.total = _this.allList.length;
              _this.countno = _this.allList.length; //未作体术
              _this.discussId = _this.allList[_this.defaultIndex].question_id; //默认加载第一题评论
              _this.discuss();
              this.loadingBool = false;
            })
            .catch(() => {
              // on cancel
              _this.defaultGetList(res);
            });
        } else {
          _this.defaultGetList(res);
        }
      });
    },
    defaultGetList(res) {
      let _this = this;
      res.data.question_class_record.items.forEach((v, i) => {
        v.isShowJx = false; //是否显示解析
        // v.isShowJx = true; //是否显示解析
        v.massage = "验证答案";
        v.isShowList = false; //题显示隐藏
        v.question_id = v.id;
        if (v.question.type == 1) {
          if (v.question_id_son == null) {
            v.isShowList = false; //题显示隐藏
            v.question.single_choices.forEach((v1, i1) => {
              v1.isShow = false;
            });
            // _this.singleData.push(v); //单选
          }
        } else if (v.question.type == 2) {
          v.question.multiple_choices.forEach((v1) => {
            v1.isShow = false;
          });
          // _this.manyData.push(v); //多选
        } else if (v.question.type == 3) {
          v.question.judges.forEach((v1) => {
            v1.isShow = false;
          });
          // _this.judgeData.push(v); //判断题
        } else if (v.question.type == 4) {
          v.modelValue = "";
          v.showAudio = false; //录音版展示
          v.AudioText = "开始录音";
          v.AudioBool = false; //暂停录音
          v.Audiotime = 60000; //倒计时
          v.listAuido = [
            // {
            //   localId: "weixin://resourceid/74f40c87cbe546bb967bc54b44b58bd3",
            //   time: "00:20",
            //   minutes: 0, //分
            //   seconds: 20, //秒
            //   BofangBool: true,
            // },
            // {
            //   localId: "999",
            //   time: "00:30",
            //   minutes: 0, //分
            //   seconds: 23, //秒
            //   BofangBool: true,
            // },
          ]; //录音所有数据
          // v.timer = "";
          _this.askData.push(v); //问答题
        } else if (v.question.type == 6) {
          v.question.stem = v.question.stem.replace(
            /____/g,
            "<input placeholder='点击答题'  />"
          );
          var n = v.question.stem.split("input").length - 1;
          v.input = [];
          for (let index = 0; index < n; index++) {
            v.input[index] = "";
          }
          _this.multipleListNum.push(n);
          // _this.multivariateData.push(v); //多元填空题
        } else if (v.question.type == 9) {
          v.question.reads.forEach((v1) => {
            v1.active = false; //阅读题小题是否做
            v1.isShowJx = false; //阅读题小题解析
            v1.massage = "验证答案";
            v1.question.single_choices.forEach((v2) => {
              v2.isShow = false;
            });
            // 阅读题评论
          });

          _this.readingData.push(v); //阅读题
        } else if (v.question.type == 10) {
          v.modelValue = "";
          v.showAudio = false; //录音版展示
          v.AudioBool = false; //暂停录音
          v.AudioText = "开始录音";
          v.Audiotime = 60000; //倒计时
          v.listAuido = []; //录音所有数据
          // _this.hearingData.push(v); //听力题
        }
        if (v.question_id_son == null) {
          _this.allList.push(v);
        }
      });
      _this.allList[_this.defaultIndex].isShowList = true;
      _this.allList.forEach((v) => {
        v.active = false; //是否做题
      });
      _this.total = _this.allList.length;
      _this.countno = _this.allList.length; //未作体术
      _this.discussId = _this.allList[0].question_id; //默认加载第一题评论
      _this.discuss();
      this.loadingBool = false;
    },

    getCollection() {
      // 创建收藏试卷
      let _this = this;
      this.loadingBool = true;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.defaultIndex = Number(_this.$route.query.index);
      _this.$api.collectlist(obj).then((res) => {
        res.data.data.forEach((v, i) => {
          v.isShowJx = false; //是否显示解析
          // v.isShowJx = true; //是否显示解析
          v.massage = "验证答案";
          v.isShowList = false; //题显示隐藏
          if (v.question.type == 1) {
            if (v.question_id_son == null) {
              v.isShowList = false; //题显示隐藏
              v.question.single_choices.forEach((v1, i1) => {
                v1.isShow = false;
              });
              // _this.singleData.push(v); //单选
            }
          } else if (v.question.type == 2) {
            v.question.multiple_choices.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.manyData.push(v); //多选
          } else if (v.question.type == 3) {
            v.question.judges.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.judgeData.push(v); //判断题
          } else if (v.question.type == 4) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioText = "开始录音";
            v.AudioBool = false; //暂停录音
            v.Audiotime = 60000; //倒计时
            v.listAuido = [
              // {
              //   localId: "weixin://resourceid/74f40c87cbe546bb967bc54b44b58bd3",
              //   time: "00:20",
              //   minutes: 0, //分
              //   seconds: 20, //秒
              //   BofangBool: true,
              // },
              // {
              //   localId: "999",
              //   time: "00:30",
              //   minutes: 0, //分
              //   seconds: 23, //秒
              //   BofangBool: true,
              // },
            ]; //录音所有数据
            // v.timer = "";
            _this.askData.push(v); //问答题
          } else if (v.question.type == 6) {
            v.question.stem = v.question.stem.replace(
              /____/g,
              "<input placeholder='点击答题'  />"
            );
            var n = v.question.stem.split("input").length - 1;
            v.input = [];
            for (let index = 0; index < n; index++) {
              v.input[index] = "";
            }
            _this.multipleListNum.push(n);
            // _this.multivariateData.push(v); //多元填空题
          } else if (v.question.type == 9) {
            v.question.reads.forEach((v1) => {
              v1.active = false; //阅读题小题是否做
              v1.isShowJx = false; //阅读题小题解析
              v1.massage = "验证答案";
              v1.question.single_choices.forEach((v2) => {
                v2.isShow = false;
              });
              // 阅读题评论
            });

            _this.readingData.push(v); //阅读题
          } else if (v.question.type == 10) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioBool = false; //暂停录音
            v.AudioText = "开始录音";
            v.Audiotime = 60000; //倒计时
            v.listAuido = []; //录音所有数据
            // _this.hearingData.push(v); //听力题
          }
          if (v.question_id_son == null) {
            _this.allList.push(v);
          }
        });
        _this.allList[_this.defaultIndex].isShowList = true;
        _this.allList.forEach((v) => {
          v.active = false; //是否做题
        });
        _this.total = _this.allList.length;
        _this.countno = _this.allList.length; //未作体术
        _this.discussId = _this.allList[_this.defaultIndex].question_id; //默认加载第一题评论
        _this.discuss();
        _this.loadingBool = false;
      });
    },
    getShowanalysis() {
      // 创建错题集试卷
      let _this = this;
      this.loadingBool = true;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.defaultIndex = Number(_this.$route.query.index);
      _this.$api.errorlist(obj).then((res) => {
        // _this.loadingBool = false;
        let arrListData = [];
        arrListData = res.data.data;
        arrListData.forEach((v) => {
          if (v.read) {
            let obj = JSON.parse(JSON.stringify(v.question));
            v.question.type = 9;
            v.question_id = v.read.questions[0].id;
            v.question.collection = v.read.questions[0].collection;
            v.question.stem = v.read.questions[0].stem;
            v.question.reads = [];
            v.question.reads.push({
              question: JSON.parse(JSON.stringify(obj)),
            });
            v.question.stem_type = v.read.questions[0].stem_type;
            v.question.file = v.read.questions[0].file;
            v.question.analysis = v.read.questions[0].analysis;
          }
        });
        // return;
        arrListData.forEach((v, i) => {
          v.isShowJx = false; //是否显示解析
          // v.isShowJx = true; //是否显示解析
          v.massage = "验证答案";
          v.isShowList = false; //题显示隐藏
          if (v.question.type == 1) {
            if (v.question_id_son == null) {
              v.isShowList = false; //题显示隐藏
              v.question.single_choices.forEach((v1, i1) => {
                v1.isShow = false;
              });
              // _this.singleData.push(v); //单选
            }
          } else if (v.question.type == 2) {
            v.question.multiple_choices.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.manyData.push(v); //多选
          } else if (v.question.type == 3) {
            v.question.judges.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.judgeData.push(v); //判断题
          } else if (v.question.type == 4) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioText = "开始录音";
            v.AudioBool = false; //暂停录音
            v.Audiotime = 60000; //倒计时
            v.listAuido = [
              // {
              //   localId: "weixin://resourceid/74f40c87cbe546bb967bc54b44b58bd3",
              //   time: "00:20",
              //   minutes: 0, //分
              //   seconds: 20, //秒
              //   BofangBool: true,
              // },
              // {
              //   localId: "999",
              //   time: "00:30",
              //   minutes: 0, //分
              //   seconds: 23, //秒
              //   BofangBool: true,
              // },
            ]; //录音所有数据
            // v.timer = "";
            _this.askData.push(v); //问答题
          } else if (v.question.type == 6) {
            v.question.stem = v.question.stem.replace(
              /____/g,
              "<input placeholder='点击答题'  />"
            );
            var n = v.question.stem.split("input").length - 1;
            v.input = [];
            for (let index = 0; index < n; index++) {
              v.input[index] = "";
            }
            _this.multipleListNum.push(n);
            // _this.multivariateData.push(v); //多元填空题
          } else if (v.question.type == 9) {
            v.question.reads.forEach((v1) => {
              v1.active = false; //阅读题小题是否做
              v1.isShowJx = false; //阅读题小题解析
              v1.massage = "验证答案";
              v1.question.single_choices.forEach((v2) => {
                v2.isShow = false;
              });
              // 阅读题评论
            });

            _this.readingData.push(v); //阅读题
          } else if (v.question.type == 10) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioBool = false; //暂停录音
            v.AudioText = "开始录音";
            v.Audiotime = 60000; //倒计时
            v.listAuido = []; //录音所有数据
            // _this.hearingData.push(v); //听力题
          }
          if (v.question_id_son == null) {
            _this.allList.push(v);
          }
        });
        _this.allList[_this.defaultIndex].isShowList = true;
        _this.allList.forEach((v) => {
          v.active = false; //是否做题
        });

        _this.total = _this.allList.length;
        _this.countno = _this.allList.length; //未作体术
        _this.discussId = _this.allList[_this.defaultIndex].question_id; //默认加载第一题评论
        _this.discuss();
        _this.loadingBool = false;
      });
    },
    getQuestionType() {
      // 创建专项训练试卷
      let _this = this;
      this.loadingBool = true;
      let obj = {
        id: _this.$route.query.id,
        msg: {
          type: _this.$route.query.num,
        },
      };

      _this.defaultIndex = 0;
      _this.$api.special(obj).then((res) => {
        res.data.question_class_record.items.forEach((v, i) => {
          v.isShowJx = false; //是否显示解析
          // v.isShowJx = true; //是否显示解析
          v.massage = "验证答案";
          v.isShowList = false; //题显示隐藏
          v.question_id = v.id;
          if (v.question.type == 1) {
            if (v.question_id_son == null) {
              v.isShowList = false; //题显示隐藏
              v.question.single_choices.forEach((v1, i1) => {
                v1.isShow = false;
              });
              // _this.singleData.push(v); //单选
            }
          } else if (v.question.type == 2) {
            v.question.multiple_choices.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.manyData.push(v); //多选
          } else if (v.question.type == 3) {
            v.question.judges.forEach((v1) => {
              v1.isShow = false;
            });
            // _this.judgeData.push(v); //判断题
          } else if (v.question.type == 4) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioText = "开始录音";
            v.AudioBool = false; //暂停录音
            v.Audiotime = 60000; //倒计时
            v.listAuido = [
              // {
              //   localId: "weixin://resourceid/74f40c87cbe546bb967bc54b44b58bd3",
              //   time: "00:20",
              //   minutes: 0, //分
              //   seconds: 20, //秒
              //   BofangBool: true,
              // },
              // {
              //   localId: "999",
              //   time: "00:30",
              //   minutes: 0, //分
              //   seconds: 23, //秒
              //   BofangBool: true,
              // },
            ]; //录音所有数据
            // v.timer = "";
            _this.askData.push(v); //问答题
          } else if (v.question.type == 6) {
            v.question.stem = v.question.stem.replace(
              /____/g,
              "<input placeholder='点击答题'  />"
            );
            var n = v.question.stem.split("input").length - 1;
            v.input = [];
            for (let index = 0; index < n; index++) {
              v.input[index] = "";
            }
            _this.multipleListNum.push(n);
            // _this.multivariateData.push(v); //多元填空题
          } else if (v.question.type == 9) {
            v.question.reads.forEach((v1) => {
              v1.active = false; //阅读题小题是否做
              v1.isShowJx = false; //阅读题小题解析
              v1.massage = "验证答案";
              v1.question.single_choices.forEach((v2) => {
                v2.isShow = false;
              });
              // 阅读题评论
            });

            _this.readingData.push(v); //阅读题
          } else if (v.question.type == 10) {
            v.modelValue = "";
            v.showAudio = false; //录音版展示
            v.AudioBool = false; //暂停录音
            v.AudioText = "开始录音";
            v.Audiotime = 60000; //倒计时
            v.listAuido = []; //录音所有数据
            // _this.hearingData.push(v); //听力题
          }
          if (v.question_id_son == null) {
            _this.allList.push(v);
          }
        });
        if (_this.allList.length > 0) {
          _this.allList[_this.defaultIndex].isShowList = true;

          _this.allList.forEach((v) => {
            v.active = false; //是否做题
          });
          _this.total = _this.allList.length;
          _this.countno = _this.allList.length; //未作体术

          _this.discussId = _this.allList[0].question_id; //默认加载第一题评论
          _this.discuss();
        }

        this.loadingBool = false;
      });
    },
    clickInput(e, i) {
      //激活样式
      let _this = this;
      let arr = [];
      $(".stem input").each((index, item) => {
        arr.push($(item).val());
      });
      _this.allList[i].input = arr;
      if (e.target.localName == "input") {
        if (e.target.value != "") {
          this.$delete(this.allList[i], "active"); //解决视图不跟新
          this.$set(this.allList[i], "active", true);
        }
      }
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    changeText(i) {
      let _this = this;
      if (_this.allList[i].modelValue != "") {
        // _this.allList[i].active = true;
        this.$delete(this.allList[i], "active"); //解决视图不跟新
        this.$set(this.allList[i], "active", true);
      }
      if (!_this.$route.query.val) {
        _this.practiceSave();
      }
    },
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      audio[0].currentTime += time;
      audio[0].play();
    },
    // 上传语音
    uploadAudio(index) {
      // this.showAudio = true;
      let _this = this;
      _this.allList[index].showAudio = true;
    },
    // 暂停开始语音
    clickPaly(index, bool) {
      let _this = this;

      if (bool) {
        // //平台、设备和操作系统
        var system = {
          win: false,
          mac: false,
          xll: false,
          ipad: false,
        };
        //检测平台
        var p = navigator.platform;
        system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        system.x11 = p == "X11" || p.indexOf("Linux") == 0;
        system.ipad = navigator.userAgent.match(/iPad/i) != null ? true : false;
        //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面
        if (system.win || system.mac || system.xll || system.ipad) {
          _this.$toast("仅支持微信打开,该平台不支持录音功能");
        } else {
          this.boxIndex = index;
          var ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //开始录音
            _this.allList[index].AudioBool = true;
            _this.allList[index].AudioText = "点击停止";
            _this.timer = setInterval(this.startTimer, 1000); //开启计时器

            wxShare.wx.startRecord({
              success: function () {},
              cancel: function (res) {
                // alert(JSON.stringify(res));
              },
            });
          } else {
            _this.$toast("仅支持微信打开,该平台不支持录音功能");
          }
        }
      } else {
        //结束录音
        clearInterval(this.timer);
        _this.allList[index].AudioBool = false;
        _this.allList[index].showAudio = false;
        _this.allList[index].AudioText = "重新录音";
        _this.$delete(_this.allList[index], "active"); //解决视图不跟新
        _this.$set(_this.allList[index], "active", true);
        wxShare.wx.stopRecord({
          //把localId放在当前list数组
          success: function (res) {
            _this.allList[index].listAuido.push({
              localId: res.localId, //当前录音id
              time: _this.timeNum, //时间
              minutes: _this.minutes, //分
              seconds: _this.seconds, //秒
              BofangBool: true,
            });
            _this.timeNum = "00:00";
            _this.minutes = 0;
            _this.seconds = 0;
          },
          fail: function (res) {},
        });
      }
    },
    clickBoAudio(i, index) {
      //播放当前录音
      let _this = this;
      // _this.allList[i].listAuido[index].BofangBool = false;
      let localId = _this.allList[i].listAuido[index].localId;

      _this.allList[i].listAuido.forEach((v, indexx) => {
        if (index != indexx) {
          v.BofangBool = true;
        } else {
          v.BofangBool = false;
        }
      });
      wxShare.wx.playVoice({
        localId: localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
      });
      wxShare.wx.onVoicePlayEnd({
        success: function (res) {
          _this.allList[i].listAuido[index].BofangBool = true;
        },
      });
    },
    clickBoAudioStop(i, index) {
      let _this = this;
      _this.allList[i].listAuido[index].BofangBool = true;
      let localId = _this.allList[i].listAuido[index].localId;
      wxShare.wx.stopVoice({
        localId: localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
      });
    },
    clickRemove(i, index) {
      let _this = this;
      _this.allList[i].listAuido.splice(index, 1);
    },
    startTimer() {
      //计时器
      let _this = this;
      _this.seconds += 1;
      if (_this.seconds == 60) {
        _this.seconds = 0;
        _this.minutes = _this.minutes + 1;

        clearInterval(_this.timer);
        //结束录音
        _this.allList[_this.boxIndex].AudioBool = false;
        _this.allList[_this.boxIndex].showAudio = false;
        _this.allList[_this.boxIndex].AudioText = "重新录音";
        wxShare.wx.stopRecord({
          //把localId放在当前list数组
          success: function (res) {
            _this.allList[_this.boxIndex].listAuido.push({
              localId: res.localId, //当前录音id
              time: _this.timeNum, //时间
              minutes: _this.minutes, //分
              seconds: _this.seconds, //秒
              BofangBool: true,
            });
            _this.timeNum = "00:00";
            _this.minutes = 0;
            _this.seconds = 0;
          },
          fail: function (res) {},
        });
      }
      _this.timeNum =
        (_this.minutes < 10 ? "0" + _this.minutes : _this.minutes) +
        ":" +
        (_this.seconds < 10 ? "0" + _this.seconds : _this.seconds);
    },
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: _this.titlename,
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },
    goBack() {
      // console.log("点击了浏览器的返回按钮");
      localStorage.aaa = "点击了浏览器的返回按钮111";
      // sessionStorage.clear();
      // window.history.back();
      // history.pushState(null, null, document.URL);
    },
    practiceSave() {
      // 保存练习模式返回或浏览器关闭
      let _this = this;
      // console.log(1);
      // console.log(_this.allList);
      // console.log(_this.question);

      let obj = {
        question_class_id: _this.question.question_class_id,
        practicedata: {
          index: _this.defaultIndex,
          allList: JSON.stringify(_this.allList),
        },
      };
      _this.$api.practiceSave(obj).then((res) => {});
    },
  },

  mounted() {
    let _this = this;
    this.wechatSharefund();
    // // return;
    // if (window.history && window.history.pushState) {
    //   // 向历史记录中插入了当前页
    //   // history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.practiceSave, false);
    // }
    // window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));

    // window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  destroyed() {
    // window.removeEventListener("beforeunload", (e) =>
    //   this.beforeunloadHandler(e)
    // );
    // window.removeEventListener("unload", (e) => this.unloadHandler(e));
    // clearInterval(this.timer);
  },
  updated() {
    this.$nextTick(() => {
      $(".jiexi").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
    this.$nextTick(() => {
      $(".audioTitle").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
    this.$nextTick(() => {
      $(".main").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
  },
  async created() {
    let _this = this;
    // 收藏
    if (_this.$route.query.val == "收藏") {
      this.titlename = "收藏 - 西知题库";
      _this.getCollection();
      //
    } else if (_this.$route.query.val == "错题集") {
      this.titlename = "错题集 - 西知题库";
      _this.getShowanalysis();
    } else if (_this.$route.query.val == "专项练习") {
      this.titlename = "专项练习 - 西知题库";
      _this.getQuestionType();
    } else {
      _this.$store.commit("changelocationHref", window.location.href);
      // _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
      _this.question = _this.$store.state.question;
      await _this.records();
      this.titlename = this.question.name + " - 西知题库";
    }
    console.log(navigator);
  },
  watch: {
    total() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
    },
    defaultIndex() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
      if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //激活上一题
      } else if (_this.defaultIndex == _this.allList.length - 1) {
        _this.downBool = false; //激活下一题
        _this.upperBool = true; //激活上一题
      } else {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      }
      clearInterval(this.timer);
      // if (
      //   _this.allList[_this.defaultIndex].question.type == 4 ||
      //   _this.allList[_this.defaultIndex].question.type == 10
      // ) {
      //   _this.allList[_this.defaultIndex].listAuido.forEach((v, indexx) => {
      //     v.BofangBool = true;
      //     wxShare.wx.stopVoice({
      //       localId: v.localId, // 需要暂停的音频的本地ID，由stopRecord接口获得
      //     });
      //   });
      // }
      // //评论
      // _this.discussId = _this.allList[_this.defaultIndex].question_id;
      // _this.discuss();
      // _this.practiceSave();
    },
    allList: {
      handler() {
        this.$nextTick(() => {
          $(".stem input").each((index, item) => {
            if (this.allList[this.defaultIndex].question.type == 6) {
              $(item).val(this.allList[this.defaultIndex].input[index]);
            }
          });
        });
        if (this.defaultIndex == this.allList.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#Practice {
  background: #fff;
  width: 100%;
  min-height: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  /deep/.collection {
    > div {
      display: flex;
      align-items: center;
      .van-icon {
        margin-right: 8px;
      }
      span {
        font-weight: 500;
        color: #666;
        font-size: 28px;
        margin-top: 10px;
      }
    }
  }
  .zanwu {
    font-size: 26px;
    padding: 10px 30px;
  }
  /deep/.discussModel {
    background: #f2f2f2;
    padding-top: 140px;
    padding-bottom: 40px;
    width: 100%;
    .discussModelTop {
      height: 100px;
      background: #fff;
      line-height: 100px;
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      font-size: 28px;
      position: fixed;
      z-index: 1000;
      width: 100%;
      top: 0;
      border-bottom: 1px solid #dfe1e6;
      .xie {
        background: url(../../assets/images/xie.png) 0 32px no-repeat;
        width: 134px;
        background-size: 30px 30px;
        padding-left: 34px;
      }
    }
    // 评论
    // .default {
    //   color: #666;
    //   font-size: 30px;
    //   text-align: center;
    // }
    .discuss {
      background: #fff;
      padding: 0 40px;
      font-size: 26px;
      .discuss_list {
        // padding-bottom: 40px;
        li {
          display: flex;
          padding: 40px 0px;
          border-bottom: 1px solid #dfe1e6;
          &:last-child {
            border: none;
          }
          img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            border-radius: 50%;
            padding: 0;
          }
          .discuss_list_right {
            width: 600px;
            .discuss_list_top {
              display: flex;
              justify-content: space-between;
              color: #666;
              font-size: 30px;
              vertical-align: middle;
              margin-right: 5px;
              line-height: 60px;
              img {
                width: 45px;
                height: 45px;
                padding: 0;
              }
              .discuss_list_thumbs {
                display: flex;
                align-items: center;
                color: #bfbfbf;
              }
            }
            .discuss_list_time {
              color: #919191;
              padding: 10px 0;
            }
            .discuss_list_ask {
              // line-height: 60px;
              padding: 10px 0;
              .discuss_list_answer_stem {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                position: relative;
              }
              span {
                width: 80px;
                padding-left: 15px;
                display: inline-block;
                height: 40px;
                border: none;
                color: #df3833;
                background: #fff;
                text-align: left;
                &.active {
                  position: absolute;
                  bottom: -1px;
                  right: 8px;
                }
              }
            }
            .discuss_list_answer {
              background: #f2f2f2;
              word-break: break-all;
              padding: 10px;
              span {
                color: #4280f4;
              }
              .discuss_list_answer_stem {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                position: relative;
              }
              p {
                span {
                  width: 65px;
                  padding-left: 13px;
                  display: inline-block;
                  height: 40px;
                  border: none;
                  color: #df3833;
                  background: #f2f2f2;
                  text-align: left;
                  &.active {
                    position: absolute;
                    bottom: -1px;
                    right: 8px;
                  }
                }
              }
            }
          }
        }
      }
      > div {
        padding: 40px 0px;
      }
    }
  }
  /deep/.discussModel1 {
    padding-top: 100px;
    textarea {
      width: 100%;
      border: none;
      resize: none;
      font-size: 28px;
      line-height: 1.5;
      height: 200px;
      padding: 20px;
      height: 500px;
    }
  }
  /deep/.audioBox {
    // margin: 0 30px;
    position: relative;
    padding-top: 100px;
    box-shadow: 0px 0px 10px #cfcfcf;
    // padding-left: 20px;
    margin-bottom: 20px;
    .btn {
      background: #fff;
      position: absolute;
      z-index: 100;
      display: flex;
      padding: 10px 130px !important;
      padding-bottom: 0 !important;
      padding-left: 100px !important;

      top: 20px;
      width: 100%;
      img {
        width: 60px !important;
        height: 60px !important;
        padding: 0;
      }
      div {
        &:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 430px;
          margin: 0 20px;
        }
      }
      // .forward {
      //   // margin-left: 300px;
      // }
    }
    audio {
      width: 94%;
      height: 110px;
      // outline: none;
      // display: flex;
      // background:#fff !important;
      // filter: invert(180,90,360); //c3 filter(滤镜) 属性
      // color: red;
      margin-left: 20px;
      border-radius: 10px;
    }

    audio::-webkit-media-controls-enclosure {
      // background: rgba(255, 255, 255, 1); //白色底
      /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
      border-radius: 10px;
    }
    .media-controls-container,
    .media-controls-container {
      background: #7eb637;
      //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
      border-radius: 10px;
      // color: red;
    }
    audio::-webkit-media-controls-play-button {
      height: 44px;
      width: 44px;
      min-width: 44px;
      border-radius: 50%;
      flex-basis: 44px;
      // border-color: red;
      //  background: red;
      // background: #000;
      // color: #4280f4;
      // color: #25c17c;
    }
    // source{
    //   background: red;
    // }
    // 音量隐藏
    audio::-webkit-media-controls-volume-control-container {
      display: none !important;
      // color: red;
      // background: red;
    }

    audio::-webkit-media-controls-current-time-display {
      order: 1; //设置弹性盒对象元素的顺序
      color: #000;
      text-shadow: unset;
    }

    audio::-webkit-media-controls-time-remaining-display {
      order: 2;
      color: #000;
      text-shadow: unset;
    }
  }
  .activeJx {
    background: #a0bff9 !important;
  }
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0px 10px #cfcfcf;

    color: #666;
    font-size: 28px;
    p {
      border: 2px solid #4280f4;
      height: 2px;
      position: absolute;
      top: 98px;
    }
    > div {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        &:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .iconfont {
      font-size: 32px;
      margin-right: 5px;
    }
    .icon-zhengque {
      color: #25c17c;
    }
    .icon-cuowu {
      color: #f44336;
      font-size: 42px;
    }
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        span {
          margin-right: 20px;
        }
      }
    }
  }
  .main {
    .classShowAudio {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      height: 300px;
      background: #fff;
      box-shadow: 0px 0px 10px #cfcfcf;
      padding: 30px 140px;
      text-align: center;
      .icon-cuowu1 {
        position: absolute;
        top: 10px;
        right: 18px;
        font-size: 28px;
        color: #ccc;
      }
      .time {
        text-align: center;
        color: #858585 !important;
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        font-size: 28px !important;
        .van-count-down {
          color: #858585 !important;
        }
        span {
          margin: 0 5px;
        }
      }
      .luyingbox {
        margin-top: 50px;
        .luyin {
          width: 100px;
          height: 100px;
          background: url(../../assets/images/luyin.png) no-repeat 0 0;
          background-size: 100% 100%;
          border-radius: 50%;
          margin: 5px auto;
        }
        .zanting {
          width: 100px;
          height: 100px;
          background: url(../../assets/images/zanting.png) no-repeat 0 0;
          background-size: 105% 105%;
          border-radius: 50%;
          margin: 5px auto;
        }
      }
      p {
        text-align: center;
        color: #858585;
      }
    }
    .listAudioBtn {
      .icon-yuyin {
        font-size: 38px;
        // margin-left: 50px;
        margin-top: 40px;
        margin-left: 0px !important;
      }
      display: flex;
      // align-items: center;
      button {
        background: #df3833 !important;
        border: none;
        width: 150px;
        height: 50px;
        font-size: 18px;
        padding: 0;
      }
    }
    .listAudio {
      margin: 0 30px;
      > div {
        display: flex;
        align-items: center;
        .icon-cuowu2 {
          font-size: 32px;
          margin-left: 30px;
        }
      }
      .listAuidoBox {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 5px;
        color: #fff;
        align-items: center;
        // border: 1px solid #000;
        width: 350px;
        height: 70px;
        border-radius: 40px;
        margin: 30px 0;
        background: url(../../assets/images/ad_bg.png) 110px 0 no-repeat #f74f52;
        background-size: 30% 100%;
        .ad_zan {
          background: url(../../assets/images/ad_zan.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .ad_bo {
          background: url(../../assets/images/ad_bo.png) no-repeat -2px -2px
            #fff;
          background-size: 110% 110%;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }
    .singchoice,
    .morechoice,
    .panduan {
      // /deep/.youanswer {
      //     display: flex;
      //     align-items: center;
      //     color: #1989fa;
      //     font-weight: bold;
      //   }
      .analysis {
        padding: 0 30px;
      }
      .analysisDaQuery {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .youanswer {
          display: flex;
          align-items: center;
          color: #1989fa;
          font-weight: bold;
        }
      }
    }
    /deep/.more {
      display: flex;
      align-items: center;
      div {
        .youanswer {
          font-weight: bold;
        }
      }
    }
    /deep/.nide {
      span {
        display: flex;
        align-items: center;
        font-weight: bold;
      }
    }
    .audioTitle {
      margin-top: 40px;
      overflow: auto;
      margin-bottom: 100px;
      // /deep/ .stem {
      //   overflow: visible !important;
      // }
      .daan {
        h4 {
          color: black;
          font-weight: 400;
        }
        // > div,
        // span {
        //   color: #25c17c;
        // }
        .single {
          color: #25c17c;
          font-weight: bold;
        }
      }
    }
    video {
      margin-top: 40px;
    }

    .btn {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;

      button {
        background: #4280f4;
        border: none;
        width: 150px;
        height: 50px;
        font-size: 18px;
        padding: 0;
        margin-top: 40px;
      }
    }
    .totalJiexi {
      margin: 40px 0;
    }
    .jiexi {
      margin-bottom: 40px;
    }
    .analysis {
      margin-top: 50px;
      // padding: 40px 30px 40px 30px;
      .analysisDa {
        border-radius: 10px;
        background: #fff;
        box-shadow: 1px 1px 10px #cfcfcf;
        padding: 40px;
        margin-bottom: 40px;
        .daan {
          h4 {
            color: black;
            font-weight: 400;
          }
          > div,
          span {
            color: #25c17c;
            font-weight: bold;
          }
          .single {
            color: #25c17c;
            font-weight: bold;
          }
        }
        .jiexi {
          margin-bottom: 40px;
          > div {
            &:nth-child(1) {
              color: #000;
            }
          }
        }
      }

      .nide {
        > div {
          &:nth-child(2) {
            color: #4280f4;
          }
        }
        span {
          color: #4280f4;
        }
      }
    }

    .jiexi {
      > div {
        &:nth-child(1) {
          color: #000;
        }
      }
    }
    /deep/input {
      // width: 150px;
      border: none;
      border-bottom: 1px solid #000;
      color: #4280f4;
      font-size: 20px;
      background: #fff;
      text-align: center;
      &::placeholder {
        font-size: 20px;
      }
    }
    .single_choices {
      display: flex;
      margin-bottom: 20px;
      margin-top: 20px;
      span {
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 50%;
        line-height: 50px;
        background: #eee;
        &.active {
          background: #4280f4;
          color: #fff;
        }
      }
      div {
        width: 500px;
      }
    }

    /deep/.audioTitle {
      width: 750px;
      p {
        word-break: break-all;
        // width: 650px;
      }
    }
    textarea {
      border-radius: 10px;
      height: 300px;
      width: 686px;
      border: 1px solid #ccc;
      text-align: left;
      resize: none;
      padding: 20px 15px 0px 20px;
    }

    > div {
      font-size: 30px;
      > div {
        line-height: 50px;
      }

      .stem {
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
      video {
        width: 100%;
        padding: 0 30px;
      }

      textarea {
        margin: 0 30px;
      }
      .single_choices {
        span {
          margin-right: 20px;
        }
      }
    }
    .reading {
      .single {
        color: #25c17c;
        font-weight: bold;
      }
      .stem{
        padding: 0px !important;
        >div{
          &:nth-child(2){
            padding: 0 30px;
          }
        }
        &.stem1{
          padding: 30px !important;
        }
      }
      .audioTitle {
        width: 100%;
        // padding: 30px;
        //  box-shadow: 0px 0px 10px #cfcfcf;
        .audioBox {
          margin: 30px;
          box-shadow: 0px 0px 10px #cfcfcf;
        }
        #box1 {
          z-index: 100;
          box-shadow: 0px 0px 10px #cfcfcf;
          #box2 {
            padding: 40px 40px 250px 40px;

            > div {
              margin-bottom: 40px;
              background: #fff;
              box-shadow: 0px 0px 10px #cfcfcf;
              padding: 20px;
            }
          }
        }
      }
      .stem {
        height: 460px;
        overflow: auto;
        padding: 0 30px;
        padding-bottom: 207px !important;
      }
      #box1 {
        z-index: 100;
        box-shadow: 0px 0px 10px #cfcfcf;
        position: relative;
        left: 0;
        width: 100%;
        #box2 {
          padding: 40px 40px 250px 40px;

          > div {
            margin-bottom: 40px;
            background: #fff;
            box-shadow: 0px 0px 10px #cfcfcf;
            padding: 20px;
          }
        }
      }
      .choce {
        > div {
          padding: 20px 0 20px 20px;
          background: #fff;
          box-shadow: 0px 0px 10px #cfcfcf;
          margin-top: 40px;

          .analysis {
            margin-top: 0;
            // background: red;
            .practice {
              .daan {
              }
            }
          }
        }
        .totalJiexi {
          padding-right: 30px;
        }
      }
      /deep/p {
        // width: 700px;
        word-break: break-all;
      }
      .daan {
        display: flex;
        justify-content: space-between;
        .nide {
          color: #000 !important;
          .youanswer {
            color: #1989fa;
            font-weight: bold;
          }
        }
      }
    }
    .single_choices {
      padding-left: 30px;
      p {
        width: 500px;
      }
    }
    .single {
      .stem {
        padding: 0 30px;
      }
      .choce {
        padding: 0 30px;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 2px 2px 10px #bebebe;
    padding: 0 30px;
    color: #666;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card {
      color: #4280f4;
    }
    i {
      font-size: 28px;
    }
    div {
      .confim {
        width: 150px;
        line-height: 70px;
        background: #fff;
        border: none;
        color: #ccc;
        &.active {
          color: #4280f4;
        }
      }
    }
  }
  .van-action-sheet,
  .van-popup {
    .content {
      h6 {
        padding: 20px 30px;
        font-size: 28px;
        border-bottom: 1px solid #ccc;
        span {
          font-weight: 400;
          margin-right: 20px;
        }
      }
      .circle {
        width: 100%;
        padding: 30px 45px;
        display: flex;
        flex-wrap: wrap;
        max-height: 460px;
        overflow: auto;
        div {
          text-align: center;
          line-height: 100px;
          color: #000;
          font-size: 28px;
          width: 100px;
          height: 100px;
          border: 1px solid #000;
          border-radius: 50%;
          margin-right: 38px;
          margin-bottom: 38px;
          &:nth-child(5n + 5) {
            margin-right: 0;
          }
          &.active {
            background: #25c17c;
            border: none;
            color: #fff !important;
          }
        }
      }
    }
  }
  .showTop {
    .circle {
      max-height: 1100px !important;
    }
  }
  // .van-action-sheet{
  //
  // }
  .van-overlay {
    z-index: 1000;
  }
  .van-popup {
    height: 100%;
    // .van-overlay{
    //   background: none;
    // }
    .j-juan {
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      div {
        &:first-child {
          flex: 1.5;
          border-top: 1px solid #ccc;
          color: #000;
          padding: 10px 0;
          i {
            font-size: 36px;
            color: #ccc;
          }
        }
        &:last-child {
          flex: 4.5;
          background: #4280f4;
          padding: 10px 0;
          p {
            color: #fff;
            line-height: 94px;
          }
        }
      }
    }
  }
  .papersShow {
    .wrapper {
      padding: 400px 120px;
      div {
        width: 500px;
        height: 280px;

        &:nth-child(1) {
          background: url("../../assets/images/dialog-bg-3.png") no-repeat
            rgba(247, 49, 82, 1);
          background-size: 500px 260px;
        }
        &:nth-child(2) {
          background: #fff;
          line-height: 280px;
          text-align: center;
          font-size: 35px;
          font-weight: 700;
          border-bottom: 1px solid #ccc;
        }
        &:nth-child(3) {
          height: 100px;
          background: #fff;
          line-height: 100px;
          text-align: center;
          font-size: 30px;
          color: #1989fa;
        }
      }
    }
  }
  /deep/table {
    width: 700px !important;
    overflow-x: auto;
    td {
      border-bottom: 1px solid #000 !important;

      border-right: 1px solid #000 !important;
      border-top: none !important;
      border-left: none !important;
    }

    border-top: 1px solid #000 !important;

    border-left: 1px solid #000 !important;

    border-spacing: 0 !important; /*去掉单元格间隙*/
  }
  // 多元填空
  .multipleList {
    > div {
      .audioTitle {
        .stem {
          // height: 460px;
          overflow: auto;
          padding: 0 30px;
          padding-bottom: 207px !important;
        }
      }
    }

    .stem {
      // height: 600px;
      // overflow-y: scroll;
      // overflow-y:hidden;
      padding: 0 30px;
      background: #fff;
      // padding-bottom: 207px !important;
    }
    // #box1 {
    //   box-shadow: 0px 0px 10px #cfcfcf;
    #box1 {
      z-index: 100;
    }
    #box2 {
      padding: 0px 30px 250px 30px;
      .analysis {
        width: 100%;

        .daan {
          > div {
            &:first-child {
              margin-top: 40px;
            }
            padding: 20px;

            background: #fff;
            box-shadow: 0px 0px 10px #cfcfcf;

            margin-bottom: 40px;
            color: #000 !important;
          }
          .youanswer {
            span {
              color: #4280f4;
              // font-size: 36px;
            }
          }
          .reference {
            display: flex;
            div {
              color: #25c17c;
              // font-size: 36px;
            }
          }
          .resolve {
            color: #000;
            // font-size: 36px;

            margin-bottom: 30px;
          }
        }
        .jiexi {
          padding: 20px;

          margin-top: 40px;
          background: #fff;
          box-shadow: 0px 0px 10px #cfcfcf;
        }
        // }
      }
    }
  }
  /deep/video {
    z-index: 1;
  }
  #box2 {
    .icon-yuyin {
      font-size: 38px;
      margin-left: 50px;
    }
  }
  /deep/#box1 {
    z-index: 100;
  } // 问答分频
  .question-answer {
    .audioTitle {
      #box1 {
        //  background: red;
        box-shadow: 0px 0px 10px #cfcfcf;
        z-index: 100;
        #box2 {
          .analysis {
            /deep/table {
              width: 655px !important;
            }
            .wenda {
              .analysis {
                padding: 40px 30px 220px 30px;
                .jiexi {
                  padding: 20px;
                  background: #fff;
                  box-shadow: 0px 0px 10px #cfcfcf;
                }
              }
            }
          }
        }
      }
      .stem {
        height: 460px;
        overflow: auto;
        padding: 0 30px;
        padding-bottom: 207px !important;
      }
      .answer {
        textarea {
          box-shadow: 0px 0px 10px #e9e9e9;
        }
        .analysis {
          margin-top: 0;
          .analysisDa {
            padding: 0;
          }
        }
      }
    }
  }
  // 听力
  .listening {
    .Hearing {
      .hearingtype {
        height: 460px;
        overflow: auto;
        .stem {
          padding: 0 30px;
          padding-bottom: 207px !important;
        }
      }
      #box1 {
        z-index: 100;
        box-shadow: 0px 0px 10px #cfcfcf;
      }
      #box2 {
        .analysis {
          padding: 0 30px 250px 30px;
          /deep/table {
            width: 655px !important;
          }
          .tingli {
            background: #fff;
            box-shadow: 0px 0px 10px #cfcfcf;
            .jiexi {
              max-width: 100%;
              padding: 30px;
            }
          }
        }
      }
    }
  }
  // 听力分频
  .Hearing {
  }
  #box1 {
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom: 0px;
    background: #fff;
    .daan {
      h4 {
        color: black;
        font-weight: 400;
      }
      // > div,
      // span {
      //   color: #25c17c;
      // }
      // .single {
      //   color: #25c17c;
      //   font-weight: bold;
      // }
    }
    .touchmove {
      text-align: center;
      margin-bottom: 20px;
      // background: red;
      button {
        width: 300px !important;
        height: 58px;
        line-height: 40px;
        overflow: hidden;
        border-radius: 0px 0px 45px 45px;
        font-size: 20px !important;

        padding: 0 30px;
        border: none;
        color: #999;
        background: #f7f7f7;
      }
    }
  }
  #box2 {
    overflow: auto;
  }
  /deep/img {
    width: 100%;
    height: 400px;
    padding: 20px;
  }
  // .audioTitle{
  //   padding: 0 30px;
  // }
  // 标题
  .topicTitle {
    display: flex;
    justify-content: space-between;

    div {
      font-weight: bold;
      padding: 0 20px;
    }
  }
}
</style>
