<template>
  <div id="Collection">
    <div class="totalSubject">
      <div class="toptitle">
        <h4>全部收藏({{ titleype }})</h4>

        <van-button v-if="titleype !== 0" @click="cancelcolletion"
          >一键清空</van-button
        >
      </div>

      <div class="notYet" v-if="titleype == 0">暂无收藏</div>
      <div
        v-else
        class="typelist"
        v-for="(item, index) in typelist"
        :key="index"
      >
        <van-icon color="#F5A452" name="star" @click="clickCancel(item)" />
        <div @click="clickSend('收藏', index)">
          <p>
            <span>{{ item.question.types }}</span>
            <!-- <span>/</span><span>{{ item.question.grade }}分</span> -->
          </p>
          <p class="questionStem" v-html="item.question.stem"></p>
        </div>
      </div>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      titlename: "我的收藏 - 西知题库",
      titleype: "0", //题目
      typelist: [],
      loadingBool: true,
    };
  },
  created() {
    //查看解析
    let _this = this;
    _this.loadingBool = true;
    _this.getAlllist();
      localStorage.routerPush="/PersonalCenter/Index"

  },
  methods: {
    clickCancel(item) {
      let _this = this;

      _this.$dialog
        .confirm({
          title: "取消收藏",
        })

        .then(() => {
          // on confirm
          console.log(item.question_id);
          let obj = {
            collection: 0 /** 收藏动作，1代表收藏，0代表取消收藏 */,
            question_id:
              item.question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
          };

          _this.$api.recordsCollectlist(obj).then((res) => {
            _this.getAlllist();
            _this.$toast.success("取消收藏成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 清空
    cancelcolletion() {
      let _this = this;

      _this.$dialog
        .confirm({
          title: "取消收藏",
        })

        .then(() => {
          // on confirm

          _this.$api.recordsCollectlist().then((res) => {
            _this.getAlllist();
            _this.$toast.success("取消收藏成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getAlllist() {
      let _this = this;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.$api.collectlist(obj).then((res) => {
        _this.typelist = res.data.data;
        _this.titleype = res.data.total;
        _this.loadingBool = false;
      });
    },
    clickSend(val, index) {
      let _this = this;
      console.log(val, index);
      _this.$router.push({
        path: "/Practice",
        query: {
          val: val,
          index: index,
        },
      });
    },
    routerPush() {
      let _this = this;
      _this.$router.push("/PersonalCenter/Index");
    },
  },
  mounted() {
    bus.$on("routerPush", this.routerPush);
  },

};
</script>

<style lang="less" scoped>
#Collection {
  width: 100%;
  background: #fcfcfc;
  min-height: 100%;
  //   position: relative;
  margin-top: 100px;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  .van-button {
    border-radius: 8px;
    padding: 0px 20px;
    height: 60px;
    color: #4280f4;
    font-size: 20px;
  }
  .notYet {
    font-size: 28px;
    padding-left: 15px;
  }
  .totalSubject {
    padding: 40px 30px;
    .toptitle {
      display: flex;
      justify-content: space-between;
      h4 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 50px;
      }
    }
  }
  .typelist {
    padding: 40px 30px 30px;
    // height: 150px;
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px #dfdfdf;
    border-radius: 8px;
    .van-icon {
      font-size: 50px;
    }
    h6 {
      // color: red;
      line-height: 90px;
      margin-right: 40px;
      span {
        font-weight: bold;
      }
    }
    div {
      margin-left: 30px;

      p {
        &:first-child {
          font-size: 24px;
          margin-bottom: 10px;
          color: #666;
          span {
            margin: 0 5px;
          }
        }
        &:last-child {
          font-weight: bold;
        }
      }
       .questionStem  {
        font-weight: bold;
        width: 500px;
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 28px;
        /deep/p  {
          width: 500px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 28px;
        }
      }
    }
  }
  .returnExam {
    width: 100%;
    line-height: 100px;
    text-align: center;
    font-size: 30px;
    background: #fff;
    box-shadow: 0px 0px 20px #dfdfdf;
    position: fixed;
    bottom: 0;
  }
}
</style>