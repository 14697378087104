<template>
  <div id="testSort">
    <div class="top">
      <img src="@/assets/images/logo.png" alt="" @click="clickSendGuan" />
      <img
        :src="
          meObj.avatar ? meObj.avatar : require('../assets/images/avatar.png')
        "
        alt=""
        @click="clickPersonalCenter"
      />
    </div>
    <div class="testBoot">
      <div class="title">
        <!-- <h2 @click="clickSendUrl">加入备考模拟题训练营</h2>
        <p @click="clickSendUrl">配套西语专属题库，助您轻松APTO</p> -->
        <img
          @click="clickSendUrl"
          src="@/assets/images/index_banner.jpg"
          alt=""
        />
      </div>

      <ul class="list">
        <li v-for="(item, index) in listbanner" :key="index">
          <!-- <a :href="item.url">
            <img :src="item.imgUrl" alt="" />
            <p>{{ item.name }}</p>
          </a> -->
          <div @click="clickHref(item.url)">
            <img :src="item.imgUrl" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </li>
      </ul>
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in forstudyList" :key="index">
            {{ item.user.username }} 在练习 {{ item.name }}</van-swipe-item
          >
          <!-- {{ item.user.username }}  -->
        </van-swipe>
      </van-notice-bar>
      <div class="bottom">
        <div
          class="textlist"
          v-for="(v, i) in textlist"
          :key="i"
          @click="clickSend(v.id)"
        >
          <h4>{{ v.name }}</h4>
          <div>
            <span>免费</span>
            <span>共{{ v.son_count }}份试卷</span>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import wxShare from "@/utils/wxShare.js";
import { getCookie, setCookie } from "@/utils/commit.js";
export default {
  head: {
    title: "西知题库 · 永久免费 · 西语人的专属训练营 - 西知题库",
  },
  data() {
    return {
      textlist: [], //试卷分类
      loadingBool: true,
      meObj: {},
      listbanner: [
        {
          name: "西知首页",
          imgUrl: require("../assets/images/xizhishouye.png"),
          url: "https://m.spanishknow.com/",
        },
        {
          name: "西知学院",
          imgUrl: require("../assets/images/xizhixueyuan.png"),
          url: "https://xizhi.duanshu.com/#/",
        },
        {
          name: "直播课堂",
          imgUrl: require("../assets/images/zhiboketang.png"),
          url: "https://m.spanishknow.com/zhiborili/",
        },
        {
          name: "资源下载",
          imgUrl: require("../assets/images/ziyuanxiazai.png"),
          url: "https://m.spanishknow.com/zyxz/",
        },
      ],
      forstudyList: [],
      obj: {
        page: 1,
        limit: 100,
      },
      total: 0,
    };
  },
  methods: {
    clickSend(id) {
      let _this = this;
      //  this.$router.push({
      //   name: `TestVueRouterTo`,
      //   params: {
      //     page: '1', code: '8989'
      //   }
      // })
      _this.$router.push({
        path: "/Index",
        query: {
          id: id,
        },
      });
    },
    clickHref(str) {
      window.location.href = str;
    },
    clickSendUrl() {
      window.location.href =
        "https://mp.weixin.qq.com/s/EXUd98qUt4TeFA23eF1Yag";
    },
    clickSendGuan() {
      window.location.href = "https://m.spanishknow.com/";
    },
    wechatSharefund() {
      let _this = this;
      _this.$api.getsign({ url: location.href.split("#")[0] }).then((res) => {
        // console.log(wxShare.getJSSDK);
        let dataForWeixin = {
          title: "西知题库 · 永久免费 · 西语人的专属训练营 - 西知题库",
          desc: "大家都在练习，快来免费刷题，西语人的专属训练营", // 分享描述
          link: location.href,
          img: "https://m.tk.spanishknow.com/logo.jpg", // 分
        };
        wxShare.getJSSDK(res.data, dataForWeixin);
      });
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
      } else {
        //跳转pc端页面
        console.log("pc端页面");
        window.location.href = "http://tk.spanishknow.com/#/";
        // window.location.href = "http://192.168.1.103:4444";
      }
    },
    clickPersonalCenter() {
      // 跳转到个人中心
      let _this = this;
      if (localStorage.userInfo) {
        _this.$router.push("/PersonalCenter");
      } else {
        _this.$toast.fail("请登录");
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          setTimeout(() => {
            _this.$router.push("/wxMMLogin");
          }, 1000);
        } else {
          setTimeout(() => {
            _this.$router.push("/");
          }, 1000);
        }
      }
    },
    getforstudy() {
      let _this = this;
      _this.$api.forstudy(_this.obj).then((res) => {
        res.data.list.data.forEach((v) => {
          _this.forstudyList.unshift(v);
        });
        _this.total = res.data.list.total;
        // console.log(_this.total);
        // console.log(_this.forstudyList);
        // console.log(_this.forstudyList.length == _this.total);
        if (_this.forstudyList.length == _this.total) {
          clearInterval(this.timeId);
        }
      });
    },
  },
  mounted() {
    this.wechatSharefund();
  },
  created() {
    let _this = this;
    _this.$store.commit("changelocationHref", window.location.href);
    // _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
    this.loadingBool = true;
    _this.browserRedirect();

    let obj = {
      page: 1,
      limit: 99999,
    };
    _this.$api.classes(obj).then((res) => {
      _this.textlist = res.data.lists.data;
      this.loadingBool = false;
    });
    _this.getforstudy();
    this.timeId = window.setInterval(() => {
      ///调取接口
      _this.obj.page += 1;
      _this.getforstudy();
    }, 60000);
    if (localStorage.userInfo) {
      _this.$api.me().then((res) => {
        _this.meObj = res.data;
      });
    }
  },
  destroyed() {
    clearInterval(this.timeId);
  },
};
</script>

<style lang="less" scoped>
#testSort {
  width: 100%;
  height: 100%;
  background: #fafafa;
  .notice-swipe {
    height: 40px;
    line-height: 45px;
  }

  /deep/.van-notice-bar {
    //  margin: 10px 0;
    // border-radius: 20px;
    margin: 0 15px;
    margin-bottom: 20px;
    color: #2f5aff;
    background: #e6ebff;
    padding-left: 0px;
    /deep/ .van-icon {
      padding: 20px 20px;
      background: url(../assets/images/c0161d7dd1f362ad92007be4db60465b.png) 0 0;
      background-size: 100% 100%;
    }
  }
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  .top {
    padding: 0 30px;
    height: 96px;
    width: 100%;
    z-index: 10;
    background: #fff;
    border-bottom: 1px solid #dfe1e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    img {
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
  }
  .testBoot {
    margin-top: 96px;
  }
  .title {
    // text-align: center;
    // background: url(../assets/images/banner.jpg);
    // background-size: 100% 100%;
    // height: 340px;
    // padding-top: 50px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    // p {
    //   font-size: 36px;
    // }
    // h2 {
    //   font-weight: bold;
    //   font-size: 48px;
    //   margin: 30px 0;
    //   color: #3654ef;
    // }
  }
  .list {
    // height: 160px;
    width: 100%;
    display: flex;
    font-size: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 50px 40px;
    text-align: center;
    background: #fff;

    img {
      width: 80px;
      height: 80px;
    }
    a {
      color: #000;
    }
  }
  .bottom {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 20px;
    padding-bottom: 20px;
    position: relative;
    // top: -40px;
    background: #fafafa;
    border-radius: 20px;
  }
  .textlist {
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-radius: 20px;
    width: 100%;
    height: 274px;
    //  background: #fff;
    box-shadow: 0px 0px 10px #cfcfcf;
    background: url("../assets/images/list-bg.png") no-repeat 50%;
    background-size: cover;
    padding: 20px 40px;
    h4 {
      font-size: 36px;
      font-weight: bold;
      // margin-bottom: 100px;
      height: 160px;
    }
    div {
      span {
        &:first-child {
          color: #f74f52;
          font-size: 28px;
          font-weight: bold;
          margin-right: 20px;
        }
        &:nth-child(2) {
          color: #999;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
