import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import wxLogin from '../views/wxLogin.vue'
import wxMMLogin from '../views/wxMMLogin.vue'
import Regist from '../views/Regist.vue'
import Forget from '../views/Forget.vue'
import phoneLogin from '../views/phoneLogin.vue'
import phoneLoginCode from '../views/phoneLoginCode.vue'
import Avater from '../views/Avater.vue'

import Index from '../views/Index.vue'
import Result from '../views/Index/Result.vue'
import Exam from '../views/Index/Exam.vue'
import test from '../views/test.vue'
import Analysis from '../views/Index/Analysis.vue'
import Practice from '../views/Index/Practice.vue'
import wxlo from '../views/wxlo.vue'
import Testsort from '../views/Testsort.vue'
import TestInfo from '../views/Index/TestInfo.vue'
// 个人中心
import PersonalCenter from '../views/Index/PersonalCenter.vue'
import RankingList from '../views/Index/PersonalCenter/RankingList.vue'
import Collection from '../views/Index/PersonalCenter/Collection.vue'
import SpecialExercises from '../views/Index/PersonalCenter/SpecialExercises.vue'
import Showanalysis from '../views/Index/PersonalCenter/Showanalysis.vue'
import QuestionType from '../views/Index/PersonalCenter/QuestionType.vue'
import PersonalCenterIndex from '../views/Index/PersonalCenter/Index.vue'
import api from '@/api/api'
// 配音秀
import DubbingShow from '../views/Index/DubbingShow/DubbingShow.vue'
import DubbingShowDetails from '../views/Index/DubbingShow/DubbingShowDetails.vue'

import ImgInfo from '../views/Index/DubbingShow/ImgInfo.vue'
import DubbingShowDetailsInfo from '../views/Index/DubbingShow/DubbingShowDetailsInfo.vue'
import DubbingShowDetailstext from '../views/Index/DubbingShow/DubbingShowDetailstext.vue'
import DubbingShowDetailstextInfo from '../views/Index/DubbingShow/DubbingShowDetailstextInfo.vue'
import Submit from '../views/Index/DubbingShow/Submit.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



Vue.use(VueRouter)

const routes = [{
    path: '/Testsort',
    name: 'Testsort',
    component: Testsort,
  }, {
    path: '/Index',
    name: 'Index',
    component: Index,
  }, {
    path: '/wxLogin',
    name: 'wxLogin',
    component: wxLogin,
  }, {
    path: '/wxlo',
    name: 'wxlo',
    component: wxlo,
  },
  {
    path: '/PersonalCenter',
    name: '个人中心',
    component: PersonalCenter,
    redirect: "/PersonalCenter/Index",
    children: [{
        path: '/PersonalCenter/Index',
        name: '集',
        component: PersonalCenterIndex,
      },
      {
        path: '/PersonalCenter/RankingList',
        name: '排行榜',
        component: RankingList,
      }, {
        path: '/PersonalCenter/Collection',
        name: '收藏',
        component: Collection,
      }, {
        path: '/PersonalCenter/SpecialExercises',
        name: '专项练习',
        component: SpecialExercises,
      }, {
        path: '/PersonalCenter/QuestionType',
        name: '专项练习题型',
        component: QuestionType,
      }, {
        path: '/PersonalCenter/Showanalysis',
        name: '错题集',
        component: Showanalysis,
      },
    ]
  },


  {
    path: '/Avater',
    name: '昵称头像',
    component: Avater,
  }, {
    path: '/phoneLogin',
    name: '手机号登录',
    component: phoneLogin,
  }, {
    path: '/phoneLoginCode',
    name: '验证码',
    component: phoneLoginCode,
  },

  {
    path: '/Result',
    name: '得分',
    component: Result,
  }, {
    path: '/Exam',
    name: '试题列表',
    component: Exam,
  }, {
    path: '/Analysis',
    name: '查看解析',
    component: Analysis,
  }, {
    path: '/Practice',
    name: '练习模式',
    component: Practice,
  }, {
    path: '/',
    name: 'Login',
    // redirect: '/',
    component: Login,
  }, {
    path: '/Regist',
    name: 'Regist',
    component: Regist,
  }, {
    path: '/Forget',
    name: 'Forget',
    component: Forget,
  }, {
    path: '/wxMMLogin',
    name: 'wxMMLogin',
    component: wxMMLogin,
  }, {
    path: '/test',
    name: 'test',
    component: test,
  }, {
    path: '/TestInfo',
    name: 'TestInfo',
    component: TestInfo,
  },
  //配音秀
  {
    path: '/DubbingShow',
    name: 'DubbingShow',
    component: DubbingShow,
  },{
    path: '/DubbingShowDetails',
    name: 'DubbingShowDetails',
    component: DubbingShowDetails,
  },
  {
    path: '/ImgInfo',
    name: 'ImgInfo',
    component: ImgInfo,
  },
  {
    path: '/DubbingShowDetailsInfo',
    name: 'DubbingShowDetailsInfo',
    component: DubbingShowDetailsInfo,
  },
  {
    path: '/DubbingShowDetailstext',
    name: 'DubbingShowDetailstext',
    component: DubbingShowDetailstext,
  },
  {
    path: '/DubbingShowDetailstextInfo',
    name: 'DubbingShowDetailstextInfo',
    component: DubbingShowDetailstextInfo,
  },
  {
    path: '/Submit',
    name: 'Submit',
    component: Submit,
  }

  
]




const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   // debugger
//   if (localStorage.userInfo) {
//     api.getToken().then((res) => {
//       localStorage.userInfo = JSON.stringify(res.data);
//       next()
//     });
//   } 
//   else {
//     next()
//   }

// })
export default router