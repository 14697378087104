import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import api from './api/api'
import './assets/icon/iconfont.css'
import './assets/css/reset.css';
import 'vant/lib/index.css';
import 'amfe-flexible'
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  // 定义组件中的数据对象
  keyName: 'head',
});
// 挂原型
Vue.prototype.$api = api
Vue.use(Vant);
Vue.config.productionTip = false
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')