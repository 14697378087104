<template>
  <div id="RankingList">
    <!-- 排行榜 -->
    <div class="title">
      <div>排行榜</div>
      <div>
        <img src="@/assets/images/rank-bg.png" alt="" />
      </div>
    </div>
    <div class="main">
      <div class="title">刷题数量排行榜（考试模式）</div>
      <ul>
        <li v-for="(item, index) in allListData" :key="index">
          <div>{{ index + 1 > 3 ? index + 1 : "" }}</div>
          <div><img :src="item.avatar" alt="" /></div>
          <div>{{ item.name }}</div>
          <div>{{ item.records_count }}</div>
        </li>
      </ul>
    </div>
    <div class="bottom" v-if="!loadingBool">
      <div style="font-size: 12px; width: 40px" v-if="userObj.count > 50">
        50名外
      </div>
      <div v-else>{{ userObj.count }}</div>
      <div><img :src="userObj.list.avatar" alt="" /></div>
      <div :style="{ width: userObj.count > 50 ? '100px' : '150px' }">
        {{ userObj.list.name }}
      </div>
      <div v-if="userObj.count > 50">请加油哟！</div>
      <div :style="{ width: userObj.count > 50 ? '74px' : '99px' }">
        {{ userObj.list.records_count }}
      </div>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import bus from "@/utils/bus";

export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      loadingBool: true,
      titlename: "排行榜 - 西知题库",
      allListData: [], //用户数据
      userObj: { list: {} },
    };
  },
  mounted() {
    bus.$on("routerPush", this.routerPush);
  },
  methods: {
    routerPush() {
      let _this = this;
      _this.$router.push("/PersonalCenter/Index");
      
    },
  },
  async created() {
    let _this = this;
    let obj = {
      orderBy: "records",
      // page: 1,
      // limit: 20,
    };
    await _this.$api.Weeklyranking(obj).then((res) => {
      _this.allListData = res.data.list;
      console.log(res.data);
    });
    _this.$api.sortme(obj).then((res) => {
      _this.userObj = res.data;
      // res.data.count = 101;
      console.log(res.data);
      _this.loadingBool = false;
    });
      localStorage.routerPush="/PersonalCenter/Index"

  },
};
</script>

<style lang="less" scoped>
#RankingList {
  background: #fcfcfc;
  width: 100%;
  min-height: 100%;
  margin-top: 100px;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  > .title {
    width: 100%;
    height: 244px;
    background: url(../../../assets/images/bg1.png) 0 0;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    div {
      font-size: 42px;
      color: #fff;
      align-items: center;
      line-height: 170px;
      img {
        width: 160px;
        height: 160px;
        margin-top: 20px;
      }
    }
  }
  .main {
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-top: -50px;
    height: 500px;
   
    background: #fff;
    .title {
      height: 90px;
      line-height: 90px;
      font-size: 30px;
      text-align: center;
    }
    ul {
       padding-bottom: 160px;
        background: #fff;
      li {
        display: flex;
        align-items: center;
        font-size: 28px;
        height: 160px;
        padding: 0 10px;
        div {
          &:nth-child(1) {
            margin-top: 10px;
            width: 70px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            margin-right: 10px;
            font-size: 28px;
            color: #333;
          }
          &:nth-child(2) {
            margin-top: 10px;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            margin-right: 10px;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
            }
          }
          &:nth-child(3) {
            margin-top: 10px;
            padding-left: 10px;
            width: 300px;
            height: 80px;
            line-height: 80px;
            border-radius: 50%;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            white-space: nowrap; /*规定段落中的文本不进行换行 */
          }
          &:nth-child(4) {
            width: 220px;
            text-align: right;
            font-weight: 700;
          }
        }
        &:nth-child(1) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/images/rank-1.png) 0 0;
              background-size: 100% 100%;
            }
            &:nth-child(4) {
              color: #ffd371;
            }
          }
        }
        &:nth-child(2) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/images/rank-2.png) 0 0;
              background-size: 100% 100%;
            }
            &:nth-child(4) {
              color: #cfcbef;
            }
          }
        }
        &:nth-child(3) {
          div {
            &:nth-child(1) {
              background: url(../../../assets/images/rank-3.png) 0 0;
              background-size: 100% 100%;
            }
            &:nth-child(4) {
              color: #f1b066;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 28px;
    height: 160px;
    padding: 0 10px;
    position: fixed;
    background: #fff;
    bottom: 0px;
    z-index: 200;
    border-top: 1px solid #dfe1e6;
    div {
      &:nth-child(1) {
        margin-top: 10px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        margin-right: 10px;
        font-size: 28px;
        color: #333;
      }
      &:nth-child(2) {
        margin-top: 10px;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        margin-right: 10px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
      &:nth-child(3) {
        margin-top: 10px;
        padding-left: 10px;
        width: 300px;
        height: 80px;
        line-height: 80px;
        border-radius: 50%;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
      }
      &:last-child {
        width: 220px;
        text-align: right;
        font-weight: 700;
      }
    }
  }
}
</style>