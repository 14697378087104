import base from './base';
import axios from '../utils/http';
import {
    service_url1
} from '../utils/http';

const api = {
    login: (params) => axios.post(base.APIlogin, params),
    getToken: (params) => axios.put(base.APIlogin, ),
    me: (params) => axios.get(base.APIme, ),
    easySms: (params) => axios.post(base.APIeasySms, params),
    resgiter: (params) => axios.post(base.APIresgiter, params),
    getwxinfo: (params) => axios.get(base.APIgetwxinfo + `/${params.token}`, ),

    classQuestion: (params) => axios.get(base.APIclassQuestion + `/${params.id}`, ),

    classesindexlist: (params) => axios.get(base.APIclassesindexlist, {
        params: params
    }),

    classes: (params) => axios.get(base.APIclasses, {
        params: params
    }),

    records: (params) => axios.post(base.APIrecords, params),
    shows: (params) => axios.get(base.APIshows + `/${params.id}`, ),
    recordtiemslist: (params) => axios.get(base.APIrecordtiemslist + `/${params.id}`, ),
    submittest: (params) => axios.put(base.APIsubmittest, params.param),
    wxLoginphone: (params) => axios.post(base.APIwxLoginphone, params),

    getcountdown: (params) => axios.get(base.APIgetcountdown),
    getsign: (params) => axios.get(base.APIgetsign, {
        params: params
    }),
    questionclassshow: (params) => axios.get(base.APIquestionclassshow + `/${params.id}`, ),

    forgetpwd: (params) => axios.post(base.APIforgetpwd, params),
    discuss: (params) => axios.get(base.APIdiscuss, {
        params: params
    }),
    discussCreate: (params) => axios.post(base.APIdiscussCreate, params),
    support: (params) => axios.post(base.APIsupport, params),
    check: (params) => axios.get(base.APIcheck, ),
    avatarInfo: (params) => axios.get(base.APIavatarInfo, {
        params: params
    }),
    editme: (params) => axios.post(base.APIEditme, params),
    errorlist: (params) => axios.get(base.APIerrorlist, {
        params: params
    }),
    emptyerrorstate: (params) => axios.put(base.APIemptyerrorstate, params),
    index: (params) => axios.get(base.APIindex, ),
    Weeklyranking: (params) => axios.get(base.APIWeeklyranking, {
        params: params
    }),
    collectlist: (params) => axios.get(base.APIcollectlist, {
        params: params
    }),
    recordsCollectlist: (params) => axios.put(base.APIrecordsCollectlist,
        params),
    sortme: (params) => axios.get(base.APIsortme, {
        params: params
    }),
    special: (params) => axios.get(base.APIspecial + `/${params.id}`, {
        params: params.msg
    }),

    forstudy: (params) => axios.get(base.APIforstudy, {
        params: params
    }),
    practice: (params) => axios.get(base.APIpractice + `/${params.id}`, ),
    practiceSave: (params) => axios.post(base.APIpracticeSave, params),
    delPracticeSave: (params) => axios.delete(base.APIpracticeSave + `/${params.id}`, ),
    // ------------配音秀------------
    ShowArctype: (params) => axios.get(base.APIarctype, {
        params: params
    }),
    passworduser: (params) => axios.get(base.APIpassworduser, {
        params: params
    }), //系统后台，查看试卷加密后做题用户
    passwordusercheck: (params) => axios.get(base.APIpasswordcheck, {
        params: params
    }), //用户，查询加密试卷用户是否解密过

    APIpasswordusercheck: (params) => axios.get(base.APIpasswordusercheck, {
        params: params
    }), //用户，查询加密试卷用户是否解密过
}




export default api;