<template>
  <div id="SpecialExercises">
    <!-- 专项练习 -->
    <div class="title">
      <div>专项训练</div>
    </div>
    <div
      class="list"
      v-for="(item, index) in textlist"
      :key="index"
      @click="clickQuestionType(item.id,item.name)"
    >
      <div>{{ item.name }}</div>
      <div><van-icon name="arrow" /></div>
    </div>
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  data() {
    return {
      textlist: [], //试卷分类
      loadingBool: true,
    };
  },
  methods: {
    clickQuestionType(id,name) {
      let _this = this;
      _this.$router.push({
        path: "/PersonalCenter/QuestionType",
        query: {
          id: id,
          name:name
        },
      });
    },
    routerPush() {
      let _this = this;
      _this.$router.push("/PersonalCenter/Index");
      

    },
  },
  created() {
    let _this = this;
    this.loadingBool = true;
    let obj = {
      page: 1,
      limit: 99999,
    };
    _this.$api.classes(obj).then((res) => {
      _this.textlist = res.data.lists.data;

      this.loadingBool = false;
    });
    localStorage.routerPush="/PersonalCenter/Index"
  },
  mounted() {
    bus.$on("routerPush", this.routerPush);
  },
};
</script>

<style lang="less" scoped>
#SpecialExercises {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  background: #fcfcfc;
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-overlay {
    z-index: 1000;
  }
  > .title {
    width: 100%;
    height: 180px;
    background: url(../../../assets/images/bg1.png) 0 0;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    div {
      font-size: 42px;
      color: #fff;
      align-items: center;
      line-height: 170px;
      img {
        width: 160px;
        height: 160px;
        margin-top: 20px;
      }
    }
  }
  .list {
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    font-size: 28px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>